import React from 'react';
import './style.css';
import Modal from 'react-modal';
import withTMCAuth from './withTMCAuth';

Modal.setAppElement('#root');

function Login(props: { signInWithGoogle; user; error }) {
  return (
    <div id="form-signin-container">
      <form className="form-signin">
        <div className="text-center mb-4">
          <img className="mb-4 rounded" src="/images/logo.png" alt="logo" width="64" height="64" />
          <h1 className="h3 mb-3 font-weight-normal">TMC Store App</h1>
        </div>

        {props.error ? (
          <div className="alert alert-danger" role="alert">
            {props.error.message}
          </div>
        ) : null}

        {!props.user && (
          <div>
            <button type="button" className="btn btn-lg btn-secondary btn-block" onClick={props.signInWithGoogle}>
              Googleアカウントでログイン
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default withTMCAuth(Login);
