import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function ShopKitchenLayoutGroupModal(props: {
  modalIsOpen: boolean;
  onSubmit: (elementGroup) => void;
  onClickCancel: () => void;
}) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      minWidth: '400px',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newLine = e.target.newLine.checked as boolean;
    const spacer = e.target.spacer.checked as boolean;

    props.onSubmit({ newLine, spacer });
  };

  return (
    <Modal isOpen={props.modalIsOpen} style={customStyles} contentLabel="Example Modal">
      <form onSubmit={handleSubmit}>
        <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" id="spacer" name="spacer" />
          <label className="form-check-label">余白</label>
        </div>
        <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" id="newLine" name="newLine" />
          <label className="form-check-label">改行する</label>
        </div>
        <button type="submit" className="btn btn-primary">
          追加する
        </button>
        &nbsp;
        <button type="button" className="btn btn-secondary" onClick={props.onClickCancel}>
          キャンセル
        </button>
      </form>
    </Modal>
  );
}

export default ShopKitchenLayoutGroupModal;
