import { useEffect, useState } from 'react';
import firebase from '../firebase';

const usePasses = (): Array<firebase.firestore.DocumentSnapshot> | null => {
  const [passesSnapshot, setPassesSnapshot] = useState<Array<firebase.firestore.DocumentSnapshot> | null>(null);

  useEffect(() => {
    const unregister = firebase
      .firestore()
      .collection('passes')
      .orderBy('order')
      .onSnapshot((passOrderSnapshots) => {
        console.log('onSnapshot usePasses');
        setPassesSnapshot(passOrderSnapshots.docs);
      });

    return () => {
      unregister();
    };
  }, []);

  return passesSnapshot;
};

export default usePasses;
