import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { NavLink } from 'react-router-dom';

function CustomerLink(props: { customerId }) {
  const [customer, setCustomer] = useState<firebase.firestore.DocumentData>();

  useEffect(() => {
    const unregisterCustomerObserver = firebase
      .firestore()
      .collection('customers')
      .doc(props.customerId)
      .onSnapshot((doc) => {
        console.log('onSnapshot CustomerLink');
        if (doc.exists) {
          setCustomer(doc);
        }
      });

    return () => {
      if (unregisterCustomerObserver) {
        unregisterCustomerObserver();
      }
    };
  }, [props.customerId]);

  return (
    <div className="container-fluid h-100">
      {customer ? (
        <NavLink to={`/customers/${customer.ref.id}`} className="nav-link">
          {customer.data().nickname}
        </NavLink>
      ) : (
        ''
      )}
    </div>
  );
}

export default CustomerLink;
