import React, { useState } from 'react';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import firebase from './firebase';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import useKitchenLayouts from './hooks/useKitchenLayouts';

function ShopKitchenLayoutForm() {
  const history = useHistory();

  const kitchenLayoutSnapshots = useKitchenLayouts();
  const [executing, setExecuting] = useState(false);

  const handleClickDeleteButton = async (kitchenLayoutSnapshot: firebase.firestore.DocumentData) => {
    const shops = await firebase
      .firestore()
      .collection('shops')
      .where('kitchen_shop_id', '==', null)
      .where('kitchen_layout_id', '==', kitchenLayoutSnapshot.id)
      .get();

    if (shops.size > 0) {
      window.alert(
        `使用中の店舗があるため削除できません。\n\n${shops.docs.map((doc) => `- ${doc.data().short_name}`).join('\n')}`,
      );
      return;
    }

    const options = {
      title: '確認',
      message: `${kitchenLayoutSnapshot.data().name}を削除します。よろしいですか？`,
      buttons: [
        {
          label: '削除する',
          onClick: async () => {
            try {
              setExecuting(true);
              await kitchenLayoutSnapshot.ref.delete();
            } finally {
              setExecuting(false);
            }
          },
        },
        {
          label: 'キャンセル',
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  return (
    <>
      <div className="container-fluid h-100">
        <div className="cooking-order-card edit-kitchen-layout">
          <div className="cooking-order-card-header">
            <div className="row align-items-center">
              <div className="col">
                <strong>盛り付けレイアウトパターン一覧</strong>
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => history.push('/admin/new_shop_kitchen_layout')}
                >
                  新規作成
                </button>
              </div>
            </div>
          </div>
          <div className="cooking-order-card-body">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>名称</th>
                  <th>アクション</th>
                </tr>
              </thead>
              <tbody>
                {kitchenLayoutSnapshots?.map((kitchenLayoutSnapshot) => (
                  <tr>
                    <th style={{ verticalAlign: 'middle' }}>{kitchenLayoutSnapshot.data().name}</th>
                    <td style={{ width: '10em', textAlign: 'center', verticalAlign: 'middle' }}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => history.push(`/admin/edit_shop_kitchen_layout/${kitchenLayoutSnapshot.id}`)}
                      >
                        <i className="fas fa-edit" />
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          handleClickDeleteButton(kitchenLayoutSnapshot);
                        }}
                      >
                        <i className="fas fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <WindmillSpinnerOverlay loading={executing} />
    </>
  );
}

export default ShopKitchenLayoutForm;
