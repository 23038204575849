import React, { useEffect, useState } from 'react';
import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';

function TodayCounter(props: { elementItemId: string; label: string }) {
  const storeContainer = StoreContainer.useContainer();
  const [limit, setLimit] = useState(0);
  const [ordered, setOrdered] = useState(0);

  const getToday = () => dayjs().tz('Asia/Tokyo').format('YYYYMMDD');

  const [today, setToday] = useState(getToday());

  useEffect(() => {
    const interval = setInterval(() => {
      setToday(getToday());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('topping_plans')
      .doc(props.elementItemId)
      .onSnapshot((doc) => {
        console.log('onSnapshot TodayCounter');
        if (doc.exists) {
          const data = doc.data()!;
          setOrdered(data.consumed ?? 0);
          setLimit(data.limit_per_day);
        }
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId, props.elementItemId]);

  return (
    <small className={`text-${ordered >= limit ? 'danger' : 'info'}`}>
      <strong>
        {props.label} {ordered}/{limit}
      </strong>
    </small>
  );
}

export default TodayCounter;
