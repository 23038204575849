import React, { useEffect, useState, useContext } from 'react';

import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import dayjs from './dayjs';
import firebase from './firebase';
import confirmAndRunPassRefund from './confirmAndRunPassRefund';
import RoleContext from './RoleContext';
import Pass from './Pass';
import confirmAndRunPassCancel from './confirmAndRunPassCancel';

function CustomerPasses(props: { customerId: string }) {
  const [passOrders, setPassOrders] = useState<any>();
  const [updating, setUpdating] = useState(false);
  const role = useContext(RoleContext);

  useEffect(() => {
    const query = firebase
      .firestore()
      .collection('pass_orders')
      .where('customer_id', '==', props.customerId)
      .orderBy('ordered_at', 'desc');

    const unregisterFeedbacksObserver = query.onSnapshot((snap) => {
      console.log('onSnapshot CustomerPasses');
      const records = {};
      snap.forEach((docSnapshot) => {
        records[docSnapshot.id] = docSnapshot.data();
      });

      setPassOrders(records);
    });

    return () => {
      unregisterFeedbacksObserver();
    };
  }, [props.customerId]);

  const statusMap = {
    active: 'アクティブ',
    canceled: 'キャンセル済',
    expired: '期限切れ',
  };

  const handleRefundModal = async (e, passOrderId: string, customerId: string) => {
    e.preventDefault();

    await confirmAndRunPassRefund(passOrderId, customerId, progress);
  };

  const handleCancelModal = async (e, passId: string, customerId: string, expiredAt: Date) => {
    e.preventDefault();

    await confirmAndRunPassCancel(passId, customerId, progress, expiredAt);
  };

  const progress = (start: boolean) => {
    setUpdating(start);
  };

  return (
    <>
      {passOrders === undefined ? (
        'loading'
      ) : (
        <>
          <table className="table w-auto text-right">
            <thead>
              <tr>
                <th> </th>
                <th> </th>
                <th>注文日時</th>
                <th>有効期限</th>
                <th>パス名</th>
                <th>支払い方法</th>
                <th>ステータス</th>
                <th>返金日時</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(passOrders).map((passOrderId) => (
                <tr key={passOrderId}>
                  <td>
                    {role.isCs() && passOrders[passOrderId].status !== 'refunded' && (
                      <>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(e) => {
                            handleRefundModal(e, passOrderId, passOrders[passOrderId].customer_id);
                          }}
                        >
                          返金/即時解約
                        </button>
                      </>
                    )}
                  </td>
                  <td>
                    {role.isCs() &&
                      passOrders[passOrderId].status === 'active' &&
                      passOrders[passOrderId].subscription_status === 'subscribed' && (
                        <>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => {
                              handleCancelModal(
                                e,
                                passOrders[passOrderId].pass_id,
                                passOrders[passOrderId].customer_id,
                                passOrders[passOrderId].expires_at.toDate(),
                              );
                            }}
                          >
                            自動課金停止
                          </button>
                        </>
                      )}
                  </td>
                  <td>
                    {dayjs(passOrders[passOrderId].ordered_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}
                  </td>
                  <td>
                    {dayjs(passOrders[passOrderId].expires_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}
                  </td>
                  <td>
                    <Pass passId={passOrders[passOrderId].pass_id} />
                  </td>
                  <td>
                    {passOrders[passOrderId].stripe_charge_id && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://dashboard.stripe.com/payments/${passOrders[passOrderId].stripe_charge_id}`}
                      >
                        クレジットカード
                      </a>
                    )}
                    {passOrders[passOrderId].stripe_subscription_id && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://dashboard.stripe.com/subscriptions/${passOrders[passOrderId].stripe_subscription_id}`}
                      >
                        サブスクリプション
                      </a>
                    )}
                  </td>
                  <td>{statusMap[passOrders[passOrderId].status]}</td>
                  <td>
                    {passOrders[passOrderId].refunded_at
                      ? dayjs(passOrders[passOrderId].refunded_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <WindmillSpinnerOverlay loading={updating} message="更新中" />
        </>
      )}
    </>
  );
}

export default CustomerPasses;
