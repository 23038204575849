import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import dayjs from './dayjs';
import firebase from './firebase';

async function submitPassCancel(passId: string, customerId: string) {
  const apiEndPoint = `${process.env.REACT_APP_api_server}/passes/${passId}/cancel`;
  const auth = firebase.auth();

  await auth.currentUser!.getIdToken().then(async (token) => {
    await fetch(apiEndPoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ customer_id: customerId }),
    });
  });
}

const emptyDiv = () => <div />;

async function confirmAndRunPassCancel(passId: string, customerId: string, cb: Function, expiredAt: Date) {
  const options = {
    title: '自動課金停止',
    message: `パスのサブスク解約を行います(返金は行いません)。発行されているクーポンは今回の期間終了(${dayjs(expiredAt)
      .tz('Asia/Tokyo')
      .format('YYYY-MM-DD')})までは有効です。`,
    buttons: [
      {
        label: '実行する',
        onClick: async () => {
          cb(true);
          await submitPassCancel(passId, customerId);
          cb(false);
        },
      },
      {
        label: '実行しない',
        onClick: () => {},
      },
    ],
    childrenElement: () => emptyDiv(),
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
  };
  confirmAlert(options);
}

export default confirmAndRunPassCancel;
