import { useState, useEffect } from 'react';
import firebase from '../firebase';

type CustomerStampCardState = {
  name: string;
  description: string;
  startPeriod: firebase.firestore.Timestamp;
  endPeriod: firebase.firestore.Timestamp;
  totalStampCount: number;
  maxStampCount: number;
  completed: boolean;
};

const useCustomerStampCard = (
  stampCardSnapshot: firebase.firestore.DocumentSnapshot | null,
  customerId: string,
): CustomerStampCardState | null => {
  const [customerStampCardSnapshot, setCustomerStampCardSnapshot] =
    useState<firebase.firestore.DocumentSnapshot | null>(null);

  useEffect(() => {
    if (stampCardSnapshot !== null) {
      firebase
        .firestore()
        .collection('customers')
        .doc(customerId)
        .collection('stamp_cards')
        .doc(stampCardSnapshot.id)
        .get()
        .then((doc) => {
          console.log('get useCustomerStampCard');
          if (doc.exists) {
            setCustomerStampCardSnapshot(doc);
          }
        });
    }
  }, [stampCardSnapshot, customerId]);

  if (stampCardSnapshot !== null && customerStampCardSnapshot !== null) {
    const stampCard = stampCardSnapshot.data()!;
    const customerStampCard = customerStampCardSnapshot.data()!;
    const totalStampCount = customerStampCard.total_stamp_count;
    const maxStampCount = Math.max(...stampCard.goals.map((goal) => goal.required_count));

    return {
      name: stampCard.name,
      description: stampCard.description,
      startPeriod: stampCard.start_period,
      endPeriod: stampCard.end_period,
      totalStampCount,
      maxStampCount,
      completed: totalStampCount >= maxStampCount,
    };
  }

  return null;
};

export default useCustomerStampCard;
