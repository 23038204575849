import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import OrderSummary from './OrderSummary';
import { StoreContainer } from './store';
import dayjs from './dayjs';

function NoCookingSlotOrders(props: { time: Date; timeUntil: Date }) {
  const storeContainer = StoreContainer.useContainer();
  const [orders, setOrders] = useState<any>();

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('pickup_at', '>', props.time)
      .where('pickup_at', '<', props.timeUntil)
      .orderBy('pickup_at')
      .onSnapshot((snap) => {
        console.log('onSnapshot NoCookingSlotOrders');
        const records = {};
        snap.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          if (data.slot_id || data.canceled_at) {
            // skip
          } else {
            records[docSnapshot.id] = data;
          }
        });

        setOrders(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [storeContainer.shopId, props.time, props.timeUntil]);

  if (orders) {
    console.log(Object.keys(orders));
  }
  return (
    <div className="">
      {orders ? (
        <>
          {Object.keys(orders).length > 0 && (
            <>
              <hr className="border border-5" />
              即時注文
            </>
          )}

          {Object.keys(orders).map((id) => (
            <div key={id}>
              <span className="pr-2">{dayjs(orders[id].pickup_at.toDate()).tz('Asia/Tokyo').format('HH:mm:ss')}</span>

              <span className="pr-2">{orders[id].order_id ? <OrderSummary orderId={orders[id].order_id} /> : '-'}</span>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}

export default NoCookingSlotOrders;
