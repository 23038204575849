import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { NavLink } from 'react-router-dom';

function Shops() {
  const [shops, setShops] = useState<any>();
  const [onlyActive, setOnlyActive] = useState<boolean>(true);

  useEffect(() => {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = firebase.firestore().collection('shops');
    if (onlyActive) {
      query = query.where('status', '==', 'active');
    }
    const unregisterShopsObserver = query.orderBy('order', 'asc').onSnapshot((snap) => {
      console.log('onSnapshot Shops');
      const records = {};
      snap.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        records[docSnapshot.id] = data;
      });

      setShops(records);
    });

    return () => {
      unregisterShopsObserver();
    };
  }, [onlyActive]);

  return (
    <div id="coupons" className="container-fluid h-80">
      <div className="scrollable-coupons h-100">
        {shops === undefined ? (
          <div className="row">
            <div className="col text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            {Object.keys(shops).length === 0 ? (
              <div className="row">
                <div className="col">
                  <div className="alert alert-light text-center" role="alert">
                    ありません
                  </div>
                </div>
              </div>
            ) : (
              <>
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <NavLink to="shops/NEW" className="nav-link" activClassName="active">
                      新規店舗作成
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/shop_info_batch_update" className="nav-link" activClassName="active">
                      店舗情報一括更新
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/shop_stocks" className="nav-link" activClassName="active">
                      在庫確認
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/shop_batch_update" className="nav-link" activClassName="active">
                      在庫一括更新
                    </NavLink>
                  </li>
                </ul>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="onlyActive"
                    defaultChecked={onlyActive}
                    onChange={(e) => setOnlyActive(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="onlyActive">
                    有効な店舗のみ
                  </label>
                </div>

                <table className="table">
                  <tbody>
                    <tr>
                      <th> </th>
                      <th>有効</th>
                      <th>オーダーアプリに表示</th>
                      <th>表示順</th>
                      <th>店舗名</th>
                    </tr>
                    {Object.keys(shops).map((shopId) => (
                      <tr key={`coupon-${shopId}`}>
                        <td>
                          <NavLink to={`shops/${shopId}`}>編集</NavLink>
                          {!shops[shopId].kitchen_shop_id ? (
                            <>
                              {' '}
                              |<NavLink to={`/admin/assign_slot_patterns/${shopId}`}> スロット</NavLink>
                            </>
                          ) : null}
                        </td>
                        <td>{shops[shopId].status === 'active' ? '✅' : ''}</td>
                        <td>{shops[shopId].enabled ? '✅' : ''}</td>
                        <td>{shops[shopId].order}</td>
                        <td>{shops[shopId].short_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Shops;
