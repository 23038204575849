import React, { useState, useEffect } from 'react';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { useHistory } from 'react-router-dom';
import firebase from './firebase';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css';
import useElementItems from './hooks/useElementItems';
import ShopKitchenLayoutGroupModal from './ShopKitchenLayoutGroupModal';
import ShopKitchenLayoutElementModal from './ShopKitchenLayoutElementModal';

type Element = {
  item_id: Array<string>;
  item_prefix?: Array<string>;
  always_emphasize_count?: boolean;
};

type ElementGroup = Array<{
  new_line: boolean;
  spacer: boolean;
  elements: Array<Element>;
}>;

function ShopKitchenLayoutForm(props: any) {
  const history = useHistory();

  const elementItems = useElementItems();

  const { id } = props.match.params;
  const isUpdate = id !== undefined;
  const [isReady, setIsReady] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [kitchenLayoutName, setKitchenLayoutName] = useState<string | undefined>();
  const [elementGroups, setElementGroups] = useState<ElementGroup>([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [shopKitchenLayoutGroupModalIsOpen, setShopKitchenLayoutGroupModalIsOpen] = useState(false);
  const [shopKitchenLayoutElementModalIsOpen, setShopKitchenLayoutElementModalIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!id) return;

        const snapshot = await firebase.firestore().collection('kitchen_layouts').doc(id).get();
        setKitchenLayoutName(snapshot.data()!.name);
        setElementGroups(snapshot.data()!.groups);
      } finally {
        setIsReady(true);
      }
    })();
  }, [id]);

  const addGroup = () => {
    setShopKitchenLayoutGroupModalIsOpen(true);
  };

  const moveGroup = (oldGroupIndex: number, newGroupIndex: number) => {
    const newElementGroups = [...elementGroups];
    const temp = newElementGroups[newGroupIndex];
    newElementGroups[newGroupIndex] = newElementGroups[oldGroupIndex];
    newElementGroups[oldGroupIndex] = temp;
    setElementGroups(newElementGroups);
  };

  const changeGroupsNewLine = (groupIndex: number, newLine: boolean) => {
    const newElementGroups = [...elementGroups];
    newElementGroups[groupIndex].new_line = newLine;
    setElementGroups(newElementGroups);
  };

  const removeGroup = (groupIndex: number) => {
    const newElementGroups = [...elementGroups];
    newElementGroups.splice(groupIndex, 1);
    setElementGroups(newElementGroups);
  };

  const handleSubmitShopKitchenLayoutGroupModal = (params) => {
    setElementGroups([
      ...elementGroups,
      {
        new_line: params.newLine,
        spacer: params.spacer,
        elements: [],
      },
    ]);

    setShopKitchenLayoutGroupModalIsOpen(false);
  };

  const addElement = (groupIndex: number) => {
    setSelectedGroupIndex(groupIndex);
    setShopKitchenLayoutElementModalIsOpen(true);
  };

  const moveElement = (groupIndex: number, oldElementIndex: number, newElementIndex: number) => {
    const newElementGroups = [...elementGroups];
    const temp = newElementGroups[groupIndex].elements[newElementIndex];
    newElementGroups[groupIndex].elements[newElementIndex] = newElementGroups[groupIndex].elements[oldElementIndex];
    newElementGroups[groupIndex].elements[oldElementIndex] = temp;
    setElementGroups(newElementGroups);
  };

  const removeElement = (groupIndex: number, elementIndex: number) => {
    const newElementGroups = [...elementGroups];
    newElementGroups[groupIndex].elements.splice(elementIndex, 1);
    setElementGroups(newElementGroups);
  };

  const handleShopKitchenLayoutElementModal = (params) => {
    const newElementGroups = [...elementGroups];
    const { itemId, itemPrefix, alwaysEmphasizeCount } = params;
    newElementGroups[selectedGroupIndex].elements.push({
      item_id: itemId,
      item_prefix: itemPrefix,
      always_emphasize_count: alwaysEmphasizeCount,
    });
    setElementGroups(newElementGroups);

    setShopKitchenLayoutElementModalIsOpen(false);
  };

  const title = (itemIds) => {
    return elementItems!
      .filter((doc) => itemIds.includes(doc.id))
      .map((elementItem) => elementItem.data()!.kitchen_name)
      .join(',');
  };

  const color = (itemIds) => {
    return elementItems!.find((doc) => itemIds.includes(doc.id))?.data()!.kitchen_color || 'gray';
  };

  const handleSubmit = async (e) => {
    try {
      setExecuting(true);

      e.preventDefault();

      const name = e.target.name.value as string;
      if (!name || name.length === 0) return;

      firebase.firestore().collection('kitchen_layouts').doc(id).set(
        {
          name,
          groups: elementGroups,
        },
        { merge: true },
      );

      history.push('/admin/shop_kitchen_layouts');
    } finally {
      setExecuting(false);
    }
  };

  return isReady ? (
    <>
      <div className="container-fluid h-100">
        <div className="cooking-order-card edit-kitchen-layout">
          <div className="cooking-order-card-header">
            <strong>盛り付けレイアウトパターン{isUpdate ? '更新' : '作成'}</strong>
          </div>
          <div className="cooking-order-card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>パターン名</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  defaultValue={kitchenLayoutName}
                  size={10}
                  style={{ width: '20em' }}
                />
              </div>
              <div className="form-group">
                <label>配置</label>
                <div className="cooking-order-card-element-group-container d-flex flex-row flex-wrap justify-content-between">
                  {elementItems &&
                    elementGroups.map((elementGroup, groupIndex) => (
                      <>
                        {elementGroup.new_line && <div className="w-100" />}
                        <div
                          className={`cooking-order-card-element-group ${
                            elementGroup.spacer ? 'flex-grow-1' : 'cooking-order-card-border-white'
                          }`}
                        >
                          {elementGroup.spacer ? (
                            <div className="cooking-order-card-element-visualized-spacer">
                              <small>余白</small>
                            </div>
                          ) : (
                            <>
                              {elementGroup.elements.map((element, elementIndex) => (
                                <div className="cooking-order-card-element-wrapper">
                                  <div
                                    className={`cooking-order-card-element order-tooltip cooking-order-card-border-${color(
                                      element.item_id,
                                    )} cooking-order-card-bg-${color(element.item_id)}`}
                                  >
                                    <div className="cooking-order-card-element-control">
                                      <strong>{title(element.item_id)}</strong>
                                      <button type="button" onClick={() => removeElement(groupIndex, elementIndex)}>
                                        <i className="fas fa-minus-circle" /> 削除
                                      </button>
                                      <div className="cooking-order-card-sortable">
                                        <button
                                          type="button"
                                          disabled={elementIndex === 0}
                                          onClick={() => moveElement(groupIndex, elementIndex, elementIndex - 1)}
                                          aria-hidden="true"
                                        >
                                          <i className="fas fa-chevron-circle-left" />
                                        </button>
                                        <button
                                          type="button"
                                          disabled={elementIndex >= elementGroup.elements.length - 1}
                                          onClick={() => moveElement(groupIndex, elementIndex, elementIndex + 1)}
                                          aria-hidden="true"
                                        >
                                          <i className="fas fa-chevron-circle-right" />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="order-tooltip-content">
                                      <table className="text-left">
                                        <tbody>
                                          <tr>
                                            <th>プレフィックス</th>
                                            <td>
                                              {element.item_prefix && element.item_prefix.length > 0
                                                ? element.item_prefix.join(',')
                                                : '-'}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>個数を常に強調表示</th>
                                            <td>{element.always_emphasize_count ? 'する' : 'しない'}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                          {elementGroup.spacer ? (
                            <></>
                          ) : (
                            <div className="cooking-order-card-element-wrapper">
                              <button
                                className="cooking-order-card-element"
                                type="button"
                                onClick={() => addElement(groupIndex)}
                              >
                                <div className="cooking-order-card-element-content">
                                  <i className="fas fa-plus-circle" /> 追加
                                </div>
                              </button>
                            </div>
                          )}
                          <div className="cooking-order-card-group-control">
                            <button
                              type="button"
                              onClick={() => changeGroupsNewLine(groupIndex, !elementGroup.new_line)}
                            >
                              改行{elementGroup.new_line ? 'あり' : 'なし'}
                            </button>
                            <button type="button" onClick={() => removeGroup(groupIndex)}>
                              <i className="fas fa-minus-circle" /> 削除
                            </button>
                            <div className="cooking-order-card-sortable">
                              <button
                                type="button"
                                disabled={groupIndex === 0}
                                onClick={() => moveGroup(groupIndex, groupIndex - 1)}
                                aria-hidden="true"
                              >
                                <i className="fas fa-chevron-left" />
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                disabled={groupIndex >= elementGroups.length - 1}
                                onClick={() => moveGroup(groupIndex, groupIndex + 1)}
                                aria-hidden="true"
                              >
                                <i className="fas fa-chevron-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  <button className="cooking-order-card-element-group" type="button" onClick={addGroup}>
                    <i className="fas fa-plus-circle" /> 追加
                  </button>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                {isUpdate ? '更新' : '作成'}する
              </button>
            </form>
          </div>
        </div>
        <ShopKitchenLayoutGroupModal
          modalIsOpen={shopKitchenLayoutGroupModalIsOpen}
          onSubmit={(params) => handleSubmitShopKitchenLayoutGroupModal(params)}
          onClickCancel={() => setShopKitchenLayoutGroupModalIsOpen(false)}
        />
        <ShopKitchenLayoutElementModal
          modalIsOpen={shopKitchenLayoutElementModalIsOpen}
          onSubmit={(params) => handleShopKitchenLayoutElementModal(params)}
          onClickCancel={() => setShopKitchenLayoutElementModalIsOpen(false)}
        />
      </div>
      <WindmillSpinnerOverlay loading={executing} />
    </>
  ) : (
    <></>
  );
}

export default ShopKitchenLayoutForm;
