import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import RoleContext from './RoleContext';
import firebase from './firebase';

Modal.setAppElement('#root');

function CustomerUpdate(props: {
  customerId: string;
  email: string;
  phoneNumber: string;
  modalIsOpen: boolean;
  inProgress: Function;
  target: 'email' | 'phoneNumber';
}) {
  const role = useContext(RoleContext);

  if (!role.isCs()) {
    throw new Error('permission error');
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  async function submit(customerid: string, updateHash: {}) {
    const apiEndPoint = `${process.env.REACT_APP_api_server}/customers/${customerid}`;
    const auth = firebase.auth();

    await auth.currentUser!.getIdToken().then(async (token) => {
      await fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateHash),
      });
    });
  }

  const emptyDiv = () => <div />;

  async function confirmAndUpdate(customerId: string, updateHash: {}, cb: Function) {
    const options = {
      title: '更新',
      message: 'カスタマー情報の更新を行います',
      buttons: [
        {
          label: '実行する',
          onClick: async () => {
            cb(true);
            await submit(customerId, updateHash);
            cb(false);
          },
        },
        {
          label: '実行しない',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  }

  useEffect(() => {
    setModalIsOpen(props.modalIsOpen);
    return () => {};
  }, [props]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const progress = (start: boolean) => {
    props.inProgress(start);
  };

  const handleUpdateModal = async (e) => {
    e.preventDefault();
    closeModal();

    const updateHash: { [key: string]: any } = {};
    if (props.target === 'email') {
      updateHash.email = e.target.email.value || '';
    } else if (props.target === 'phoneNumber') {
      updateHash.phone_number = e.target.phoneNumber.value || '';
    } else {
      throw new Error('shouldn\t come here');
    }

    await confirmAndUpdate(props.customerId, updateHash, progress);
  };

  const closeModal = () => {
    props.inProgress(false);
    setModalIsOpen(false);
  };

  return (
    <>
      {modalIsOpen ? (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
          <form id="update_form" onSubmit={handleUpdateModal}>
            <div className="form-group">
              {props.target === 'email' && (
                <>
                  <h5>メールアドレス</h5>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="inputEmail"
                    defaultValue={props.email}
                    size={50}
                  />
                </>
              )}

              {props.target === 'phoneNumber' && (
                <>
                  <h5>携帯電話番号</h5>
                  <input
                    className="form-control"
                    type="tel"
                    name="phoneNumber"
                    id="inputPhoneNumber"
                    defaultValue={props.phoneNumber}
                  />
                </>
              )}

              <br />
              <input type="submit" className="btn btn-danger" value="更新" />
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                キャンセル
              </button>
            </div>
          </form>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default CustomerUpdate;
