import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import ReactHowler from 'react-howler';
import firebase from './firebase';
import { StoreContainer } from './store';

Modal.setAppElement('#root');

function OrdersOverview(props: {}) {
  const storeContainer = StoreContainer.useContainer();
  const [modalOpen, setModalOpen] = useState(true);
  const [readyAlarm, setReadyAlarm] = useState(false);
  const [play, setPlay] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  useEffect(() => {
    const playChime = () => {
      if (readyAlarm) {
        setPlay(true);
      }
    };

    let lastIds: Array<string> = [];

    const unregisterOrdersObserver = firebase
      .firestore()
      .collectionGroup('orders_for_kitchen')
      .where('kitchen_shop_id', '==', storeContainer.shopId)
      .where('status', 'in', ['cooking'])
      .orderBy('pickup_at', 'asc')
      .onSnapshot((snap) => {
        console.log('onSnapshot OrdersOverview');
        const ids: Array<string> = [];

        for (const doc of snap.docs) {
          ids.push(doc.id);
        }

        if (lastIds.length === 0 && ids.length > 0) {
          playChime();
        }

        lastIds = ids;
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [readyAlarm, storeContainer.shopId]);

  const playClicked = (e) => {
    // 今後自動で音声を再生するために、一度、ユーザーイベントで音声を再生する必要がある
    setPlay(true);
    setModalOpen(false);
  };

  const cancelClicked = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  const playEnded = (e) => {
    setPlay(false);
    setReadyAlarm(true);
  };

  return (
    <div>
      <ReactHowler src="/chime.mp3" onStop={playEnded} onEnd={playEnded} playing={play} />

      <Modal isOpen={modalOpen} style={customStyles}>
        <div>
          <button type="button" className="btn btn-primary btn-lg btn-block" onClick={playClicked}>
            アラームをオンにする
          </button>
          <button type="button" className="btn btn-light btn-sm btn-block" onClick={cancelClicked}>
            しない
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default withRouter(OrdersOverview);
