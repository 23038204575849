import React, { useState } from 'react';
import firebase from 'firebase/compat/app';

function SlotDeliveryGroupLimitCurrries(props: { slotSet; group }) {
  const [status, setStatus] = useState('');

  const slotSetData = props.slotSet.data();
  const handleChange = async (data) => {
    const newLimit = Number.parseInt(data.target.value, 10);

    const batch = firebase.firestore().batch();

    const groups = props.slotSet.data().delivery_groups;

    for (const group of groups) {
      if (group.id === props.group.id) {
        group.limit_curries_per_slot = Number.isNaN(newLimit) ? null : newLimit;
      }
    }

    batch.update(props.slotSet.ref, {
      delivery_groups: groups,
    });

    const slots = await firebase
      .firestore()
      .collectionGroup('slots')
      .where('kitchen_shop_id', '==', props.slotSet.data().shop_id)
      .where('slot_set_id', '==', props.slotSet.id)
      .get();

    for (const slot of slots.docs) {
      const slotData = slot.data();
      if (slotData.delivery_group?.id === props.group.id) {
        batch.update(slot.ref, {
          'delivery_group.limit_curries_per_slot': Number.isNaN(newLimit) ? null : newLimit,
        });
      }
    }

    await batch.commit();
    setStatus('更新完了');
  };

  return (
    <>
      {props.group.name}{' '}
      <input min={0} max={100} type="number" defaultValue={props.group?.limit_curries_per_slot} onBlur={handleChange} />
      食<span className="badge badge-success">{status}</span>
    </>
  );
}

export default SlotDeliveryGroupLimitCurrries;
