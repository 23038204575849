import React, { useContext, useEffect, useState } from 'react';
import firebase from './firebase';
import OrdersForKitchen from './OrdersForKitchen';
import { StoreContainer } from './store';

function Orders(props: { match }) {
  const storeContainer = StoreContainer.useContainer();
  useEffect(() => {
    firebase
      .firestore()
      .collection('orders')
      .doc(props.match.params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const orderData = doc.data()!;
          if (orderData.kitchen_shop_id !== storeContainer.shopId) {
            storeContainer.setShopId(orderData.kitchen_shop_id);
          }
        }
      });

    return () => {};
  }, [props.match.params.id, storeContainer]);

  return (
    <div id="waiting-orders" className="container-fluid">
      <OrdersForKitchen orderId={props.match.params.id} />
    </div>
  );
}

export default Orders;
