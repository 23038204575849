import React from 'react';
import firebase from './firebase';

interface IShopsContext {
  currentShop: firebase.firestore.DocumentSnapshot | null;
  shops: { [key: string]: firebase.firestore.DocumentSnapshot };
}

export default React.createContext<IShopsContext>({
  currentShop: null,
  shops: {},
});
