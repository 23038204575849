import React from 'react';

import { useHistory } from 'react-router-dom';
import PaymentType from './PaymentType';
import dayjs from './dayjs';

function OrderCustomerTooltip(props: { order }) {
  const history = useHistory();

  return (
    <div className="cooking-order-card-customer">
      <span className="order-tooltip order-customer-tooltip" data-order-tooltip-position="right">
        <span className="nickname">🧑 {props.order.nickname || '(ニックネーム未設定)'} 様</span>
        <div className="order-tooltip-content">
          <table>
            <tbody>
              <tr>
                <th>注文日時</th>
                <td>{dayjs(props.order.ordered_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}</td>
              </tr>
              <tr>
                <th>スロット日時</th>
                <td>{dayjs(props.order.pickup_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}</td>
              </tr>
              {props.order.manual_cooking_start && (
                <tr>
                  <th>事前到着</th>
                  <td>{dayjs(props.order.cooking_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}</td>
                </tr>
              )}
              {props.order.cooked_at && (
                <tr>
                  <th>調理完了日時</th>
                  <td>{dayjs(props.order.cooked_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}</td>
                </tr>
              )}
              {props.order.status === 'canceled' && (
                <tr>
                  <th>キャンセル日時</th>
                  <td>{dayjs(props.order.canceled_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}</td>
                </tr>
              )}
              {props.order.refunded_at && (
                <>
                  <tr>
                    <th>返金日時</th>
                    <td>{dayjs(props.order.refunded_at.toDate()).tz('Asia/Tokyo').format('MM/DD HH:mm')}</td>
                  </tr>
                  <tr>
                    <th>返金額</th>
                    <td>{props.order.refunded_amount} 円</td>
                  </tr>
                </>
              )}
              <tr>
                <th>クーポン利用</th>
                <td>{props.order.coupon_code ? props.order.coupon_code : 'なし'}</td>
              </tr>
              <tr>
                <th>支払い方法</th>
                <td>
                  <PaymentType order={props.order} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-right mt-2">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                history.push(`/customers/${props.order.customer_id}`);
              }}
            >
              詳細を見る
            </button>
          </div>
        </div>
      </span>
    </div>
  );
}

export default OrderCustomerTooltip;
