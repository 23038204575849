import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import firebase from './firebase';
import ShelfOrder from './ShelfOrder';
import { StoreContainer } from './store';

Modal.setAppElement('#root');

function CurrentShelves() {
  const storeContainer = StoreContainer.useContainer();

  const [leftShelves, setLeftShelves] = useState<number>();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [shelves, setShelves] = useState<Array<{ row: number; col: number; label: string; orderId: string | null }>>();

  useEffect(() => {
    const unregisterSlotsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('shelves')
      .orderBy('position')
      .onSnapshot((snap) => {
        console.log('onSnapshot CurrentShelves');
        let assigned = 0;
        const records: Array<{ row: number; col: number; label: string; orderId: string }> = [];
        for (const doc of snap.docs) {
          if (doc.data().order_id) {
            assigned += 1;
          }
          records.push({
            row: doc.data().row,
            col: doc.data().col,
            label: doc.data().label,
            orderId: doc.data().order_id,
          });
        }
        setShelves(records);
        setLeftShelves(snap.size - assigned);
      });

    return () => {
      unregisterSlotsObserver();
    };
  }, [storeContainer.shopId]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      width: '99%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  };

  const renderShalves = () => {
    const rows: Array<React.ReactElement> = [];

    let prevRow: number | null = null;
    let cols: Array<React.ReactElement> = [];
    for (const shelf of shelves!) {
      if (prevRow !== shelf.row) {
        if (cols.length > 0) {
          rows.push(<div className="row justify-content-center">{cols.reverse()}</div>);
        }
        cols = [];
      }
      cols.push(
        <div className="shelf">
          <ShelfOrder label={shelf.label} orderId={shelf.orderId} />
        </div>,
      );
      prevRow = shelf.row;
    }
    rows.push(<div className="row justify-content-center">{cols.reverse()}</div>);

    return <div className="container-fluid">{rows}</div>;
  };

  return (
    <>
      {leftShelves === undefined ? (
        <div>Loading</div>
      ) : (
        <>
          {modalIsOpen && (
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="ピックアップカウンター"
            >
              {renderShalves()}
            </Modal>
          )}

          <button
            type="button"
            className={`badge badge-${leftShelves === 0 ? 'danger' : leftShelves <= 3 ? 'warning' : 'primary'}`}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            <small>ピックアップカウンター残り {leftShelves}</small>
          </button>
        </>
      )}
    </>
  );
}

export default CurrentShelves;
