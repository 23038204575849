import React, { useEffect, useState } from 'react';
import firebase from './firebase';

function useCustomers(customerIds: Array<string>) {
  const [customers, setCustomers] = useState<Array<firebase.firestore.DocumentSnapshot>>();

  useEffect(() => {
    Promise.all(
      customerIds.map((customerId) => {
        return firebase
          .firestore()
          .collection('customers')

          .doc(customerId)
          .get();
      }),
    ).then((docs: Array<firebase.firestore.DocumentSnapshot>) => {
      setCustomers(docs);
    });
  }, [customerIds]);

  return customers;
}

export default useCustomers;
