import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useKitchenWarnings = (shopId: string): Array<firebase.firestore.DocumentData> => {
  const [kitchenWarningsSnapshot, setKitchenWarningsSnapshot] = useState<Array<firebase.firestore.DocumentData>>([]);

  useEffect(() => {
    const unregister = firebase
      .firestore()
      .collection('shops')
      .doc(shopId)
      .collection('kitchen_warnings')
      .where('enabled', '==', true)
      .orderBy('kitchen_order')
      .onSnapshot((snapshot) => {
        console.log('onSnapshot useKitchenWarnings');
        setKitchenWarningsSnapshot(snapshot.docs);
      });

    return () => {
      unregister();
    };
  }, [shopId]);

  return kitchenWarningsSnapshot;
};

export default useKitchenWarnings;
