import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useElementItems = (
  onlyEnabled: boolean = true,
  onlyEnabledKitchenCounter: boolean = false,
): Array<firebase.firestore.DocumentSnapshot> | null => {
  const [useElementItemsSnapshot, setElementItemsSnapshot] =
    useState<Array<firebase.firestore.DocumentSnapshot> | null>(null);

  useEffect(() => {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = firebase
      .firestore()
      .collection('element_items');

    if (onlyEnabled) query = query.where('enabled', '==', true);
    if (onlyEnabledKitchenCounter) query = query.where('enabled_kitchen_counter', '==', true);

    query.get().then((snapshot) => {
      console.log('get useElementItems');
      // indexを節約するためにローカルでソート
      setElementItemsSnapshot(snapshot.docs.sort((a, b) => a.data().kitchen_order - b.data().kitchen_order));
    });
  }, [onlyEnabled, onlyEnabledKitchenCounter]);

  return useElementItemsSnapshot;
};

export default useElementItems;
