import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import dayjs from './dayjs';

function ScheduledMessages() {
  const [messages, setMessages] = useState<any>();

  useEffect(() => {
    const unregisterMessagesObserver = firebase
      .firestore()
      .collection('scheduled_messages')
      .where('status', '==', 'scheduled')
      .orderBy('send_at', 'desc')
      .onSnapshot((snap) => {
        console.log('onSnapshot ScheduledMessages');
        loadOtherContent(snap);
      });

    return () => {
      unregisterMessagesObserver();
    };
  }, []);

  async function loadOtherContent(snap) {
    const records = {};
    for (const docSnapshot of snap.docs) {
      const data = docSnapshot.data();

      if (data.payload.segment.shop_ids) {
        data.shop_name = (
          await Promise.all(
            data.payload.segment.shop_ids.map(async (shopId) => {
              if (shopId === 'OTHERS') {
                return 'どの店舗にも属していない人';
              }
              const shop = await firebase.firestore().collection('shops').doc(shopId).get();
              return shop.data()!.short_name;
            }),
          )
        ).join(', ');
      }

      if (data.payload.message.coupon_id) {
        const coupon = await firebase.firestore().collection('coupons').doc(data.payload.message.coupon_id).get();
        data.coupon_code = coupon.data()!.code;
      }

      records[docSnapshot.id] = data;
    }

    setMessages(records);
  }

  const submit = (messageId) => {
    firebase.firestore().collection('scheduled_messages').doc(messageId).delete();
  };

  const emptyDiv = () => <div />;

  const cancelScheduledMessage = (messageId) => {
    const options = {
      title: '送信予約のキャンセルを行います。',
      buttons: [
        {
          label: 'はい',
          onClick: () => {
            submit(messageId);
          },
        },
        {
          label: 'いいえ',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const notificationChannelsMap = { order: 'オーダー', marketing: 'お知らせ' };

  return (
    <div className="container-fluid h-100">
      <h3>送信予約メッセージ</h3>

      <div id="messages" className="container-fluid h-80">
        <div className="scrollable-messages h-100">
          {messages === undefined ? (
            <div className="row">
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {Object.keys(messages).length === 0 ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-light text-center" role="alert">
                      ありません
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>-</th>
                        <th>タイプ</th>
                        <th>種別</th>
                        <th>送信対象店舗</th>
                        <th>オフィス送信(親店舗別)</th>
                        <th>通知チャンネル</th>
                        <th>タイトル</th>
                        <th>本文</th>
                        <th>クーポン</th>
                        <th>通知画像</th>
                        <th>カバー画像</th>
                      </tr>
                      {Object.keys(messages).map((messageId) => (
                        <tr key={`message-${messageId}`}>
                          <td>
                            <NavLink to={`/admin/messages/${messageId}`}>編集</NavLink>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {messages[messageId].send_option === 'time_specified' && (
                              <>
                                <div>時間指定</div>
                                {dayjs(messages[messageId].send_at.toDate())
                                  .tz('Asia/Tokyo')
                                  .format('YYYY/MM/DD HH:mm')}
                                {messages[messageId].send2_at &&
                                  dayjs(messages[messageId].send2_at.toDate())
                                    .tz('Asia/Tokyo')
                                    .format('YYYY/MM/DD HH:mm')}
                                {messages[messageId].send3_at &&
                                  dayjs(messages[messageId].send3_at.toDate())
                                    .tz('Asia/Tokyo')
                                    .format('YYYY/MM/DD HH:mm')}
                              </>
                            )}
                            {messages[messageId].send_option === 'batch_delivery_reminder' &&
                              '法人デリバリーリマインダー'}
                            {messages[messageId].send_option === 'hakase' && (
                              <>
                                <div>博士ロジック</div>
                                {messages[messageId].hakase_send_date}
                              </>
                            )}
                          </td>
                          <td>{messages[messageId].payload.segment.customer_ids ? '個別' : '全体'}</td>
                          <td>{messages[messageId].shop_name || '-'}</td>
                          <td>{messages[messageId].batch_delivery_parent_shop_name || '-'}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {notificationChannelsMap[messages[messageId].payload.message.notification_channel]}
                          </td>
                          <td>{messages[messageId].payload.message.subject}</td>
                          <td>
                            <pre style={{ whiteSpace: 'pre-wrap' }}>{messages[messageId].payload.message.body}</pre>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>{messages[messageId].coupon_code || '-'}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {messages[messageId].payload.message.image_url ? (
                              <img
                                alt={messages[messageId].payload.message.image_url}
                                src={messages[messageId].payload.message.image_url}
                                height="50px"
                              />
                            ) : (
                              'なし'
                            )}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {messages[messageId].payload.message.cover_image_url ? (
                              <img
                                alt={messages[messageId].payload.message.cover_image_url}
                                src={messages[messageId].payload.message.cover_image_url}
                                height="50px"
                              />
                            ) : (
                              'なし'
                            )}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => cancelScheduledMessage(messageId)}
                            >
                              予約キャンセル
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ScheduledMessages;
