import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import withTMCAuth from './withTMCAuth';
import RoleContext from './RoleContext';

function AdminNavigator() {
  const role = useContext(RoleContext);

  return (
    <ul className="navbar-nav mr-auto">
      {role.isCs() && (
        <li className="nav-item">
          <NavLink to="/admin/coupons" className="nav-link" activeClassName="active">
            クーポン
          </NavLink>
        </li>
      )}
      {role.isSuperAdmin() && (
        <li className="nav-item">
          <NavLink to="/admin/messages" className="nav-link" activeClassName="active">
            メッセージ
          </NavLink>
        </li>
      )}

      {role.isSuperAdmin() && (
        <li className="nav-item">
          <NavLink to="/admin/newsletters" className="nav-link" activeClassName="active">
            ニュースレター
          </NavLink>
        </li>
      )}
      {role.isSuperAdmin() && (
        <li className="nav-item">
          <NavLink to="/admin/shops" className="nav-link" activeClassName="active">
            店舗
          </NavLink>
        </li>
      )}
      {role.isCs() && (
        <li className="nav-item">
          <NavLink to="/admin/search_customers" className="nav-link" activeClassName="active">
            カスタマー検索
          </NavLink>
        </li>
      )}
      {role.isSuperAdmin() && (
        <li className="nav-item">
          <NavLink to="/admin/shop_kitchen_layouts" className="nav-link" activeClassName="active">
            盛り付けレイアウト
          </NavLink>
        </li>
      )}
      {role.isSuperAdmin() && (
        <li className="nav-item">
          <NavLink to="/admin/all_slots" className="nav-link" activeClassName="active">
            全スロット
          </NavLink>
        </li>
      )}

      <li className="nav-item">
        <NavLink to="/" className="nav-link" activeClassName="active" exact>
          戻る
        </NavLink>
      </li>
    </ul>
  );
}

export default withTMCAuth(AdminNavigator);
