import React, { useEffect, useState } from 'react';
import firebase from './firebase';
import CustomerLink from './CustomerLink';

function InvitedFriends(props: { couponId }) {
  const [friends, setFriends] = useState<any>();

  useEffect(() => {
    const unregisterFriendsObserver = firebase
      .firestore()
      .collection('coupons')
      .doc(props.couponId)
      .collection('consumed_customers')
      .onSnapshot((snap) => {
        console.log('onSnapshot InvitedFriends');
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });

        setFriends(records);
      });

    return () => {
      unregisterFriendsObserver();
    };
  }, [props.couponId]);

  return (
    <div>
      {friends === undefined ? (
        ''
      ) : (
        <>
          {Object.keys(friends).length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>ニックネーム</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(friends).map((friendId) => (
                  <tr key={friendId}>
                    <td>
                      <CustomerLink customerId={friendId} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            'なし'
          )}
        </>
      )}
    </div>
  );
}

function CustomerFriends(props: { customerId: string }) {
  const [coupons, setCoupons] = useState<any>();

  useEffect(() => {
    const unregisterCouponsObserver = firebase
      .firestore()
      .collection('coupons')
      .where('inviter_id', '==', props.customerId)
      .onSnapshot((snap) => {
        console.log('onSnapshot CustomerFriends');
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });

        setCoupons(records);
      });

    return () => {
      unregisterCouponsObserver();
    };
  }, [props.customerId]);

  return (
    <div>
      {coupons === undefined ? (
        'loading'
      ) : (
        <>
          {Object.keys(coupons).length > 0 ? (
            <div className="row">
              <div className="col">
                <div className="alert alert-light text-left" role="alert">
                  {Object.keys(coupons).map((couponId) => (
                    <InvitedFriends key={couponId} couponId={couponId} />
                  ))}
                </div>
              </div>
            </div>
          ) : (
            'なし'
          )}
        </>
      )}
    </div>
  );
}

export default CustomerFriends;
