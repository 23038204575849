import React from 'react';

export default class Common {
  static pickupPoint(order) {
    return (
      <>
        {order.shelf_status && (
          <>
            【
            {order.shelf_status === 'error' ? (
              <span className="badge badge-danger">フル</span>
            ) : (
              <>{order.shelf_label || '-'}</>
            )}
            】
          </>
        )}
        {order.table_number && <>【{order.table_number}】</>}
      </>
    );
  }
}
