import React, { useState } from 'react';

const ReadMore = (props: { text: string }) => {
  const { text } = props;

  const [showFullText, setShowFullText] = useState(false);
  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  const maxLength = 20;

  const trimmedText = text.trim();
  const noNewline = trimmedText.replace('\n', ' ');
  const requireReadmore = noNewline.length > maxLength || trimmedText !== noNewline;

  return (
    <div className="row align-items-center">
      {requireReadmore ? (
        <>
          <div className={`col ${showFullText ? '' : 'text-truncate'}`} style={{ maxWidth: `${maxLength}rem` }}>
            {trimmedText}
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={() => {
                toggleReadMore();
              }}
            >
              {showFullText ? '隠す' : '全文表示'}
            </button>
          </div>
        </>
      ) : (
        <div className="col">{trimmedText}</div>
      )}
    </div>
  );
};

export default ReadMore;
