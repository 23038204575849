import { useState } from 'react';
import { createContainer } from 'unstated-next';
import useLocalStorage from './use_local_storage';

export const useStore = () => {
  const [shopId, setShopId] = useLocalStorage('shop_id', '');
  const [line, setLine] = useLocalStorage('line', 1);
  const [pickupNowLine, setPickupNowLine] = useLocalStorage('pickup_now_line', false);
  const [cookedLine, setCookedLine] = useLocalStorage<number | 'pickup_now' | 'slot_order' | 'all'>(
    'cooked_line',
    'all',
  );
  const [crewId, setCrewId] = useLocalStorage<string | null>('crew_id', null);
  const [cookingKitchenLayoutId, setCookingKitchenLayoutId] = useLocalStorage('cooking_kitchen_layout_id', '');
  const [cookingKitchenLayoutReverse, setCookingKitchenLayoutReverse] = useLocalStorage(
    'cooking_kitchen_layout_reverse',
    false,
  );

  const cookingKitchenLayoutFlexRowClassName = `flex-row${cookingKitchenLayoutReverse ? '-reverse' : ''}`;

  return {
    shopId,
    setShopId,
    line,
    setLine,
    pickupNowLine,
    setPickupNowLine,
    cookedLine,
    setCookedLine,
    crewId,
    setCrewId,
    cookingKitchenLayoutId,
    setCookingKitchenLayoutId,
    cookingKitchenLayoutReverse,
    setCookingKitchenLayoutReverse,
    cookingKitchenLayoutFlexRowClassName,
  };
};

export const StoreContainer = createContainer(useStore);
