import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import dayjs from './dayjs';
import firebase from './firebase';
import ToppingList from './ToppingList';
import ShopDay from './ShopDay';
import { StoreContainer } from './store';
import ShopsContext from './ShopsContext';

Modal.setAppElement('#root');

function Topping() {
  const storeContainer = StoreContainer.useContainer();
  const [shop, setShop] = useState<firebase.firestore.DocumentData>();

  const { currentShop } = useContext(ShopsContext);

  useEffect(() => {
    const shopData = currentShop?.data();

    if (shopData) {
      setShop(shopData);
    }
  }, [currentShop]);

  return (
    <div className="container-fluid">
      {shop ? (
        <>
          {shop.kitchen_shop_id ? (
            '盛り付けを行う店舗で参照してください'
          ) : (
            <>
              <div className="row justify-content-between align-items-center my-4">
                <div className="col-auto" />
                <div className="col-auto">
                  <ShopDay />
                </div>
              </div>

              <ToppingList />
            </>
          )}
        </>
      ) : (
        'loading...'
      )}
    </div>
  );
}

export default Topping;
