import React, { useEffect, useState } from 'react';
import firebase from './firebase';
import useKitchenLayout from './hooks/useKitchenLayout';
import { StoreContainer } from './store';
import Order from './Order';

function CanceledOrders() {
  const [orders, setOrders] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();
  const storeContainer = StoreContainer.useContainer();
  const kitchenLayoutSnapshot = useKitchenLayout();

  useEffect(() => {
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('status', '==', 'canceled')
      .where('canceled_at', '>=', new Date(+new Date() - 30 * 24 * 60 * 60 * 1000))
      .orderBy('canceled_at', 'desc')
      .onSnapshot((snap) => {
        console.log('onSnapshot CanceledOrders');
        setOrders(snap.docs);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId]);

  return (
    <div id="canceled-orders" className="container-fluid">
      <div className="scrollable-orders">
        {orders === undefined ? (
          <div className="row">
            <div className="col text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            {Object.keys(orders).length === 0 ? (
              <div className="row">
                <div className="col">
                  <div className="alert alert-light text-center" role="alert">
                    ありません
                  </div>
                </div>
              </div>
            ) : (
              orders.map((tmp) => (
                <Order
                  key={tmp.id}
                  kitchenOrderId={tmp.id}
                  order={tmp.data()}
                  line={null}
                  kitchenLayoutSnapshot={kitchenLayoutSnapshot}
                  hideWhenDone
                />
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CanceledOrders;
