import React, { useEffect, useState } from 'react';

import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';

function CurrentSlots() {
  const storeContainer = StoreContainer.useContainer();
  const [counter, setCounter] = useState(0);
  const [full, setFull] = useState(false);
  interface ISlot {
    time: Date;
    timeUntil: Date;
    available: number;
  }

  const [slots, setSlots] = useState<Array<ISlot>>();

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => prev + 1);
    }, 60 * 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const now = new Date();
    const limit = full ? 100 : 4;
    const unregisterSlotsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('slots')
      .where('time_until', '>', now)
      .orderBy('time_until')
      .limit(limit)
      .onSnapshot((snap) => {
        console.log('onSnapshot CurrentSlots');
        const records: Array<ISlot> = [];
        for (const doc of snap.docs) {
          const data = doc.data();
          const slot: ISlot = {
            time: data.time.toDate(),
            timeUntil: data.time_until.toDate(),
            available: data.available,
          };
          records.push(slot);
        }
        setSlots(records);
      });

    return () => {
      console.log('unregister CurrentSlots');
      unregisterSlotsObserver();
    };
  }, [storeContainer.shopId, counter, full]);

  return (
    <>
      {slots === undefined ? (
        <div>Loading</div>
      ) : (
        // Lint導入前のコードのため。後で直す
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={() => {
            setFull((a) => !a);
          }}
        >
          <table className="table table-sm table-borderless">
            <tbody>
              {slots.map((data) => (
                <tr key={data.time.getTime()}>
                  <td className="text-center">
                    {data.time < new Date() ? '今' : <small>{dayjs(data.time).fromNow()}</small>}
                  </td>
                  <td>{dayjs(data.time).tz('Asia/Tokyo').format('HH:mm')}</td>
                  <td>
                    {data.available > 0 ? (
                      <>
                        <small>残り</small> {data.available}
                      </>
                    ) : (
                      <span className="badge badge-warning" role="alert">
                        売り切れ
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default CurrentSlots;
