import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import firebase from './firebase';
import { StoreContainer } from './store';
import dayjs from './dayjs';

function NavigatorOrderDetail(props: { orderId: string; order: firebase.firestore.DocumentData }) {
  const storeContainer = StoreContainer.useContainer();
  const [pastOrders, setPastOrders] = useState<Array<{ id: string; data: firebase.firestore.DocumentData }> | null>(
    null,
  );

  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    if (showDetail) {
      firebase
        .firestore()
        .collection('orders')
        .where('customer_id', '==', props.order.customer_id)
        .where('cooked_at', '!=', null)
        .orderBy('cooked_at', 'desc')
        .limit(5)
        .get()
        .then((snap) => {
          const records: Array<{ id: string; data: firebase.firestore.DocumentData }> = [];
          snap.docs.forEach((docSnapshot) => {
            if (docSnapshot.id !== props.orderId) {
              records.push({
                id: docSnapshot.id,
                data: docSnapshot.data(),
              });
            }
          });
          setPastOrders(records);
        });
    }
    return () => {};
  }, [props.orderId, props.order, storeContainer.shopId, showDetail]);

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  return (
    <div className="row pt-2">
      <div className="col-2">
        <small>
          🧾&nbsp;<strong>注文履歴</strong>
        </small>
      </div>
      <div className="col pl-0">
        {showDetail && (
          <ul className="list-group">
            {pastOrders &&
              pastOrders.map((order) => (
                <li key={order.id} className="list-group-item py-2 small text-muted">
                  <div className="row align-items-center">
                    <div className="col-auto small">
                      {dayjs(order.data.pickup_at.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}
                    </div>
                    <div className="col pl-0">{order.data.items.map((item) => item.curry.name).join(', ')}</div>
                    <div className="col-auto pl-0 text-right">
                      <NavLink to={`/orders/${order.id}`}>詳細</NavLink>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        )}
      </div>
      <div className="col-auto">
        <button type="button" onClick={() => toggleShowDetail()} className="btn btn-secondary btn-sm">
          {showDetail ? '隠す' : '表示'}
        </button>
      </div>
    </div>
  );
}

export default NavigatorOrderDetail;
