import React from 'react';

function PaymentType(props: { order: any }) {
  if (props.order.self_checkout || props.order.paylater) {
    const label = props.order.self_checkout ? 'セルフレジ払い' : '決済レジ払い';

    const status = (
      <>
        {props.order.square_order_id ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://squareup.com/dashboard/sales/transactions/${props.order.square_order_id}`}
          >
            {label}(Square)
          </a>
        ) : props.order.stores ? (
          `${label}(Stores)`
        ) : props.order.paypay ? (
          `${label}(PayPay)`
        ) : (
          `${label}(支払い方法不明)`
        )}
      </>
    );

    return status;
  }

  const status = props.order.paypay ? (
    <>PayPay</>
  ) : props.order.stripe_charge_id ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://dashboard.stripe.com/payments/${props.order.stripe_charge_id}`}
    >
      クレジットカード
    </a>
  ) : props.order.stripe_payment_intent_id ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://dashboard.stripe.com/payments/${props.order.stripe_payment_intent_id}`}
    >
      クレジットカード(QR)
    </a>
  ) : (
    <>-</>
  );

  return status;
}

export default PaymentType;
