import { useContext, useEffect, useState } from 'react';
import firebase from '../firebase';
import ShopsContext from '../ShopsContext';
import { StoreContainer } from '../store';

const useKitchenLayout = (): firebase.firestore.DocumentData | null => {
  const storeContainer = StoreContainer.useContainer();
  const { shops } = useContext(ShopsContext);
  const [kitchenLayoutSnapshot, setKitchenLayoutSnapshot] = useState<firebase.firestore.DocumentData | null>(null);

  useEffect(() => {
    (async () => {
      const kitchenLayoutId = shops[storeContainer.shopId].data()!.kitchen_layout_id;
      if (!kitchenLayoutId) return;

      const snapshot = await firebase.firestore().collection('kitchen_layouts').doc(kitchenLayoutId).get();

      setKitchenLayoutSnapshot(snapshot);
    })();
  }, [shops, storeContainer.shopId]);

  return kitchenLayoutSnapshot;
};

export default useKitchenLayout;
