class Role {
  roles: Array<string>;

  constructor(roles: Array<string>) {
    this.roles = roles;
  }

  isSuperAdmin() {
    return this.roles.includes('super-admin');
  }

  isCs() {
    return this.isSuperAdmin() || this.roles.includes('cs');
  }

  isCrewAdmin() {
    return this.isSuperAdmin() || this.roles.includes('crew-admin');
  }

  isCrew() {
    return this.isCrewAdmin() || this.roles.includes('crew');
  }
}

export default Role;
