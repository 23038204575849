// eslint-disable-next-line max-classes-per-file
import React, { useContext, useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-datasheet/lib/react-datasheet.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import ShopsContext from './ShopsContext';
import useElementItems from './hooks/useElementItems';
import firebase from './firebase';
import Utils from './Utils';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
  value: number | null;
}

class MyReactDataSheet extends ReactDataSheet<GridElement, number> {}

interface AppState {
  grid: GridElement[][];
}

class ToppingHandler {
  shopId: string;

  elementItemId: string;

  constructor(shopId: string, elementItmeId: string) {
    this.shopId = shopId;
    this.elementItemId = elementItmeId;
  }

  update(value: number, batch: firebase.firestore.WriteBatch) {
    const ref = firebase
      .firestore()
      .collection('shops')
      .doc(this.shopId)
      .collection('topping_plans')
      .doc(this.elementItemId);

    batch.set(
      ref,
      {
        shop_id: this.shopId,
        element_item_id: this.elementItemId,
        limit_per_day: value,
        available_for_kitchen: value !== null,
        kitchen_updated_at: firebase.firestore.Timestamp.now(),
      },
      { merge: true },
    );

    return true;
  }
}

class ShopRecipe {
  shopId: string;

  recipes = {};

  name: string;

  order: number;

  constructor(shopId: string, name: string, order: number) {
    this.shopId = shopId;
    this.name = name;
    this.order = order;
  }

  updateValue(elementItemId: string, value: number) {
    this.recipes[elementItemId] = value;
  }
}

function transpose(a, defaultValue) {
  return Object.keys(a[0]).map((c) => {
    return a.map((r) => {
      return r[c] || defaultValue;
    });
  });
}

function ShopBatchUpdate() {
  const { shops } = useContext(ShopsContext);
  const [shopRecipes, setShopRecipes] = useState<{ [key: string]: ShopRecipe }>();
  const elementItems = useElementItems();

  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [messageImported, setMessageImported] = useState('');
  const [reloadCounter, setReloadCounter] = useState(0);

  const emptyDiv = () => <div />;
  const importGoogleSheet = (e) => {
    e.preventDefault();
    const options = {
      title: 'Google Sheets取り込み',
      message: 'Google Sheetsの在庫情報を取り込みますか？',
      buttons: [
        {
          label: '実行する',
          onClick: async () => {
            setLoading(true);
            const auth = firebase.auth();
            const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/import_stocks/`;

            auth.currentUser!.getIdToken().then((token) => {
              fetch(apiEndPoint, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({}),
              })
                .then(async (response) => {
                  setLoading(false);
                  const responseJson = await response.json();
                  if (response.status === 200) {
                    setMessageImported('取り込み完了');
                    setReloadCounter((prev) => prev + 1);
                  } else {
                    setMessageImported(`取り込みエラー ${responseJson.error.message}`);
                  }
                })
                .catch(() => {
                  setLoading(false);
                  setMessageImported('取り込みエラー');
                });
            });
          },
        },
        {
          label: '実行しない',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collectionGroup('topping_plans')
      .get()
      .then((snapshot) => {
        const newShopRecipes: { [key: string]: ShopRecipe } = {};

        for (const shop of Object.keys(shops)
          .map((shopId) => shops[shopId])
          .filter((s) => !s.data()!.kitchen_shop_id && s.data()!.enabled)) {
          newShopRecipes[shop.id] = new ShopRecipe(shop.id, shop.data()!.short_name, shop.data()!.order);
        }

        for (const doc of snapshot.docs) {
          const plan = doc.data()!;
          const shopId = doc.ref.parent.parent!.id;

          if (!newShopRecipes[shopId]) {
            continue;
          }

          const shopRecipe = newShopRecipes[shopId];

          if (plan.element_item_id) {
            shopRecipe.updateValue(plan.element_item_id, plan.limit_per_day);
          }
        }

        setShopRecipes(newShopRecipes);
      });
  }, [shops, reloadCounter]);

  useEffect(() => {
    if (shopRecipes && elementItems) {
      const grid: any = [];

      const elementNames = elementItems.map((element) => {
        return {
          value: element.data()!.kitchen_name,
          width: 200,
          readOnly: true,
        };
      });
      const header = [{ value: '', width: 200, readOnly: true }].concat(elementNames);

      grid.push(header);

      for (const shopRecipe of Object.values(shopRecipes).sort((a, b) => a.order - b.order)) {
        const toppingRow = elementItems.map((elementItem) => {
          return {
            value: shopRecipe.recipes[elementItem.id],
            width: 150,
            readOnly: false,
            handler: new ToppingHandler(shopRecipe.shopId, elementItem.id),
          };
        });

        const row = [{ value: shopRecipe.name, width: 150, readOnly: true }].concat(toppingRow);
        grid.push(row);
      }
      setData(transpose(grid, { value: '', width: 150 }));
    }
  }, [shopRecipes, elementItems]);

  const onGridRowsUpdated = (changes) => {
    setData((prevData) => {
      const batch = firebase.firestore().batch();
      const grid = prevData.map((row) => [...row]);
      changes.forEach(({ cell, row, col, value }) => {
        let updatingValue: null | number = null;
        let valid = false;
        if (value === '') {
          updatingValue = null;
          valid = true;
        } else {
          const intValue = parseInt(value, 10);
          if (Utils.isNumber(intValue)) {
            updatingValue = intValue;
            valid = true;
          }
        }

        if (valid) {
          grid[row][col] = { ...grid[row][col], value: updatingValue };

          if (cell.handler) {
            cell.handler.update(updatingValue, batch);
          }
        }
      });

      batch.commit();
      return grid;
    });
  };

  return (
    <>
      <div className="ml-2 mb-2">
        <button type="button" className="btn btn-sm btn-primary" onClick={importGoogleSheet}>
          Google Sheets取り込み
        </button>
        <span className="ml-2">{messageImported}</span>
      </div>
      {elementItems && shopRecipes && (
        <MyReactDataSheet
          className="reset-bootstrap-for-datasheet"
          data={data}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={onGridRowsUpdated}
        />
      )}
      <WindmillSpinnerOverlay loading={loading} message="取り込み中" />
    </>
  );
}

export default ShopBatchUpdate;
