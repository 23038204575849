import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import * as holidayJP from '@holiday-jp/holiday_jp';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';

Modal.setAppElement('#root');

function NumberOfOrdered(props: { kitchenShopId: string; date: dayjs.Dayjs }) {
  const [numberOfOrders, setNumberOfOrders] = useState<number>();

  useEffect(() => {
    const unregisterShopObserver = firebase
      .firestore()
      .collection('orders')
      .where('kitchen_shop_id', '==', props.kitchenShopId)
      .where('pickup_at', '>=', props.date.startOf('day').toDate())
      .where('pickup_at', '<', props.date.endOf('day').toDate())
      .onSnapshot((doc) => {
        console.log('onSnapshot NumberOfOrdered');
        setNumberOfOrders(doc.docs.filter((doc2) => doc2.data().status !== 'canceled').length);
      });

    return () => {
      unregisterShopObserver();
    };
  }, [props.date, props.kitchenShopId]);

  return <>{numberOfOrders}</>;
}

function AssignSlotPatterns(props: { match }) {
  const [posting, setPosting] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');

  const [shop, setShop] = useState<any>();
  const [slotPatterns, setSlotPatterns] = useState<any>();
  const [assignedSlotPatterns, setAssignedSlotPatterns] = useState<any>();

  const [shopId, setShopId] = useState<string>();

  const [month, setMonth] = useQueryParam('month', withDefault(StringParam, ''));

  const startDate = dayjs
    .max(month ? dayjs.tz(month, 'Asia/Tokyo') : dayjs().tz('Asia/Tokyo').startOf('month'), dayjs().tz('Asia/Tokyo'))!
    .startOf('day');
  const endDate = startDate.add(1, 'month').startOf('month');

  useEffect(() => {
    const givenId = props.match.params.id;
    setShopId(givenId);

    const unregisterShopObserver = firebase
      .firestore()
      .collection('shops')
      .doc(givenId)
      .onSnapshot((doc) => {
        console.log('onSnapshot AssignSlotPatterns');
        if (doc.exists) {
          setShop(doc.data());
        }
      });

    return () => {
      unregisterShopObserver();
    };
  }, [props.match.params.id]);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('slot_patterns')
      .where('shop_id', '==', props.match.params.id)
      .where('archived', '==', false)
      .onSnapshot((snap) => {
        console.log('onSnapshot AssignSlotPatterns');
        const records = {};
        snap.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        });

        setSlotPatterns(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [props.match.params.id]);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(props.match.params.id)
      .collection('assigned_slot_patterns')
      .where('date', '>=', startDate.format('YYYY-MM-DD'))
      .where('date', '<', endDate.format('YYYY-MM-DD'))
      .onSnapshot((snap) => {
        console.log('onSnapshot AssignSlotPatterns');
        const records = {};
        snap.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        });

        setAssignedSlotPatterns(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [props.match.params.id, startDate, endDate]);

  const days: Array<dayjs.Dayjs> = [];

  for (let day = startDate; day.isBefore(endDate); day = day.add(1, 'days')) {
    days.push(day);
  }

  const patternChanged = (e, day: string, patternId: string | null) => {
    const ref = firebase
      .firestore()
      .collection('shops')
      .doc(props.match.params.id)
      .collection('assigned_slot_patterns')
      .doc(day);

    if (patternId) {
      ref.set(
        {
          date: day,
          shop_id: props.match.params.id,
          slot_pattern_id: patternId,
        },
        { merge: true },
      );
    } else {
      ref.delete();
    }
  };

  const monthChanged = (e) => {
    setAssignedSlotPatterns(null);
    setMonth(e.target.value);
  };

  const syncCalendar = (date: string) => {
    const emptyDiv = () => <div />;

    const options = {
      title: 'スロットの(再)作成を行います',
      message: 'この店舗と配下の店舗が更新されます',
      buttons: [
        {
          label: '同期する',
          onClick: () => {
            sync(date);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const sync = (date: string) => {
    const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${shopId}/sync_calendar/`;
    const auth = firebase.auth();
    setPosting(true);

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ date }),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200) {
            setMessageSuccess('同期完了しました。実際の反映までには数分かかることがあります。');
          } else {
            setMessageError(responseJson.error.message);
          }
          setPosting(false);
        })
        .catch(() => {
          setMessageError('同期に失敗しました');
          setPosting(false);
        });
    });
  };

  const thisMonth = dayjs().tz('Asia/Tokyo').startOf('month');
  return (
    <div className="container-fluid h-100">
      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

      {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

      {shopId && shop && slotPatterns && assignedSlotPatterns ? (
        <>
          <h3>
            {shop.short_name} (<NavLink to={`/admin/shop_slot_patterns/${props.match.params.id}`}>パターン設定</NavLink>
            )
          </h3>

          <select onChange={monthChanged} defaultValue={startDate.format('YYYYMM')}>
            <option value={thisMonth.format('YYYYMM')}>{thisMonth.format('YYYY-MM')}</option>
            <option value={thisMonth.add(1, 'month').format('YYYYMM')}>
              {thisMonth.add(1, 'month').format('YYYY-MM')}
            </option>
            <option value={thisMonth.add(2, 'month').format('YYYYMM')}>
              {thisMonth.add(2, 'month').format('YYYY-MM')}
            </option>
          </select>

          <table key={startDate.format('YYYYMM')} className="assign-patterns">
            <thead>
              <tr>
                <th> </th>
                <th className="pattern">休業</th>
                {Object.keys(slotPatterns).map((patternId) => (
                  <th key={patternId} className="pattern">
                    <NavLink to={`/admin/shop_slot_patterns/${props.match.params.id}?slot_pattern_id=${patternId}`}>
                      {slotPatterns[patternId].label}
                    </NavLink>
                  </th>
                ))}
                <th>既存注文数</th>
                <th>最終更新日時</th>
                <th> </th>
              </tr>
            </thead>

            <tbody>
              {days.map((day) => (
                <tr key={day.unix()}>
                  <th className="day">
                    <span
                      className={
                        holidayJP.isHoliday(new Date(day.format('YYYY-MM-DD'))) || day.day() === 0 || day.day() === 6
                          ? 'holiday'
                          : ''
                      }
                    >
                      {day.format('YYYY-MM-DD (ddd)')}
                    </span>
                  </th>

                  <td>
                    <div>
                      <label>
                        {assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_created_at ? (
                          <>
                            <input
                              type="radio"
                              defaultChecked={!assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_pattern_id}
                              disabled
                            />
                          </>
                        ) : (
                          <input
                            type="radio"
                            name={day.format('YYYY-MM-DD')}
                            defaultChecked={!assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_pattern_id}
                            value={day.format('YYYY-MM-DD')}
                            onChange={(e) => patternChanged(e, e.target.value, null)}
                          />
                        )}
                      </label>
                    </div>
                  </td>
                  {Object.keys(slotPatterns).map((patternId) => (
                    <td key={patternId}>
                      <div>
                        <label>
                          {assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_created_at ? (
                            <input
                              type="radio"
                              defaultChecked={
                                assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_pattern_id === patternId
                              }
                              disabled
                            />
                          ) : (
                            <input
                              type="radio"
                              name={day.format('YYYY-MM-DD')}
                              defaultChecked={
                                assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_pattern_id === patternId
                              }
                              value={day.format('YYYY-MM-DD')}
                              onChange={(e) => patternChanged(e, e.target.value, patternId)}
                            />
                          )}
                        </label>
                      </div>
                    </td>
                  ))}

                  <td>
                    <div>
                      {assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_created_at ? (
                        <NumberOfOrdered kitchenShopId={shopId} date={day} />
                      ) : (
                        '-'
                      )}
                    </div>
                  </td>

                  <td>
                    <div>
                      {assignedSlotPatterns[day.format('YYYY-MM-DD')]?.slot_created_at
                        ? dayjs(assignedSlotPatterns[day.format('YYYY-MM-DD')].slot_created_at.toDate())
                            .tz('Asia/Tokyo')
                            .format('YYYY/MM/DD HH:mm')
                        : '-'}
                    </div>
                  </td>

                  <td>
                    <div>
                      {assignedSlotPatterns[day.format('YYYY-MM-DD')] ? (
                        <>
                          <input
                            type="button"
                            className="btn btn-secondary"
                            value="スロット(最大食数)の(再)生成"
                            onClick={() => {
                              syncCalendar(day.format('YYYY-MM-DD'));
                            }}
                          />
                        </>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
      <WindmillSpinnerOverlay loading={posting} message="スロット更新中" />
    </div>
  );
}

export default AssignSlotPatterns;
