import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NavLink } from 'react-router-dom';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';

function SearchCustomers() {
  const [customers, setCustomers] = useState<Array<{ id: string; nickname: string }>>([]);
  const [loading, setLoading] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    const apiEndPoint = `${process.env.REACT_APP_api_server}/customers/search`;
    const auth = firebase.auth();
    const payload = {
      search: e.target.search.value,
    };

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200) {
            setCustomers(responseJson.customers);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    });
  };

  return (
    <div className="container-fluid h-100">
      <h3>カスタマー検索</h3>

      <div className="container-fluid">
        <form id="search-form" onSubmit={submit}>
          <div className="form-group row">
            <input
              type="text"
              size={70}
              placeholder="メールアドレス / 電話番号 / ニックネーム / 招待コード / 未使用クーポン"
              name="search"
            />
            <input type="submit" className="btn btn-sm btn-primary" value="検索" />
          </div>
        </form>
      </div>

      <div id="customers" className="container-fluid h-80">
        <div className="scrollable-messages h-100">
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>ID / ニックネーム</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer, i) => (
                  <tr key={`customer-${customer.id}`}>
                    <td style={{ whiteSpace: 'nowrap', width: '100px' }}>
                      <NavLink to={`/customers/${customer.id}`} className="nav-link" activeClassName="active">
                        {customer.id} / {customer.nickname}
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <WindmillSpinnerOverlay loading={loading} message="検索中" />
    </div>
  );
}

export default SearchCustomers;
