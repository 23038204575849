/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import ShopsContext from './ShopsContext';

Modal.setAppElement('#root');

function ShopPrivate(props: { match }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { shops } = useContext(ShopsContext);
  const [posting, setPosting] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [shop, setShop] = useState<any>();

  const shopId = props.match.params.id;
  const shopName = shops[shopId].data()!.short_name;

  useEffect(() => {
    const unregisterShopObserver = firebase
      .firestore()
      .collection('shops_private')
      .doc(shopId)
      .onSnapshot((doc) => {
        console.log('onSnapshot ShopPrivate');
        if (doc.exists) {
          setShop(doc.data());
        } else {
          setShop({});
        }
      });

    return () => {
      unregisterShopObserver();
    };
  }, [props.match.params.id, shopId]);

  const emptyDiv = () => <div />;
  const onSubmit = (data) => {
    const options = {
      title: '店舗情報の更新を行います',
      message: '変更は直ちに反映されます',
      buttons: [
        {
          label: '更新する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  const submit = (argData) => {
    setPosting(true);
    const data = { ...argData };

    const shopRef = firebase.firestore().collection('shops_private').doc(shopId);
    shopRef
      .set(data, { merge: true })
      .then(() => {
        setMessageSuccess('更新完了');
        setPosting(false);
      })
      .catch((error) => {
        setMessageError(`更新に失敗しました${error}`);
        setPosting(false);
      });
  };

  return (
    <div className="container-fluid h-100">
      <NavLink to={`/admin/shops/${shopId}`} className="nav-link" activeClassName="active">
        {shopName}
      </NavLink>

      {shop ? (
        <div>
          {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

          {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

          {shop ? (
            <div>
              <form id="shop-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label" htmlFor="inputCustomerAdminEmail">
                    お客様の管理者メールアドレス
                  </label>
                  <div className="col-sm-8">
                    <input
                      id="inputCustomerAdminEmail"
                      defaultValue={shop.customer_admin_email}
                      type="text"
                      size={50}
                      {...register('customer_admin_email', {
                        pattern: {
                          value: /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,} *,? *)+$/i,
                          message: '正しいメールアドレスを入力してください',
                        },
                      })}
                    />
                    <small className="form-text text-muted">
                      法人デリバリーではスロットの締め切り時間にこのアドレスに通知メールが行く。,で区切って複数のアドレスを指定可能(to:にそのまま入る)
                    </small>
                    <small className="text-danger">
                      {errors.customer_admin_email && (errors.customer_admin_email.message as string)}
                    </small>
                  </div>
                </div>

                <input type="submit" className="btn btn-primary" value="更新" />
              </form>
            </div>
          ) : (
            <div className="row">
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          )}
          <WindmillSpinnerOverlay loading={posting} message="店舗情報更新中" />
        </div>
      ) : (
        'loading...'
      )}
    </div>
  );
}

export default ShopPrivate;
