import React, { useEffect, useState, useContext } from 'react';
import ShopsContext from './ShopsContext';

function ClosedAlert(props: {}) {
  const [closed, setClosed] = useState(false);
  const [time, setTime] = useState<Date>();
  const { currentShop } = useContext(ShopsContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const data = currentShop?.data();

    setClosed(
      data &&
        (!!data.today_soldout ||
          !data.today_opening_time ||
          (data.today_opening_time && data.today_last_order_time && data.today_last_order_time.toDate() < new Date())),
    );
  }, [currentShop, time]);

  return (
    <>
      {closed && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-10">
              <div className="alert alert-danger text-center mb-1" role="alert">
                閉店 (注文受け付け停止中)
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ClosedAlert;
