import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import NotificationBadge from './NotificationBadge';
import withTMCAuth from './withTMCAuth';
import RoleContext from './RoleContext';
import firebase from './firebase';
import { StoreContainer } from './store';

function InCooking(props: { shopId: string }) {
  const [inCooking, setInCooking] = useState(0);
  useEffect(() => {
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('shops')
      .doc(props.shopId)
      .collection('orders_for_kitchen')
      .where('status', '==', 'cooking')
      .onSnapshot((snap) => {
        console.log('onSnapshot InCooking');
        setInCooking(snap.size);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [props.shopId]);

  return <>{inCooking > 0 && <span className="badge badge-warning">{inCooking}</span>}</>;
}

function Warnings(props: { shopId: string }) {
  const [warnings, setWarnings] = useState(0);
  useEffect(() => {
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('shops')
      .doc(props.shopId)
      .collection('kitchen_warnings')
      .where('enabled', '==', true)
      .onSnapshot((snap) => {
        console.log('onSnapshot Warnings');
        setWarnings(snap.size);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [props.shopId]);

  return <>{warnings > 0 && <span className="badge badge-warning">{warnings}</span>}</>;
}

function DeliveryProviderWarnings(props: { shopId: string }) {
  const [deliveryProviderWarnings, setDeliveryProviderWarnings] = useState(0);
  useEffect(() => {
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('shops')
      .doc(props.shopId)
      .collection('delivery_provider_warnings')
      .where('confirmed', '==', false)
      .onSnapshot((snap) => {
        setDeliveryProviderWarnings(snap.size);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [props.shopId]);

  return <>{deliveryProviderWarnings > 0 && <span className="badge badge-warning">{deliveryProviderWarnings}</span>}</>;
}

function CrewNavigator(props) {
  const storeContainer = StoreContainer.useContainer();

  const role = useContext(RoleContext);

  return (
    <>
      <ul className="navbar-nav mr-auto">
        {storeContainer.shopId && (
          <>
            <li className="nav-item">
              <NavLink to="/navigatororders" className="nav-link" activeClassName="active">
                接客
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/waitingorders" className="nav-link" activeClassName="active">
                調理待ち
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/cookingorders" className="nav-link" activeClassName="active">
                調理中
                <InCooking shopId={storeContainer.shopId} />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/cookedorders" className="nav-link" activeClassName="active">
                調理済
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/topping" className="nav-link" activeClassName="active">
                在庫管理
                <Warnings shopId={storeContainer.shopId} />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/slots" className="nav-link" activeClassName="active">
                スロット
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/self_checkout_preference" className="nav-link" activeClassName="active">
                セルフレジ
              </NavLink>
            </li>
            <li className="nav-item">
              <span>
                <NavLink
                  to="/notifications"
                  className="nav-link"
                  style={{ display: 'inline-block', paddingRight: '0px' }}
                  activeClassName="active"
                >
                  通知
                </NavLink>
                <NotificationBadge />
              </span>
            </li>
            <li className="nav-item">
              <NavLink to="/canceledorders" className="nav-link" activeClassName="active">
                キャンセル
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/delivery_provider" className="nav-link" activeClassName="active">
                配達
                <DeliveryProviderWarnings shopId={storeContainer.shopId} />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/searchOrders" className="nav-link" activeClassName="active">
                検索
              </NavLink>
            </li>
          </>
        )}

        {(role.isSuperAdmin() || role.isCs()) && (
          <li className="nav-item">
            <NavLink to="/admin/coupons" className="nav-link" activeClassName="active">
              管理
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
}

export default withTMCAuth(CrewNavigator);
