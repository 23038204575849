/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function Newsletter(props: { match; history }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [updating, setUpdating] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [newsletter, setNewsletter] = useState<firebase.firestore.DocumentData>();

  useEffect(() => {
    if (props.match.params.id) {
      const unregisterNewsletterObserver = firebase
        .firestore()
        .collection('newsletters')
        .doc(props.match.params.id)
        .onSnapshot((doc) => {
          console.log('onSnapshot Newsletter');
          const data = doc.data();
          setNewsletter(data);
        });

      return () => {
        unregisterNewsletterObserver();
      };
    }
    setNewsletter({});
    return () => {};
  }, [props.match.params.id]);

  const submit = (argData) => {
    setUpdating(true);

    const data = { ...argData };

    let ref;
    if (props.match.params.id) {
      ref = firebase.firestore().collection('newsletters').doc(props.match.params.id);
    } else {
      ref = firebase.firestore().collection('newsletters').doc();
    }

    data.nth = parseInt(data.nth, 10);
    data.days_after_previous_newsletter = parseInt(data.days_after_previous_newsletter, 10);

    ref
      .set(data, { merge: true })
      .then(() => {
        setMessageSuccess('更新完了');
        setUpdating(false);
        props.history.push('/admin/newsletters');
      })
      .catch((error) => {
        setMessageError(`更新に失敗しました${error}`);
        setUpdating(false);
      });
  };

  return (
    <div className="container-fluid h-100">
      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

      {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

      {newsletter ? (
        <div>
          <form id="newsletter-form" onSubmit={handleSubmit(submit)}>
            <input
              defaultValue={newsletter.type}
              type="hidden"
              value="from_chef"
              {...register('type', { required: true })}
            />
            <input
              defaultValue={newsletter.type}
              type="hidden"
              value={6}
              {...register('days_after_previous_newsletter', { required: true })}
            />
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">回数</label>
              <div className="col-sm-8">
                <input
                  defaultValue={newsletter.nth}
                  type="number"
                  min="1"
                  max="999"
                  {...register('nth', { required: true })}
                />
                <small className="text-danger">{errors.nth && '必須です'}</small>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">タイトル</label>
              <div className="col-sm-8">
                <input
                  defaultValue={newsletter.subject}
                  type="text"
                  size={50}
                  {...register('subject', { required: true })}
                />
                <small className="text-danger">{errors.subject && '必須です'}</small>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">本文</label>
              <div className="col-sm-8">
                <textarea
                  defaultValue={newsletter.body}
                  rows={20}
                  cols={80}
                  {...register('body', { required: true })}
                />
                <small className="text-danger">{errors.body && '必須です'}</small>
              </div>
            </div>
            <input type="submit" className="btn btn-primary" value={props.match.params.id ? '更新' : '作成'} />
          </form>
        </div>
      ) : (
        <div className="row">
          <div className="col text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <WindmillSpinnerOverlay loading={updating} message="ニュースレター情報更新中" />
    </div>
  );
}

export default Newsletter;
