import React from 'react';
import { useHistory } from 'react-router-dom';
import { StoreContainer } from './store';
import useKitchenWarnings from './hooks/useKitchenWarnings';

function KitchenWarnings(props: {}) {
  const history = useHistory();

  const storeContainer = StoreContainer.useContainer();
  const warnings = useKitchenWarnings(storeContainer.shopId);
  const stockWarnings = warnings.filter(
    (warning) => warning.data().type === 'stock' && warning.data().notify_stock_in_cooking,
  );

  return (
    <>
      {warnings && stockWarnings.length > 0 && (
        <div className="cooking-order-card-alert">
          <div className="alert alert-danger shadow mx-4" role="alert">
            <h4 className="alert-heading mb-3">在庫を確認してください 👀</h4>
            <div className="cooking-order-card-alert-messages">
              <div className="row">
                <div className="col" style={{ opacity: stockWarnings.length > 0 ? 1 : 0.2 }}>
                  <div className="border-bottom border-danger py-1">(まもなく)売り切れ</div>
                  <div className="p-1">
                    {stockWarnings.length > 0 ? (
                      <table style={{ width: '100%' }}>
                        <tbody>
                          {stockWarnings.map((warning) => (
                            <tr key={warning.id}>
                              <th>{warning.data().kitchen_name} </th>
                              <td className="text-right">
                                残り
                                <big className="text-danger">
                                  <strong>{warning.data().left}</strong>
                                </big>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <span>なし</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <button type="button" className="btn btn-lg btn-danger" onClick={() => history.push('/topping')}>
              在庫を確認する
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default KitchenWarnings;
