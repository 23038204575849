import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const useCustomertInfo = (customerId: string) => {
  const [customerInfo, setCustomerInfo] = useState<null | { email: string; phoneNumber: string; churnStatus: string }>(
    null,
  );
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const apiEndPoint = `${process.env.REACT_APP_api_server}/customers/${customerId}`;

      const auth = firebase.auth();
      auth.currentUser!.getIdToken().then((token) => {
        fetch(apiEndPoint, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async (response) => {
            const responseJson = await response.json();
            if (response.status === 200) {
              setCustomerInfo({
                email: responseJson.customer.email || null,
                phoneNumber: responseJson.customer.phone_number || null,
                churnStatus: responseJson.customer.churn_status || null,
              });
            }
          })
          .catch((error) => {});
      });
    };

    fetchData();
  }, [customerId, counter]);

  const forceUpdate = () => {
    setCounter((prevCount) => prevCount + 1);
  };

  return { customerInfo, forceUpdate };
};

export default useCustomertInfo;
