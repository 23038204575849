import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Route, Redirect, Switch, useLocation, useHistory } from 'react-router-dom';
import NavigatorOrders from './NavigatorOrders';
import Orders from './Orders';
import WaitingOrders from './WaitingOrders';
import CookingOrders from './CookingOrders';
import CookedOrders from './CookedOrders';
import CanceledOrders from './CanceledOrders';
import SearchOrders from './SearchOrders';
import Coupons from './Coupons';
import IssueCoupon from './IssueCoupon';
import Coupon from './Coupon';
import Topping from './Topping';
import ShopBatchUpdate from './ShopBatchUpdate';
import ShopStocks from './ShopStocks';
import Customer from './Customer';
import Messages from './Messages';
import EditScheduledMessage from './EditScheduledMessage';
import Newsletters from './Newsletters';
import Newsletter from './Newsletter';
import CustomerMessage from './CustomerMessage';
import ScheduledMessages from './ScheduledMessages';
import Shop from './Shop';
import ShopPrivate from './ShopPrivate';
import SearchCustomers from './SearchCustomers';
import Shops from './Shops';
import Notifications from './Notifications';
import DeliveryProvider from './DeliveryProvider';
import firebase from './firebase';
import SelectCrew from './SelectCrew';
import SelectShop from './SelectShop';
import { StoreContainer } from './store';
import OrdersOverview from './OrdersOverview';
import Picture from './Picture';
import CrewNavigator from './CrewNavigator';
import AdminNavigator from './AdminNavigator';
import withTMCAuth from './withTMCAuth';
import ShopSlotPatternSummary from './ShopSlotPatternSummary';
import AssignSlotPatterns from './AssignSlotPatterns';
import SlotSets from './SlotSets';
import ShopKitchenLayouts from './ShopKitchenLayouts';
import ShopKitchenLayoutForm from './ShopKitchenLayoutForm';
import ClosedAlert from './ClosedAlert';
import ShopInfoBatchUpdate from './ShopInfoBatchUpdate';
import ShopsContext from './ShopsContext';
import AllSlots from './AllSlots';
import SelfCheckoutPreference from './SelfCheckoutPreference';
import NotificationToast from './NotificationToast';

function Navigator(props: { signOut; error }) {
  const storeContainer = StoreContainer.useContainer();
  const { shops } = useContext(ShopsContext);

  const history = useHistory();

  const [currentCrew, setCurrentCrew] = useState<any>();

  const location = useLocation();

  const { signOut } = props;
  const { error } = props;

  const resetCrew = useCallback(() => {
    storeContainer.setCrewId(null);
  }, [storeContainer]);

  useEffect(() => {
    if (storeContainer.crewId) {
      firebase
        .firestore()
        .collection('crews')
        .doc(storeContainer.crewId)
        .get()
        .then((snap) => {
          console.log('get crew');
          if (snap.exists) {
            setCurrentCrew(snap.data());
          } else {
            storeContainer.setCrewId(null);
            setCurrentCrew(null);
          }
        });
    } else {
      setCurrentCrew(null);
    }
  }, [storeContainer.crewId, storeContainer]);

  const systemSignOut = () => {
    signOut();
    history.push('/');
  };

  const shopsLoaded = Object.keys(shops).length > 0;
  const isAdmin = location.pathname.match(/^\/admin\//);

  return (
    <>
      <div className="h-100">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-2">
          <img src="/images/logo.png" width="30" height="30" className="d-inline-block align-top rounded" alt="" />
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigator"
            aria-controls="navigator"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div id="navigator" className="collapse navbar-collapse">
            {isAdmin ? <AdminNavigator /> : <CrewNavigator />}

            <form className="form-inline">
              <div id="select_shop" className="form-group mr-1">
                <SelectShop />
              </div>
              <div className="form-group">
                <div className=" text-light bg-dark mr-1">
                  {currentCrew ? (
                    <>
                      <small>{currentCrew.display_name}</small>&nbsp;
                      <Picture width={24} file={currentCrew.picture} alt="crew" />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {storeContainer.crewId && (
                  <button type="button" className="btn btn-sm btn-outline-light" onClick={resetCrew}>
                    <i className="fas fa-sign-out-alt" style={{ fontSize: '12px' }} />
                  </button>
                )}
              </div>
            </form>
            <OrdersOverview />
          </div>
        </nav>

        {shopsLoaded && storeContainer.shopId && <ClosedAlert />}

        <div className="container-fluid">
          {!isAdmin && <NotificationToast />}
          {error ? (
            <div className="row">
              <div className="col">
                <div className="alert alert-danger" role="alert">
                  {error.message}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {shopsLoaded && storeContainer.shopId && storeContainer.crewId ? (
          <>
            <Switch>
              <Route path="/navigatororders" component={NavigatorOrders} />
              <Route path="/waitingorders" component={WaitingOrders} />
              <Route path="/cookingorders" component={CookingOrders} />
              <Route path="/cookedorders" component={CookedOrders} />
              <Route path="/canceledorders" component={CanceledOrders} />
              <Route path="/searchOrders" component={SearchOrders} />
              <Route path="/slots" component={SlotSets} />
              <Route path="/admin/issue_coupon" component={IssueCoupon} />
              <Route path="/topping" component={Topping} />
              <Route path="/orders/:id" component={Orders} />
              <Route path="/customers/:id" component={Customer} />
              <Route path="/customer_message/:id" component={CustomerMessage} />
              <Route path="/notifications" component={Notifications} />
              <Route path="/delivery_provider" component={DeliveryProvider} />
              <Route path="/self_checkout_preference" component={SelfCheckoutPreference} />
              <Route path="/admin/coupons/:id" component={Coupon} />
              <Route path="/admin/coupons" component={Coupons} />
              <Route path="/admin/messages/:id" component={EditScheduledMessage} />
              <Route path="/admin/messages" component={Messages} />
              <Route path="/admin/newsletters" component={Newsletters} />
              <Route path="/admin/newsletter/:id" component={Newsletter} />
              <Route path="/admin/newsletter" component={Newsletter} />
              <Route path="/admin/scheduled_messages" component={ScheduledMessages} />
              <Route path="/admin/shops/:id" component={Shop} />
              <Route path="/admin/shops" component={Shops} />
              <Route path="/admin/shops_private/:id" component={ShopPrivate} />
              <Route path="/admin/search_customers" component={SearchCustomers} />
              <Route path="/admin/shop_batch_update" component={ShopBatchUpdate} />
              <Route path="/admin/shop_stocks" component={ShopStocks} />
              <Route path="/admin/shop_info_batch_update" component={ShopInfoBatchUpdate} />
              <Route path="/admin/shop_slot_patterns/:id" component={ShopSlotPatternSummary} />
              <Route path="/admin/assign_slot_patterns/:id" component={AssignSlotPatterns} />
              <Route path="/admin/edit_shop_kitchen_layout/:id" component={ShopKitchenLayoutForm} />
              <Route path="/admin/new_shop_kitchen_layout" component={ShopKitchenLayoutForm} />
              <Route path="/admin/shop_kitchen_layouts" component={ShopKitchenLayouts} />
              <Route path="/admin/all_slots" component={AllSlots} />
              <Redirect from="/" to="/cookingorders" />
            </Switch>
          </>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-5">
                {storeContainer.crewId ? (
                  '店舗を選択してください'
                ) : (
                  <>
                    <SelectCrew />
                  </>
                )}
              </div>
              <div className="col">
                <button type="button" className="btn btn-danger float-right" onClick={systemSignOut}>
                  システムログアウト
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default withTMCAuth(Navigator);
