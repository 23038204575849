import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import firebase from './firebase';

async function submitPassRefund(passOrderId: string, customerId: string) {
  const apiEndPoint = `${process.env.REACT_APP_api_server}/passes/${passOrderId}/refund`;
  const auth = firebase.auth();

  await auth.currentUser!.getIdToken().then(async (token) => {
    await fetch(apiEndPoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ customer_id: customerId }),
    });
  });
}

const emptyDiv = () => <div />;

async function confirmAndRunPassRefund(passOrderId: string, customerId: string, cb: Function) {
  const options = {
    title: '返金/即時解約',
    message: '返金を行い、発行されているクーポンは即時使用不可になります。',
    buttons: [
      {
        label: '実行する',
        onClick: async () => {
          cb(true);
          await submitPassRefund(passOrderId, customerId);
          cb(false);
        },
      },
      {
        label: '実行しない',
        onClick: () => {},
      },
    ],
    childrenElement: () => emptyDiv(),
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
  };
  confirmAlert(options);
}

export default confirmAndRunPassRefund;
