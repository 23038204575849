import withFirebaseAuth from 'react-auth-firebase';
import firebase from './firebase';

function withTMCAuth(component: Function) {
  const authConfig = {
    email: {
      verifyOnSignup: false,
      saveUserInDatabase: true,
    },
    google: {
      // redirect: true, // Opens a pop up by default
      returnAccessToken: true,
      saveUserInDatabase: true,
    },
  };
  return withFirebaseAuth(component, firebase, authConfig);
}

export default withTMCAuth;
