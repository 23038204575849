import { useState, useEffect } from 'react';
import dayjs from '../dayjs';
import firebase from '../firebase';

const useStampCard = (): firebase.firestore.DocumentSnapshot | null => {
  const [stampCardSnapshot, setStampCardSnapshot] = useState<firebase.firestore.DocumentSnapshot | null>(null);

  useEffect(() => {
    const nowTimestamp = firebase.firestore.Timestamp.now();

    firebase
      .firestore()
      .collection('stamp_card_plans')
      .where('end_period', '>=', nowTimestamp)
      .orderBy('end_period', 'asc')
      .limit(1)
      .get()
      .then((stampCardSnapshots) => {
        console.log('get useStampCard');
        if (stampCardSnapshots.size === 0) {
          return;
        }

        const doc = stampCardSnapshots.docs[0];

        // スタンプカード期間外ならnull
        if (!isValidStampCardPeriod(doc)) {
          return;
        }

        setStampCardSnapshot(doc);
      });
  }, []);

  return stampCardSnapshot;
};

export default useStampCard;

/// スタンプカードが期限内かどうか
const isValidStampCardPeriod = (stampCardSnapshot: firebase.firestore.DocumentSnapshot) => {
  const stampCard = stampCardSnapshot.data()!;

  const now = dayjs(firebase.firestore.Timestamp.now().toDate()).tz('Asia/Tokyo');
  const startPeriod = dayjs(stampCard.start_period.toDate()).tz('Asia/Tokyo');
  const endPeriod = dayjs(stampCard.end_period.toDate()).tz('Asia/Tokyo');

  return !startPeriod.isAfter(now) && !endPeriod.isBefore(now);
};
