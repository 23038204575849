import React from 'react';

function PayLaterUnPaidBadge() {
  return (
    <div
      className="alert alert-danger"
      style={{
        display: 'inline-block',
        margin: 0,
        padding: '2px 4px',
        borderRadius: '4px',
      }}
    >
      <span
        style={{
          display: 'inline-block',
          position: 'relative',
        }}
      >
        <i
          className="fas fa-yen-sign text-danger"
          aria-hidden
          style={{
            fontSize: '20px',
            textShadow: '0 0 1px #000',
          }}
        />
      </span>
      &nbsp;
      <small>
        <strong>店頭未払い</strong>
      </small>
    </div>
  );
}

export default PayLaterUnPaidBadge;
