import React, { useContext, useEffect, useState } from 'react';
import firebase from './firebase';
import LineSwitcher from './LineSwitcher';
import LineOrders from './LineOrders';
import TodaySuccessRate from './TodaySuccessRate';
import { StoreContainer } from './store';
import useKitchenLayout from './hooks/useKitchenLayout';
import useKitchenLayouts from './hooks/useKitchenLayouts';
import ShopsContext from './ShopsContext';
import PickupNowOrders from './PickupNowOrders';

function CookingOrders(props: {}) {
  const { shops } = useContext(ShopsContext);
  const storeContainer = StoreContainer.useContainer();

  const shopData = shops[storeContainer.shopId].data()!;

  const batchDelivery = shopData.batch_delivery;

  const [selectedKitchenLayoutSnapshot, setSelectedKitchenLayoutSnapshot] =
    useState<firebase.firestore.DocumentData | null>(null);

  const kitchenLayoutSnapshot = useKitchenLayout();
  const kitchenLayoutSnapshots = useKitchenLayouts();

  useEffect(() => {
    const cookingKitchenLayout = kitchenLayoutSnapshots?.find(
      (snapshot) => snapshot.id === storeContainer.cookingKitchenLayoutId,
    );

    setSelectedKitchenLayoutSnapshot(cookingKitchenLayout ?? kitchenLayoutSnapshot);
  }, [kitchenLayoutSnapshot, kitchenLayoutSnapshots, storeContainer.cookingKitchenLayoutId]);

  const handleChangeKitchenLayout = (selected) => {
    const cookingKitchenLayout = kitchenLayoutSnapshots?.find((snapshot) => snapshot.id === selected.target.value)!;
    setSelectedKitchenLayoutSnapshot(cookingKitchenLayout);
    storeContainer.setCookingKitchenLayoutId(cookingKitchenLayout.id);
  };

  const [enabledPickupNowLine, setEnabledPickupNowLine] = useState(!!shopData.enabled_pickup_now_line);

  const handleChangeEnabledPickupNowLine = async (enabled: boolean) => {
    await firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .update({ enabled_pickup_now_line: enabled });
    setEnabledPickupNowLine(enabled);
    if (!enabled) {
      storeContainer.setLine(-1);
      storeContainer.setPickupNowLine(false);
    }
  };

  const [kitchenLayoutReverse, setSelectedKitchenLayoutReverse] = useState(false);

  useEffect(() => {
    const { cookingKitchenLayoutReverse } = storeContainer;
    setSelectedKitchenLayoutReverse(cookingKitchenLayoutReverse);
  }, [storeContainer]);

  const handleChangeKitchenLayoutReverse = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setSelectedKitchenLayoutReverse(checked);
    storeContainer.setCookingKitchenLayoutReverse(checked);
  };

  return kitchenLayoutSnapshot ? (
    <div id="cooking-orders" className="container-fluid">
      <div className="row">
        <div className="col-auto">
          <LineSwitcher />
        </div>
        <div className="col-auto">
          <div className="form-inline">
            <select
              id="select_kitchen_layout"
              value={selectedKitchenLayoutSnapshot?.id}
              className="form-control form-control-sm mr-2"
              onChange={handleChangeKitchenLayout}
              style={{
                maxWidth: '10rem',
                backgroundColor:
                  kitchenLayoutSnapshot?.id !== selectedKitchenLayoutSnapshot?.id ? '#CCE5FF' : undefined,
              }}
            >
              {kitchenLayoutSnapshots?.map((snapshot) => (
                <option key={snapshot.id} value={snapshot.id}>
                  {snapshot.data().name}
                  {kitchenLayoutSnapshot?.id === snapshot.id}
                  {kitchenLayoutSnapshot?.id === snapshot.id && ' (店舗設定)'}
                </option>
              ))}
            </select>
            <input
              type="checkbox"
              id="kitchen_layout_reverse"
              name="kitchen_layout_reverse"
              className="form-check-input"
              checked={kitchenLayoutReverse}
              onChange={handleChangeKitchenLayoutReverse}
            />
            <label className="form-check-label" htmlFor="kitchen_layout_reverse">
              反転
            </label>
            {shopData.sales_channels.includes('eatin') && (
              <>
                &nbsp;
                <input
                  type="checkbox"
                  id="enabled_pickup_now_line"
                  name="kitchen_layout_reverse"
                  className="form-check-input"
                  defaultChecked={enabledPickupNowLine}
                  onChange={(e) => {
                    handleChangeEnabledPickupNowLine(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="enabled_pickup_now_line">
                  即時注文
                </label>
              </>
            )}
          </div>
        </div>
        {!batchDelivery && (
          <div className="col">
            <TodaySuccessRate />
          </div>
        )}
      </div>
      {storeContainer.pickupNowLine ? (
        <PickupNowOrders kitchenLayoutSnapshot={selectedKitchenLayoutSnapshot} />
      ) : (
        <LineOrders line={storeContainer.line} kitchenLayoutSnapshot={selectedKitchenLayoutSnapshot} />
      )}
    </div>
  ) : (
    <></>
  );
}

export default CookingOrders;
