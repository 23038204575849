import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useSalesPresets = (): Array<firebase.firestore.DocumentData> | null => {
  const [useSalesPresetsSnapshot, setSalesPresetsSnapshot] = useState<Array<firebase.firestore.DocumentData> | null>(
    null,
  );

  useEffect(() => {
    const query = firebase.firestore().collection('sales_presets').where('archived', '==', false).orderBy('order');

    query.get().then((snapshot) => {
      setSalesPresetsSnapshot(snapshot.docs);
    });
  }, []);

  return useSalesPresetsSnapshot;
};

export default useSalesPresets;
