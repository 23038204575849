import React, { useState, useEffect } from 'react';

function InputQuantityWithStepper(props: { value; onChange }) {
  const [quantity, setQuantity] = useState(0);
  const maxQuantity = 20;

  useEffect(() => {
    setQuantity(props.value || 0);
    return () => {};
  }, [props.value]);

  const increment = (e) => {
    e.preventDefault();
    if (quantity < maxQuantity) {
      updated(quantity + 1);
    }
  };

  const decrement = (e) => {
    e.preventDefault();
    if (quantity > 0) {
      updated(quantity - 1);
    }
  };

  const changed = (e) => {
    e.preventDefault();
    updated(parseInt(e.target.value, 10));
  };

  const updated = (value) => {
    setQuantity(value);
    props.onChange(value);
  };

  return (
    <>
      <input type="number" min="0" max={maxQuantity} value={quantity} onChange={changed} />
      &nbsp;&nbsp;&nbsp;
      <button type="button" className="btn btn-sm btn-light" onClick={increment}>
        +1
      </button>
      &nbsp;&nbsp;&nbsp;
      <button type="button" className="btn btn-sm btn-light" onClick={decrement}>
        -1
      </button>
      &nbsp;&nbsp;
    </>
  );
}

export default InputQuantityWithStepper;
