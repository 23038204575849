import React, { useEffect, useState, useContext } from 'react';
import firebase from './firebase';

function Pass(props: { passId: string }) {
  const [pass, setPass] = useState<any>();

  useEffect(() => {
    const unregisterShopObserver = firebase
      .firestore()
      .collection('passes')
      .doc(props.passId)
      .onSnapshot((doc) => {
        console.log('onSnapshot Pass');
        setPass(doc.data());
      });

    return () => {
      unregisterShopObserver();
    };
  }, [props.passId]);

  return <>{pass ? pass.name : ''}</>;
}

export default Pass;
