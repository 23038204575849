import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import useElementItems from './hooks/useElementItems';

function ShopKitchenLayoutElementModal(props: {
  modalIsOpen: boolean;
  onSubmit: (element) => void;
  onClickCancel: () => void;
}) {
  const elementItems = useElementItems();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      minWidth: '400px',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const itemId = Array.from(e.target.itemId.selectedOptions, (option: any) => option.value);
    const itemPrefixValue = e.target.itemPrefix.value as string;
    const itemPrefix = itemPrefixValue.length > 0 ? itemPrefixValue.split(',') : null;
    const alwaysEmphasizeCount = e.target.alwaysEmphasizeCount.checked;

    if (itemId.length === 0) return;

    props.onSubmit({ itemId, itemPrefix, alwaysEmphasizeCount });
  };
  const afterOpenModal = () => {};

  return (
    <Modal isOpen={props.modalIsOpen} onAfterOpen={afterOpenModal} style={customStyles} contentLabel="Example Modal">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>エレメント</label>
          <select multiple className="form-control" id="itemId" name="itemId">
            {elementItems?.map((doc) => (
              <option value={doc.id}>{doc.data()!.kitchen_name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>プレフィックス</label>
          <input type="text" id="itemPrefix" name="itemPrefix" className="form-control" />
          <small className="form-text text-muted">半角カンマ「,」区切りで入力してください</small>
        </div>
        <div className="form-group">
          <div className="form-check">
            <input type="checkbox" id="alwaysEmphasizeCount" name="alwaysEmphasizeCount" className="form-check-input" />
            <label className="form-check-label" htmlFor="alwaysEmphasizeCount">
              個数を常に強調表示する
            </label>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          追加する
        </button>
        &nbsp;
        <button type="button" className="btn btn-secondary" onClick={props.onClickCancel}>
          キャンセル
        </button>
      </form>
    </Modal>
  );
}

export default ShopKitchenLayoutElementModal;
