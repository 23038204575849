import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import dayjs from './dayjs';

import 'firebase/compat/auth';
import { StoreContainer } from './store';

function Notifications() {
  const storeContainer = StoreContainer.useContainer();
  const [notifications, setNotifications] = useState<any>();

  useEffect(() => {
    const unregisterNotificationsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('notifications')
      .where('created_at', '>=', dayjs().tz('Asia/Tokyo').startOf('day').toDate())
      .orderBy('created_at', 'desc')
      .onSnapshot((snap) => {
        console.log('onSnapshot Notifications');
        const records = {};
        for (const docSnapshot of snap.docs) {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        }

        setNotifications(records);
      });

    return () => {
      unregisterNotificationsObserver();
    };
  }, [storeContainer.shopId]);

  const confirm = (notificationId) => {
    firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('notifications')
      .doc(notificationId)
      .update({
        confirmed: true,
        confirmed_at: new Date(),
        crew_id: storeContainer.crewId,
      });
  };

  return (
    <div className="container-fluid h-100">
      <h3>通知センター</h3>

      <div id="notifications" className="container-fluid h-80">
        <div className="scrollable-notifications h-100">
          {notifications === undefined ? (
            <div className="row">
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {Object.keys(notifications).length === 0 ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-light text-center" role="alert">
                      ありません
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <table className="table w-auto">
                    <tbody>
                      <tr>
                        <th> </th>
                        <th>通知時刻</th>
                        <th>通知</th>
                      </tr>
                      {Object.keys(notifications).map((notificationId) => (
                        <tr key={`notification-${notificationId}`}>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {notifications[notificationId].confirmed ? (
                              '確認済'
                            ) : (
                              <button type="button" className="btn btn-primary" onClick={() => confirm(notificationId)}>
                                確認
                              </button>
                            )}
                          </td>

                          <td style={{ whiteSpace: 'nowrap' }}>
                            {dayjs.tz(notifications[notificationId].created_at.toDate(), 'Asia/Tokyo').fromNow()}
                          </td>

                          <td>
                            <pre>{notifications[notificationId].message}</pre>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
