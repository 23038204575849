import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

function Picture(props: { file: any; width: number; alt: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const [pictureThumbUrl, setPictureThumbUrl] = useState<string>();
  const [pictureUrl, setPictureUrl] = useState<string>();

  useEffect(() => {
    if (props.file) {
      const fileName = `${props.file.path}/${props.file.name}`;
      const thumbFileName = `${props.file.path}/thumb@1080_${props.file.name}`;

      firebase
        .storage()
        .ref(fileName)
        .getDownloadURL()
        .then((url) => {
          setPictureThumbUrl(url);
        });

      firebase
        .storage()
        .ref(thumbFileName)
        .getDownloadURL()
        .then((url) => {
          setPictureUrl(url);
        });
    }
  }, [props.file]);

  return (
    <>
      {pictureUrl && pictureThumbUrl ? (
        <>
          <span role="presentation" onClick={() => setIsOpen(true)}>
            <img src={pictureThumbUrl} width={props.width} alt={props.alt} />
          </span>
          {isOpen && <Lightbox mainSrc={pictureThumbUrl!} onCloseRequest={() => setIsOpen(false)} />}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Picture;
