/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { NavLink } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function EditScheduledMessage(props: { match }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const [waitingMessage, setWaitingMessage] = useState<string>();

  const [messageSuccess, setMessageSuccess] = useState('');

  const [scheduledMessage, setScheduledMessage] = useState<any>();
  const [shops, setShops] = useState<any>();

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .orderBy('order')
      .onSnapshot((snap) => {
        console.log('onSnapshot Shops');
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });

        setShops(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('scheduled_messages')
      .doc(props.match.params.id)
      .onSnapshot((doc) => {
        console.log('onSnapshot EditScheduledMessage');
        setScheduledMessage(doc.data()!);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [props.match.params.id]);

  const emptyDiv = () => <div />;

  const onSubmit = (argData) => {
    const data = { ...argData };
    const options = {
      title: 'メッセージの更新を行います。',
      message: '',
      buttons: [
        {
          label: '更新する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const submit = (data) => {
    const doc = firebase.firestore().collection('scheduled_messages').doc(props.match.params.id);

    const { payload } = scheduledMessage;
    payload.message.subject = data.subject;
    payload.message.body = data.body;
    payload.message.team_bonus = !!data.team_bonus;

    setWaitingMessage('更新中');
    setLoading(true);

    doc
      .update({
        'payload.segment.shop_ids': data.shop_ids,
        'payload.message.subject': data.subject,
        'payload.message.body': data.body,
        'payload.message.team_bonus': !!data.team_bonus,
      })
      .then(() => {
        setMessageSuccess('更新完了');
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid h-100">
      <NavLink to="/admin/scheduled_messages/" className="nav-link" activeClassName="active">
        送信予約メッセージ
      </NavLink>

      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

      {scheduledMessage && shops && (
        <>
          <form id="message-form" onSubmit={handleSubmit(onSubmit)}>
            <h3>送信セグメント</h3>
            <div className="form-group row">
              <div className="col-sm-8">
                <div className="form-group row">
                  <label htmlFor="selectShop" className="col-sm-4 col-form-label">
                    店舗
                  </label>
                  <div className="col-sm-6">
                    <select
                      size={10}
                      multiple
                      defaultValue={scheduledMessage.payload.segment.shop_ids}
                      {...register('shop_ids', {})}
                    >
                      {Object.keys(shops).map((shopId) => (
                        <option key={shopId} value={shopId}>
                          {shops[shopId].short_name}
                        </option>
                      ))}
                      <option value="OTHERS">どの店舗にも属していない人</option>
                    </select>
                    <small className="form-text text-muted">
                      最後の注文がこの店舗。TMCで一度も注文したことない人は、最後にこの店舗を選択したユーザー{' '}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <h3>送信内容</h3>
            <div className="form-group row">
              <label htmlFor="inputSubject" className="col-sm-2 col-form-label">
                タイトル
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  id="inputSubject"
                  size={60}
                  defaultValue={scheduledMessage.payload.message.subject}
                  {...register('subject', { required: true })}
                />
              </div>
              <div className="col-sm-2">
                <small id="error_subject" className="text-danger">
                  {errors.subject && 'タイトルは必須です'}
                </small>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputBody" className="col-sm-2 col-form-label">
                チームボーナスメッセージを本文に挿入(該当時のみ)
              </label>
              <div className="col-sm-8">
                <input
                  type="checkbox"
                  {...register('team_bonus', {})}
                  defaultValue={scheduledMessage.payload.message.team_bonus}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputBody" className="col-sm-2 col-form-label">
                本文
              </label>
              <div className="col-sm-8">
                <textarea
                  id="inputBody"
                  rows={20}
                  cols={80}
                  defaultValue={scheduledMessage.payload.message.body}
                  {...register('body', { required: true })}
                />
              </div>
              <div className="col-sm-2">
                <small id="error_body" className="text-danger">
                  {errors.body && '本文は必須です'}
                </small>
              </div>
            </div>

            <input type="submit" className="btn btn-primary" value="更新" />
          </form>
          <WindmillSpinnerOverlay loading={loading} message={waitingMessage} />
        </>
      )}
    </div>
  );
}

export default EditScheduledMessage;
