import React from 'react';

function Stars(props: { num: number }) {
  const stars: Array<JSX.Element> = [];
  for (let i = 0; i < props.num; i += 1) {
    stars.push(<i key={i} className="fas fa-star" style={{ color: '#f2b01e' }} />);
  }
  return <> {stars}</>;
}

export default Stars;
