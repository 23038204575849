import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Modal from 'react-modal';
import Linkify from 'linkify-react';
import dayjs from './dayjs';
import { StoreContainer } from './store';

Modal.setAppElement('#root');

function NotificationToast() {
  const storeContainer = StoreContainer.useContainer();
  const [items, setItems] = useState<Array<{ id: string; message: string; createdAt: Date }>>([]);

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    const batch = firebase.firestore().batch();
    for (const item of items) {
      batch.update(
        firebase.firestore().collection('shops').doc(storeContainer.shopId).collection('notifications').doc(item.id),
        {
          confirmed: true,
          confirmed_at: new Date(),
          crew_id: storeContainer.crewId,
        },
      );
    }
    batch.commit();
    setItems([]);
  };

  useEffect(() => {
    let unregisterNotificationBadgeObserver;
    if (storeContainer.shopId) {
      unregisterNotificationBadgeObserver = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('notifications')
        .where('confirmed', '==', false)
        .where('created_at', '>=', dayjs().tz('Asia/Tokyo').startOf('day').toDate())
        .orderBy('created_at', 'asc')
        .onSnapshot((snap) => {
          console.log('onSnapshot NotificationToast');
          let newNotification = false;
          const newItems: Array<{ id: string; message: string; createdAt: Date }> = [];
          for (const doc of snap.docs.filter((d) => d.data().toast)) {
            newNotification = true;
            newItems.push({
              id: doc.id,
              message: doc.data().message,
              createdAt: doc.data().created_at.toDate(),
            });
          }

          setItems(newItems);
        });
    }

    return () => {
      if (unregisterNotificationBadgeObserver) {
        unregisterNotificationBadgeObserver();
      }
    };
  }, [storeContainer.shopId]);

  const confirmNotification = (notificationId: string) => {
    firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('notifications')
      .doc(notificationId)
      .update({
        confirmed: true,
        confirmed_at: new Date(),
        crew_id: storeContainer.crewId,
      });
  };

  return (
    <div style={{ position: 'fixed', right: '16px', zIndex: 999 }}>
      {items.map((item) => (
        <div className="toast show" style={{ backgroundColor: 'rgba(255, 193, 7, 0.8)' }}>
          <div className="toast-header">
            <strong className="mr-auto">🔈</strong>
            <small>{dayjs.tz(item.createdAt, 'Asia/Tokyo').fromNow()}</small>
            <button
              type="button"
              className="ml-2 mb-1 close"
              data-dismiss="toast"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
                confirmNotification(item.id);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="toast-body" style={{ whiteSpace: 'pre-wrap' }}>
            <Linkify options={{ target: '_blank' }}>{item.message}</Linkify>
          </div>
        </div>
      ))}
    </div>
  );
}

export default NotificationToast;
