import React, { useEffect, useState } from 'react';
import useElementItems from './hooks/useElementItems';
import firebase from './firebase';
import { StoreContainer } from './store';

function OrderElements(props: {
  item: any;
  priceIncluded: boolean;
  kitchenLayoutSnapshot: firebase.firestore.DocumentData | null;
  cooking: boolean;
  hideUntilCookingStart: boolean;
  onChangeChecked: (checked: boolean) => void;
}) {
  const storeContainer = StoreContainer.useContainer();

  const elementItems = useElementItems(false);

  const elementGroups: Array<{
    id: string;
    color: string;
    newLine?: boolean;
    spacer?: boolean;
    elements: Array<{
      itemId: Array<string>;
      itemPrefix?: Array<string>;
      orderedElement?: any;
      alwaysEmphasizeCount?: boolean;
      curryCup?: boolean;
    }>;
  }> =
    props.kitchenLayoutSnapshot?.data().groups.map((group, index) => ({
      id: `${props.kitchenLayoutSnapshot?.id}-${index}`,
      color: 'white',
      newLine: group.new_line,
      spacer: group.spacer,
      elements: group.elements.map((element) => ({
        itemId: element.item_id,
        itemPrefix: element.item_prefix,
        curryCup: false,
        alwaysEmphasizeCount: element.always_emphasize_count,
      })),
    })) ?? [];

  const unknownOrderedElements: Array<any> = [];

  props.item.components.forEach((orderedComponent) => {
    orderedComponent.elements.forEach((orderedElement) => {
      if (orderedElement.quantity * orderedElement.size > 0) {
        let foundElementGroupElement;
        for (const elementGroup of elementGroups) {
          foundElementGroupElement = elementGroup.elements.find((elementGroupElement) => {
            return elementGroupElement.itemId.includes(orderedElement.item_id);
          });
          if (foundElementGroupElement) {
            break;
          }
        }

        if (foundElementGroupElement) {
          foundElementGroupElement.curryCup = ['curry_sauce', 'keihan_dashi'].includes(orderedComponent.id);
          foundElementGroupElement.orderedElement = orderedElement;
        } else {
          unknownOrderedElements.push([orderedComponent, orderedElement]);
        }
      }
    });
  });

  unknownOrderedElements.forEach(([unknownComponent, unknownElement], index) => {
    elementGroups.push({
      id: `unknown-${index}`,
      color: 'red',
      elements: [
        {
          itemId: [unknownElement.item_id],
          curryCup: ['curry_sauce', 'keihan_dashi'].includes(unknownComponent.id),
          orderedElement: unknownElement,
        },
      ],
    });
  });

  const title = (itemId) => {
    const elementItem = elementItems!.find((doc) => doc.id === itemId);
    return elementItem ? elementItem.data()!.kitchen_name : '名称未設定';
  };

  const color = (orderedElement) => {
    if (orderedElement) {
      const elementItem = elementItems!.find((doc) => doc.id === orderedElement.item_id);
      return elementItem ? elementItem.data()!.kitchen_color : 'gray';
    }
    return 'gray';
  };

  // 確認(チェック)が必要な項目を抽出
  const checkRequiredItemIds = elementGroups.flatMap((elementGroup) =>
    elementGroup.elements
      .map((element) => {
        const { orderedElement } = element;
        if (!orderedElement) return null;

        const elementItem = elementItems?.find((doc) => doc.id === orderedElement.item_id);
        if (elementItem) {
          if (elementItem.data()!.kitchen_require_check) {
            return { id: orderedElement.item_id, message: '確認' };
          }

          if (element.curryCup || !elementItem.data()!.kitchen_require_multiple_check) {
            return null;
          }

          const multiple = orderedElement.quantity * orderedElement.size > 1;
          if (!multiple) return null;

          return { id: orderedElement.item_id, message: '複数' };
        }
        return null;
      })
      .filter((element) => !!element),
  );

  // blur(hide)が必要な項目を抽出
  const blurRequiredItemIds = elementGroups.flatMap((elementGroup) =>
    elementGroup.elements
      .map((element) => {
        const { orderedElement } = element;
        if (!orderedElement) return null;

        const elementItem = elementItems?.find((doc) => doc.id === orderedElement.item_id);
        if (elementItem) {
          if (elementItem.data()!.kitchen_require_blur) {
            return { id: orderedElement.item_id };
          }
        }
        return null;
      })
      .filter((element) => !!element),
  );

  // チェックした項目
  const [checkedItemIds, setCheckedItemIds] = useState<Set<string>>(new Set<string>());

  const handleChangeChecked = (itemId: string, checked: boolean) => {
    if (checked) {
      setCheckedItemIds((pre) => {
        pre.add(itemId);
        return new Set(pre);
      });
    } else {
      setCheckedItemIds((pre) => {
        pre.delete(itemId);
        return new Set(pre);
      });
    }
  };

  useEffect(() => {
    props.onChangeChecked(checkRequiredItemIds.length === checkedItemIds.size);
  }, [checkRequiredItemIds, checkedItemIds, props]);

  const buildElement = (elementGroup, element) => {
    const orderedElement =
      element.orderedElement &&
      (!props.hideUntilCookingStart ||
        !blurRequiredItemIds.some((item) => item?.id === element.orderedElement?.item_id))
        ? element.orderedElement
        : null;

    const prefix =
      orderedElement && element.itemPrefix ? element.itemPrefix[element.itemId.indexOf(orderedElement.item_id)] : '';

    const tooltipContent = element.curryCup ? 'カレーカップ' : '';

    const confirmation = orderedElement && checkRequiredItemIds.find((item) => item?.id === orderedElement.item_id);

    const emphasize = element.alwaysEmphasizeCount || confirmation;
    const checked = checkedItemIds.has(orderedElement?.item_id ?? '');
    const showCheckbox = props.cooking && confirmation;

    return (
      <div key={`${elementGroup.id}-${element.itemId.join(',')}`} className="cooking-order-card-element-wrapper">
        {showCheckbox && (
          <div className="cooking-order-card-element-checkmark-wrapper">
            <button
              type="button"
              className={`cooking-order-card-border-${color(orderedElement)} cooking-order-card-bg-${color(
                orderedElement,
              )} cooking-order-card-element-checkmark-circle ${checked ? 'checked' : 'unchecked'}`}
              onClick={() => handleChangeChecked(orderedElement?.item_id ?? '', !checked)}
            >
              <div className="cooking-order-card-element-checkmark">
                {checked ? <i className="fas fa-check" /> : <span>{confirmation?.message}</span>}
              </div>
            </button>
          </div>
        )}
        <button
          type="button"
          className={`cooking-order-card-element order-tooltip cooking-order-card-border-${color(
            orderedElement,
          )} cooking-order-card-bg-${color(orderedElement)} ${orderedElement ? '' : 'disabled'} ${
            showCheckbox ? (checked ? 'checked' : 'unchecked') : ''
          }`}
          data-order-tooltip-active="true"
          onClick={() => showCheckbox && handleChangeChecked(orderedElement?.item_id ?? '', !checked)}
        >
          <div className="cooking-order-card-element-name">{orderedElement ? title(orderedElement.item_id) : '-'}</div>
          <div className={`cooking-order-card-element-count ${emphasize ? 'emphasize' : ''}`}>
            {orderedElement ? `${prefix}${orderedElement.quantity * orderedElement.size}` : 0}
          </div>
          <div className="cooking-order-card-element-weight">
            {orderedElement
              ? `${(orderedElement.quantity * orderedElement.weight).toFixed()}${orderedElement.weight_unit}`
              : '-'}
          </div>
          {props.priceIncluded && (
            <div className="cooking-order-card-element-price">
              {orderedElement ? `¥${orderedElement.quantity * orderedElement.price_without_tax}` : '-'}
            </div>
          )}
          {tooltipContent && <div className="order-tooltip-content">{tooltipContent}</div>}
        </button>
      </div>
    );
  };

  return (
    <>
      <div
        className={`cooking-order-card-element-group-container d-flex ${storeContainer.cookingKitchenLayoutFlexRowClassName} flex-wrap justify-content-between`}
      >
        {elementItems &&
          elementGroups.map((elementGroup) => (
            <React.Fragment key={elementGroup.id}>
              {elementGroup.newLine && <div className="w-100" />}
              <div
                className={`cooking-order-card-element-group d-flex ${
                  storeContainer.cookingKitchenLayoutFlexRowClassName
                } ${elementGroup.spacer ? 'flex-grow-1' : ''} ${
                  !props.hideUntilCookingStart && `cooking-order-card-border-${elementGroup.color}`
                } ${!elementGroup.elements.some((element) => element.orderedElement?.quantity > 0) && 'disabled'}`}
              >
                {elementGroup.elements.map((element) => buildElement(elementGroup, element))}
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );
}

export default OrderElements;
