import React, { useState, useEffect } from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { v4 as uuidv4 } from 'uuid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import InputQuantityWithStepper from './InputQuantityWithStepper';
import OrderedDeliveryProviderToday from './OrderedDeliveryProviderToday';
import Config from './Config';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { StoreContainer } from './store';
import dayjs from './dayjs';

function DeliveryProvider() {
  const storeContainer = StoreContainer.useContainer();
  const [updating, setUpdating] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [recipes, setRecipes] = useState<any>();
  const [deliveryProvider, setDeliveryProvider] = useState<string>();
  const [quantities, setQuantities] = useState({});
  const [warnings, setWarnings] = useState<Array<firebase.firestore.DocumentSnapshot> | null>(null);

  useEffect(() => {
    (async () => {
      const date = dayjs().tz('Asia/Tokyo').add(1, 'day').toDate();
      const timestamp = Math.round(date.getTime() / 1000);
      const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${storeContainer.shopId}/curry_recipes?date=${timestamp}&include_last_ordered_recipes=false&sales_channel=takeout&curry_recipe_type=delivery_provider`;

      const response = await fetch(apiEndPoint, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const responseJson = await response.json();
      setRecipes(responseJson.curry_recipes);
    })();
  }, [storeContainer.shopId]);

  useEffect(() => {
    if (storeContainer.shopId) {
      const unregisterOrdersObserver = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('delivery_provider_warnings')
        .onSnapshot((snap) => {
          console.log('onSnapshot DeliveryProvider');
          setWarnings(snap.docs);
        });

      return () => {
        unregisterOrdersObserver();
      };
    }

    return () => {};
  }, [storeContainer.shopId]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!deliveryProvider) {
      // eslint-disable-next-line no-alert
      window.alert('配達業者を選択してください');
      return;
    }

    const curries = Object.keys(quantities)
      .filter((curryId) => quantities[curryId] > 0)
      .map((curryId) => ({
        curry_recipe_id: curryId,
        quantity: quantities[curryId],
      }));

    if (curries.length === 0) {
      // eslint-disable-next-line no-alert
      window.alert('食数が0です');

      return;
    }

    const emptyDiv = () => <div />;
    const options = {
      title: '注文データを作成します',
      buttons: [
        {
          label: 'する',
          onClick: () => {
            submit(curries);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const findProviderName = (key: string) => {
    const index = Config.deliveryProviders.findIndex((val) => key === val[0]);
    if (index < 0) {
      return key;
    }
    return Config.deliveryProviders[index][1];
  };

  const submit = (curries) => {
    const apiEndPoint = `${process.env.REACT_APP_api_server}/orders_by_delivery_provider/`;
    const auth = firebase.auth();

    const now = Math.round(new Date().getTime() / 1000);
    const payload = {
      shop_id: storeContainer.shopId,
      delivery_provider: deliveryProvider,
      nickname: deliveryProvider ? findProviderName(deliveryProvider) : '',
      uuid: uuidv4(),
      pickup_at: now,
      pickup_until_at: now,
      curries,
    };

    setUpdating(true);
    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200 || response.status === 201) {
            setMessageSuccess('オーダー作成しました。');
            setDeliveryProvider(undefined);
            setQuantities({});
          } else {
            setMessageError(responseJson.error.message);
          }
          setUpdating(false);
        })
        .catch((error) => {
          setMessageError('オーダー作成失敗しました');
          setUpdating(false);
        });
    });
  };

  const updateQuantity = (id, val) => {
    const newValue = { ...quantities };
    newValue[id] = val;
    setQuantities(newValue);
  };

  const renderRecipe = (recipe: any) => {
    const warning = warnings?.find((w) => w.data()!.recipe_id === recipe.id);

    return (
      <tr key={`recipe-${recipe.id}`}>
        <td>{recipe.name}</td>
        <td>
          <InputQuantityWithStepper value={quantities[recipe.id]} onChange={(val) => updateQuantity(recipe.id, val)} />
        </td>
        <td>
          {warning && (
            <>
              {warning.data()!.confirmed ? (
                '売り切れ対応済'
              ) : (
                <button
                  value={quantities[recipe.id]}
                  className="btn btn-sm btn-danger"
                  type="button"
                  onClick={() => {
                    warning?.ref.update({ confirmed: true });
                  }}
                >
                  売り切れ対応完了
                </button>
              )}
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="container-fluid h-100 row">
      <div className="col-sm-8">
        {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

        {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

        <div id="messages" className="container-fluid h-80">
          <div className="scrollable-messages h-100">
            {recipes === undefined ? (
              <div className="row">
                <div className="col text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {warnings === null ? (
                  'loading'
                ) : (
                  <>
                    {recipes.length === 0 ? (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-light text-center" role="alert">
                            ありません
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <form id="delivery-provider-form" onSubmit={onSubmit}>
                          <div className="btn-group btn-group-toggle" data-toggle="buttons">
                            {Config.deliveryProviders.map((provider) => (
                              <button
                                type="button"
                                key={provider[0]}
                                className={`btn ${
                                  deliveryProvider === provider[0] ? 'btn-primary active' : 'btn-light'
                                }`}
                                onClick={() => setDeliveryProvider(provider[0])}
                              >
                                <img alt={provider[0]} src={`/${provider[0]}.webp`} width="32px" height="32px" />
                                <br />
                                {provider[1]}
                              </button>
                            ))}
                          </div>

                          <table className="table w-auto">
                            <thead>
                              <tr>
                                <th>プリセット</th>
                                <th>個数</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>{recipes.map((recipe) => renderRecipe(recipe))}</tbody>
                          </table>

                          <input type="submit" className="btn btn-primary" value="注文確定" />
                        </form>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <WindmillSpinnerOverlay loading={updating} message="注文データ作成中" />
      </div>

      <div className="col-sm-4">
        <OrderedDeliveryProviderToday />
      </div>
    </div>
  );
}

export default DeliveryProvider;
