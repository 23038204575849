import React, { useContext, useEffect, useState, useRef } from 'react';
import { useQueryParam, BooleanParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import dayjs from './dayjs';
import NavigatorOrder from './NavigatorOrder';
import CurrentSlots from './CurrentSlots';
import firebase from './firebase';
import TodayCounter from './TodayCounter';
import useStampCard from './hooks/useStampCard';
import CurrentShelves from './CurrentShelves';
import ShopsContext from './ShopsContext';
import TodayNewCustomersOrders from './TodayNewCustomerOrders';
import useElementItems from './hooks/useElementItems';
import { StoreContainer } from './store';

function NavigatorOrders() {
  console.log('RELOADED navigator orders');
  const history = useHistory();

  const [unpaidOrders, setUnpaidOrders] =
    useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();
  const [orders, setOrders] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();
  const [all, setAll] = useQueryParam('all', withDefault(BooleanParam, false));
  const [last3Days, setLast3Days] = useQueryParam('last3Days', withDefault(BooleanParam, false));
  const [showLid, setShowlid] = useQueryParam('lid', withDefault(BooleanParam, false));
  const [includeDoneByStaff, setIncludeDoneByStaff] = useQueryParam('done_by_staff', withDefault(BooleanParam, false));
  const [includeDelivering, setIncludeDelivering] = useQueryParam('delivering', withDefault(BooleanParam, false));
  const { shops } = useContext(ShopsContext);

  const storeContainer = StoreContainer.useContainer();
  const shopData = shops[storeContainer.shopId].data()!;
  const textSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    console.log('useEffect NavigatorOrders');
    let query = firebase
      .firestore()
      .collection('orders')
      .where(
        'store_app_sort_at',
        '>=',
        dayjs()
          .tz('Asia/Tokyo')
          .subtract(last3Days ? 3 : 0, 'day')
          .startOf('day')
          .toDate(),
      );

    query = query.where('kitchen_shop_id', '==', storeContainer.shopId);

    if (!all) {
      query = query.where('store_app_sort_at', '<', dayjs().tz('Asia/Tokyo').endOf('day').toDate());
    }

    if (!includeDoneByStaff) {
      query = query.where('done_by_staff', '==', false);
    }

    if (!includeDelivering && !includeDoneByStaff) {
      query = query.where('delivering', '==', false);
    }

    query = query.orderBy('store_app_sort_at', 'asc');

    const unregisterOrdersObserver = query.limit(100).onSnapshot((snap) => {
      console.log('onSnapshot NavigatorOrders');

      setOrders(snap.docs.filter((doc) => !doc.data().canceled_at));
    });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId, all, includeDoneByStaff, includeDelivering, last3Days]);

  useEffect(() => {
    let query = firebase
      .firestore()
      .collection('orders')
      .where('store_app_sort_at', '>=', dayjs().tz('Asia/Tokyo').startOf('day').toDate())
      .where('paylater', '==', true)
      .where('paid', '==', false);

    query = query.where('kitchen_shop_id', '==', storeContainer.shopId);
    query = query.where('store_app_sort_at', '<', dayjs().tz('Asia/Tokyo').endOf('day').toDate());
    query = query.where('done_by_staff', '==', true);
    query = query.orderBy('store_app_sort_at', 'asc');

    const unregisterOrdersObserver = query.onSnapshot((snap) => {
      console.log('onSnapshot UnpaidOrders');

      setUnpaidOrders(snap.docs.filter((doc) => !doc.data().canceled_at));
    });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId, all, includeDoneByStaff, includeDelivering]);

  const stampCardSnapshot = useStampCard();
  const kitchenCountElementItems = useElementItems(true, true);
  const allElementItems = useElementItems(true, false);

  const searchOrder = () => {
    if (textSearchRef.current && textSearchRef.current.value) {
      history.push(`/searchOrders?number=${textSearchRef.current.value}`);
    }
  };

  return (
    <div id="navigator-orders" className="container-fluid">
      <div className="row my-3">
        <div className="col pr-0">
          {orders === undefined || unpaidOrders === undefined || !kitchenCountElementItems ? (
            <div className="container-fluid h-100">
              <div className="row">
                <div className="col-12 align-self-center text-center">
                  <h5>loading..</h5>
                </div>
              </div>
            </div>
          ) : (
            <>
              {orders.length + unpaidOrders.length === 0 ? (
                <div className="container-fluid h-100">
                  <div className="row p-3">
                    <div className="col-12 align-self-center text-center">
                      <h2>待機中</h2>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {unpaidOrders.map((tmp) => (
                    <NavigatorOrder
                      key={tmp.id}
                      orderId={tmp.id}
                      order={tmp.data()}
                      customerId={tmp.data().customer_id}
                      stampCardSnapshot={stampCardSnapshot}
                      elementItems={allElementItems}
                      showLid={showLid}
                    />
                  ))}
                  {orders.map((tmp) => (
                    <NavigatorOrder
                      key={tmp.id}
                      orderId={tmp.id}
                      order={tmp.data()}
                      customerId={tmp.data().customer_id}
                      stampCardSnapshot={stampCardSnapshot}
                      elementItems={allElementItems}
                      showLid={showLid}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
        <div className="col-auto">
          <div className="row mb-3">
            <div className="col">
              <div id="current-slots-floating-card" className="card">
                <div className="card-body p-1">
                  <TodayNewCustomersOrders />
                  <br />
                  {kitchenCountElementItems &&
                    kitchenCountElementItems.map((elementItem) => (
                      <React.Fragment key={elementItem.id}>
                        <TodayCounter elementItemId={elementItem.id} label={elementItem.data()!.kitchen_name} />
                        <br />
                      </React.Fragment>
                    ))}
                  <CurrentSlots />
                  {shopData.shelf_layout && <CurrentShelves />}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group form-check mb-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="includeDeliveringCheckBox"
                  defaultChecked={includeDelivering}
                  onChange={(e) => {
                    setIncludeDelivering(e.target.checked);
                  }}
                />
                <label className="form-check-label small" htmlFor="includeDeliveringCheckBox">
                  配達中の注文も表示
                </label>
              </div>
              <div className="form-group form-check mb-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="doneByStaffCheckBox"
                  defaultChecked={includeDoneByStaff}
                  onChange={(e) => {
                    setIncludeDoneByStaff(e.target.checked);
                  }}
                />
                <label className="form-check-label small" htmlFor="doneByStaffCheckBox">
                  完了済の注文も表示
                </label>
              </div>
              <div className="form-group form-check mb-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="allCheckBox"
                  defaultChecked={all}
                  onChange={(e) => {
                    setAll(e.target.checked);
                  }}
                />
                <label className="form-check-label small" htmlFor="allCheckBox">
                  明日以降の注文も表示
                </label>
              </div>

              <div className="form-group form-check mb-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="last3DaysCheckbox"
                  defaultChecked={last3Days}
                  onChange={(e) => {
                    setLast3Days(e.target.checked);
                  }}
                />
                <label className="form-check-label small" htmlFor="last3DaysCheckbox">
                  過去3日間の注文も表示
                </label>
              </div>

              <div className="form-group form-check mt-3 mb-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="lidCheckbox"
                  defaultChecked={showLid}
                  onChange={(e) => {
                    setShowlid(e.target.checked);
                  }}
                />
                <label className="form-check-label small" htmlFor="lidCheckbox">
                  フタメッセージの表示
                </label>
              </div>

              <hr />
              <div>
                <div className="row no-gutters">
                  <div className="col-md-6 pr-2">
                    <input type="number" className="form-control" placeholder="注文番号" ref={textSearchRef} />
                  </div>
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => {
                        searchOrder();
                      }}
                    >
                      検索
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigatorOrders;
