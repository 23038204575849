import React, { useEffect, useState } from 'react';
import { useQueryParam, BooleanParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';
import useKitchenLayout from './hooks/useKitchenLayout';
import Order from './Order';

function WaitingOrders() {
  const storeContainer = StoreContainer.useContainer();
  const [waitingOrders, setWaitingOrders] =
    useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();
  const [all, setAll] = useQueryParam('all', withDefault(BooleanParam, false));
  const kitchenLayoutSnapshot = useKitchenLayout();

  useEffect(() => {
    const basicQuery = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('status', '==', 'ordered');

    let query: firebase.firestore.Query;

    if (all) {
      query = basicQuery;
    } else {
      query = basicQuery.where('planned_cooking_at', '<', dayjs().tz('Asia/Tokyo').endOf('day').toDate());
    }

    const unregisterWaitingOrdersObserver = query
      .orderBy('planned_cooking_at', 'asc')
      .orderBy('pickup_at', 'asc')
      .orderBy('estimated_at', 'asc')
      .orderBy('number', 'asc')
      .orderBy('nth_curry_in_this_order', 'asc')
      .onSnapshot((snap) => {
        console.log('onSnapshot WaitingOrders');

        setWaitingOrders(snap.docs);
      });

    return () => {
      unregisterWaitingOrdersObserver();
    };
  }, [storeContainer.line, storeContainer.shopId, all]);

  return (
    <div id="waiting-orders" className="container-fluid">
      {waitingOrders === undefined ? (
        <div className="row">
          <div className="col-12 align-self-center text-center">
            <div className="alert alert-light text-center" role="alert">
              loading...
            </div>
          </div>
        </div>
      ) : (
        <>
          {Object.keys(waitingOrders).length === 0 ? (
            <div className="row">
              <div className="col">
                <div className="alert alert-light text-center" role="alert">
                  ありません
                </div>
              </div>
            </div>
          ) : (
            waitingOrders.map((tmp) => (
              <Order
                key={tmp.id}
                kitchenOrderId={tmp.id}
                order={tmp.data()}
                line={storeContainer.line}
                kitchenLayoutSnapshot={kitchenLayoutSnapshot}
                hideWhenDone
              />
            ))
          )}
        </>
      )}

      <div className="row">
        <div className="col-12">
          <form>
            <div className="form-group">
              &nbsp; &nbsp; &nbsp;
              <input
                type="checkbox"
                className="form-check-input"
                id="allCheckBox"
                defaultChecked={all}
                onChange={(e) => {
                  setAll(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="allCheckBox">
                明日以降の注文も表示
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default WaitingOrders;
