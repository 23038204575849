import React from 'react';

function NoBagIcon() {
  return (
    <span
      style={{
        display: 'inline-block',
        position: 'relative',
      }}
    >
      <i
        className="fas fa-shopping-bag text-warning"
        style={{
          fontSize: '20px',
          textShadow: '0 0 1px #000',
        }}
      />
      <i
        className="far fa-times text-dark"
        style={{
          position: 'absolute',
          bottom: '3px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          fontSize: '14px',
        }}
      />
    </span>
  );
}

export default NoBagIcon;
