import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import RoleContext from './RoleContext';
import firebase from './firebase';
import 'firebase/compat/auth';

Modal.setAppElement('#root');

function CustomerPrivateUpdate(props: {
  customerId: string;
  modalIsOpen: boolean;
  inProgress: Function;
  favoriteToppings: string;
  dislikedToppings: string;
}) {
  const role = useContext(RoleContext);

  if (!role.isCs()) {
    throw new Error('permission error');
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  async function submit(customerid: string, updateHash: {}) {
    const doc = firebase.firestore().collection('customers_private').doc(props.customerId);

    await doc.set(updateHash, { merge: true });
  }

  const emptyDiv = () => <div />;

  async function confirmAndUpdate(customerId: string, updateHash: {}, cb: Function) {
    const options = {
      title: '更新',
      message: '更新を行います',
      buttons: [
        {
          label: '実行する',
          onClick: async () => {
            cb(true);
            await submit(customerId, updateHash);
            cb(false);
          },
        },
        {
          label: '実行しない',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  }

  useEffect(() => {
    setModalIsOpen(props.modalIsOpen);
    return () => {};
  }, [props]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const progress = (start: boolean) => {
    props.inProgress(start);
  };

  const inputFavoriteToppingsRef = React.createRef<HTMLTextAreaElement>();
  const inputDislikedToppingsRef = React.createRef<HTMLTextAreaElement>();

  const handleUpdateModal = async (e) => {
    e.preventDefault();
    closeModal();
    const auth = firebase.auth();

    const favoriteToppings = inputFavoriteToppingsRef.current?.value ?? '';
    const dislikedToppings = inputDislikedToppingsRef.current?.value ?? '';

    const updateHash: any = {
      favorite_toppings: favoriteToppings,
      disliked_toppings: dislikedToppings,
    };

    await confirmAndUpdate(props.customerId, updateHash, progress);
  };

  const closeModal = () => {
    props.inProgress(false);
    setModalIsOpen(false);
  };

  return (
    <>
      {modalIsOpen ? (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
          <form id="update_form" onSubmit={handleUpdateModal}>
            <div className="form-group">
              <h5>好きなトッピング</h5>
              <textarea
                className="form-control"
                id="inputFavoriteToppings"
                ref={inputFavoriteToppingsRef}
                defaultValue={props.favoriteToppings}
                rows={2}
                cols={60}
              />
            </div>
            <div className="form-group">
              <h5>苦手なトッピング</h5>
              <textarea
                className="form-control"
                id="inputDislikedToppings"
                ref={inputDislikedToppingsRef}
                defaultValue={props.dislikedToppings}
                rows={2}
                cols={60}
              />
            </div>
            <div className="form-group">
              <input type="submit" className="btn btn-danger" value="更新" />
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                キャンセル
              </button>
            </div>
          </form>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default CustomerPrivateUpdate;
