import React, { useEffect, useState, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import firebase from './firebase';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ShopsContext from './ShopsContext';
import { StoreContainer } from './store';

function ShopDay(props: {}) {
  const [shopSoldout, setShopSoldout] = useState<boolean>(false);
  const [posting, setPosting] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const storeContainer = StoreContainer.useContainer();
  const { currentShop } = useContext(ShopsContext);

  useEffect(() => {
    const shopData = currentShop?.data();
    if (shopData) {
      setShopSoldout(!!shopData.today_soldout);
    }
  }, [currentShop]);

  const reopen = () => {
    const emptyDiv = () => <div />;

    const options = {
      title: '店舗の再開を行います',
      message: '在庫が足りない場合は再開できません',
      buttons: [
        {
          label: '再開する',
          onClick: () => {
            doReopen();
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const doReopen = () => {
    const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${storeContainer.shopId}/reopen/`;
    const auth = firebase.auth();
    setPosting(true);

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200) {
            setMessageSuccess('再開しました。実際の反映までには数分かかることがあります。');
          } else {
            setMessageError(responseJson.error.message);
          }
          setPosting(false);
        })
        .catch(() => {
          setMessageError('再開に失敗しました');
          setPosting(false);
        });
    });
  };

  const close = () => {
    const emptyDiv = () => <div />;

    const options = {
      title: '店舗の緊急閉店を行います',
      message: '',
      buttons: [
        {
          label: '閉店する',
          onClick: () => {
            doClose();
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const doClose = () => {
    const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${storeContainer.shopId}/close/`;
    const auth = firebase.auth();
    setPosting(true);

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200) {
            setMessageSuccess('閉店しました。実際の反映までには数分かかることがあります。');
          } else {
            setMessageError(responseJson.error.message);
          }
          setPosting(false);
        })
        .catch(() => {
          setMessageError('閉店に失敗しました');
          setPosting(false);
        });
    });
  };

  return (
    <>
      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}
      {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}
      {shopSoldout ? (
        <>
          <span className="text-danger">本日閉店済</span>
          <small className="form-text text-muted">緊急閉店またはすべての必須プリセットが売り切れになりました。</small>
          <br />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              reopen();
            }}
          >
            店舗再開する
          </button>
        </>
      ) : (
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => {
            close();
          }}
        >
          緊急閉店する
        </button>
      )}
      <WindmillSpinnerOverlay loading={posting} message="更新中" />
    </>
  );
}

export default ShopDay;
