import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useTags = (): Array<firebase.firestore.DocumentData> | null => {
  const [useTagsSnapshot, setTagsSnapshot] = useState<Array<firebase.firestore.DocumentData> | null>(null);

  useEffect(() => {
    const query = firebase.firestore().collection('recipe_tags').orderBy('order');

    query.get().then((snapshot) => {
      setTagsSnapshot(snapshot.docs);
    });
  }, []);

  return useTagsSnapshot;
};

export default useTags;
