import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { v4 as uuidv4 } from 'uuid';
import RoleContext from './RoleContext';
import firebase from './firebase';
import 'firebase/compat/auth';

Modal.setAppElement('#root');

function CsRequestsUpdate(props: { customerId: string; modalIsOpen: boolean; inProgress: Function }) {
  const role = useContext(RoleContext);

  if (!role.isCs()) {
    throw new Error('permission error');
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [csRequests, setCsReqeusts] = useState<
    Array<{ id: string; request: string; repeat: boolean; types: Array<string> }>
  >([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection('customers_private')
      .doc(props.customerId)
      .get()
      .then((doc) => {
        console.log('get CsRequestsUpdate');
        if (doc.exists) {
          setCsReqeusts(
            doc.data()!.cs_requests?.map((request) => {
              return {
                id: request.id,
                request: request.request,
                repeat: request.repeat,
                types: request.types,
              };
            }) || [],
          );
        }
      });
  }, [props.customerId]);

  const emptyDiv = () => <div />;

  async function confirmAndUpdate() {
    const options = {
      title: '更新',
      message: '更新を行います',
      buttons: [
        {
          label: '実行する',
          onClick: async () => {
            props.inProgress(true);
            const doc = firebase.firestore().collection('customers_private').doc(props.customerId);

            await doc.set({ cs_requests: csRequests }, { merge: true });
            props.inProgress(false);
            closeModal();
          },
        },
        {
          label: '実行しない',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  }

  useEffect(() => {
    setModalIsOpen(props.modalIsOpen);
    return () => {};
  }, [props]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleUpdateModal = async (e) => {
    e.preventDefault();

    await confirmAndUpdate();
  };

  const addCsRequest = () => {
    setCsReqeusts((prevState) => [
      ...prevState,
      { id: uuidv4(), request: '', repeat: false, types: ['navigator', 'cooking'] },
    ]);
  };

  const removeCsRequest = (index: number) => {
    setCsReqeusts((prevState) => {
      const tmp = [...prevState];
      tmp.splice(index, 1);

      return tmp;
    });
  };

  const closeModal = () => {
    props.inProgress(false);
    setModalIsOpen(false);
  };

  return (
    <>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
          <form id="update_form" onSubmit={handleUpdateModal}>
            <div className="form-group">
              <h5>CS要望</h5>

              {csRequests.length > 0
                ? csRequests.map((request, index) => (
                    <div key={request.id} className="card my-2">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="row my-1">
                              <div className="col">
                                <textarea
                                  className="form-control"
                                  defaultValue={request.request}
                                  onChange={(value) => {
                                    csRequests[index].request = value.target.value;
                                    console.log(csRequests);
                                  }}
                                  rows={2}
                                  cols={60}
                                />
                              </div>
                            </div>
                            <div className="row my-1">
                              <div className="col-auto pr-0">
                                <div className="form-group form-check">
                                  <input
                                    id={`inputRepeat_${index}`}
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={request.repeat}
                                    onChange={(value) => {
                                      csRequests[index].repeat = value.target.checked;
                                    }}
                                  />
                                  <label htmlFor={`inputRepeat_${index}`} className="form-check-label">
                                    繰り返し
                                  </label>
                                </div>
                              </div>
                              <div className="col-auto pr-0">
                                <div className="form-group form-check">
                                  <input
                                    id={`inputNavigator_${index}`}
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={request.types.includes('navigator')}
                                    onChange={(value) => {
                                      if (value.target.checked) {
                                        if (!csRequests[index].types.includes('navigator')) {
                                          csRequests[index].types.push('navigator');
                                        }
                                      } else {
                                        csRequests[index].types = csRequests[index].types.filter(
                                          (type) => type !== 'navigator',
                                        );
                                      }
                                    }}
                                  />
                                  <label htmlFor={`inputNavigator_${index}`} className="form-check-label">
                                    接客
                                  </label>
                                </div>
                              </div>
                              <div className="col-auto pr-0">
                                <div className="form-group form-check">
                                  <input
                                    id={`inputCooking_${index}`}
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={request.types.includes('cooking')}
                                    onChange={(value) => {
                                      if (value.target.checked) {
                                        if (!csRequests[index].types.includes('cooking')) {
                                          csRequests[index].types.push('cooking');
                                        }
                                      } else {
                                        csRequests[index].types = csRequests[index].types.filter(
                                          (type) => type !== 'cooking',
                                        );
                                      }
                                    }}
                                  />
                                  <label htmlFor={`inputCooking_${index}`} className="form-check-label">
                                    盛り付け
                                  </label>
                                </div>
                              </div>
                              <div className="col-auto pr-0">
                                <div className="form-group form-check">
                                  <input
                                    id={`inputCs_${index}`}
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={request.types.includes('cs')}
                                    onChange={(value) => {
                                      if (value.target.checked) {
                                        if (!csRequests[index].types.includes('cs')) {
                                          csRequests[index].types.push('cs');
                                        }
                                      } else {
                                        csRequests[index].types = csRequests[index].types.filter(
                                          (type) => type !== 'cs',
                                        );
                                      }
                                    }}
                                  />
                                  <label htmlFor={`inputCs_${index}`} className="form-check-label">
                                    CS通知
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-auto">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                removeCsRequest(index);
                              }}
                            >
                              削除
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : '(設定なし)'}
            </div>
            <div className="form-group text-right">
              <button type="button" className="btn btn-primary" onClick={addCsRequest}>
                追加
              </button>
            </div>
            <div className="form-group">
              <input type="submit" className="btn btn-danger" value="更新" />
              &nbsp;
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                キャンセル
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}

export default CsRequestsUpdate;
