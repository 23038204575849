import React from 'react';
import NoBagIcon from './NoBagIcon';

function NoBagBadge() {
  return (
    <div
      className="alert alert-warning"
      style={{
        display: 'inline-block',
        margin: 0,
        padding: '2px 4px',
        borderRadius: '4px',
      }}
    >
      <NoBagIcon />
      &nbsp;
      <small>
        <strong>レジ袋なし</strong>
      </small>
    </div>
  );
}

export default NoBagBadge;
