import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import RoleContext from './RoleContext';
import firebase from './firebase';

Modal.setAppElement('#root');

function OrderMistake(props: { kitchenOrderId: string; inProgress: Function }) {
  const auth = firebase.auth();
  const role = useContext(RoleContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mistakeNote, setMistakeNote] = useState('');
  const [handoverMistakeNote, setHandoverMistakeNote] = useState('');
  const [cutleryMstakeNote, setCutleryMstakeNote] = useState('');
  const [lidMistakeNote, setLidMistakeNote] = useState('');

  if (!role.isCs()) {
    throw new Error('permission error');
  }

  useEffect(() => {
    const unregisterObserver = firebase
      .firestore()
      .collection('cooked_logs')
      .doc(props.kitchenOrderId)
      .onSnapshot((rec) => {
        console.log('onSnapshot OrderMistake');
        // サーバ側ではpubsubで作成しているので調理直後は存在しない場合があるのでチェック
        if (rec.exists) {
          const data = rec.data()!;
          setMistakeNote(data.mistake_note ?? '');
          setHandoverMistakeNote(data.handover_mistake_note ?? '');
          setCutleryMstakeNote(data.cutlery_mistake_note ?? '');
          setLidMistakeNote(data.lid_mistake_note ?? '');
        }
      });

    return () => {
      unregisterObserver();
    };
  }, [props.kitchenOrderId]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleMarkAsMistake = async (e) => {
    e.preventDefault();
    closeModal();

    props.inProgress(true);

    const payload: { [key: string]: string } = {};

    for (const key of ['mistake_note', 'handover_mistake_note', 'cutlery_mistake_note', 'lid_mistake_note']) {
      if (e.target[key].value) {
        payload[key] = e.target[key].value;
      }
    }

    const apiEndPoint = `${process.env.REACT_APP_api_server}/orders/${props.kitchenOrderId}/mistake`;

    await auth.currentUser!.getIdToken().then(async (token) => {
      await fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
    });
    props.inProgress(false);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    props.inProgress(false);
    setModalIsOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="cooking-order-card-primary-button btn btn-danger"
        onClick={() => {
          setModalIsOpen(true);
        }}
      >
        ミス
      </button>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form id="refund_form" onSubmit={handleMarkAsMistake}>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                盛り付けミス
              </label>
              <div className="col-sm-10">
                <input
                  className="form-control-lg"
                  type="text"
                  name="mistake_note"
                  defaultValue={mistakeNote}
                  size={60}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                店頭でのお渡し間違え
              </label>
              <div className="col-sm-10">
                <input
                  className="form-control-lg"
                  type="text"
                  name="handover_mistake_note"
                  defaultValue={handoverMistakeNote}
                  size={60}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                カトラリー入れ忘れ
              </label>
              <div className="col-sm-10">
                <input
                  className="form-control-lg"
                  type="text"
                  name="cutlery_mistake_note"
                  defaultValue={cutleryMstakeNote}
                  size={60}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                フタのつけ間違え
              </label>
              <div className="col-sm-10">
                <input
                  className="form-control-lg"
                  type="text"
                  name="lid_mistake_note"
                  defaultValue={lidMistakeNote}
                  size={60}
                />
              </div>
            </div>

            <div className="form-group">
              <input type="submit" className="btn btn-danger" value="ミス登録" />
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                キャンセル
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}

export default OrderMistake;
