/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';
import { useForm } from 'react-hook-form';
import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';
import NavigatorOrder from './NavigatorOrder';
import useStampCard from './hooks/useStampCard';
import useElementItems from './hooks/useElementItems';
import OrdersForKitchen from './OrdersForKitchen';

function SearchOrders() {
  interface IOrder {
    id: string;
    customerId: string;
    data: firebase.firestore.DocumentData;
  }

  const storeContainer = StoreContainer.useContainer();
  const [orders, setOrders] = useState<IOrder[]>();
  const [number, setNumber] = useQueryParam('number', withDefault(NumberParam, null));
  const [includeTomorrow, setIncludeTomorrow] = useState(false);
  const { register, handleSubmit } = useForm();
  const stampCardSnapshot = useStampCard();
  const allElementItems = useElementItems(true, false);

  useEffect(() => {
    if (number) {
      const now = dayjs().tz('Asia/Tokyo');

      console.log('qwe');
      const baseQuery = firebase
        .firestore()
        .collection('orders')
        .where('kitchen_shop_id', '==', storeContainer.shopId)
        .where('number', '==', number)
        .where('pickup_at', '>', now.startOf('day').toDate());

      const query = includeTomorrow ? baseQuery : baseQuery.where('pickup_at', '<', now.endOf('day').toDate());

      const unregisterOrdersObserver = query.orderBy('pickup_at', 'asc').onSnapshot((snap) => {
        console.log('onSnapshot SearchOrders');
        const records: Array<IOrder> = [];

        snap.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          records.push({
            id: docSnapshot.id,
            customerId: data.customer_id,
            data,
          });
        });

        setOrders(records);
      });

      return () => {
        unregisterOrdersObserver();
      };
    }

    return () => {};
  }, [storeContainer.shopId, number, includeTomorrow]);

  const onSubmit = (data) => {
    if (data.number) {
      setOrders(undefined);
      setNumber(undefined);
      setNumber(Number.parseInt(data.number, 10));
      setIncludeTomorrow(data.includeFuture);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">&nbsp;</div>
      </div>

      <div className="row">
        <div className="col-12">
          <form id="coupon-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <label className="col-2 col-form-label" htmlFor="inputNumber">
                注文番号
              </label>

              <div className="col-2">
                <input
                  className="form-control"
                  type="number"
                  id="inputNumber"
                  defaultValue={number ? number.toString() : ''}
                  {...register('number', {})}
                />
              </div>

              <div className="form-check col-5">
                <input
                  type="checkbox"
                  className="form-check-input"
                  {...register('includeFuture', {})}
                  id="checkboxIncludeFuture"
                />
                <label className="form-check-label" htmlFor="checkboxIncludeFuture">
                  明日以降の注文も表示
                </label>
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <input type="submit" className="btn btn-primary" value="検索" />
              </div>
            </div>
          </form>
        </div>
      </div>

      {number && (
        <>
          {orders === undefined ? (
            <div className="row">
              <div className="col-12 align-self-center text-center">
                <div className="alert alert-light text-center" role="alert">
                  searching...
                </div>
              </div>
            </div>
          ) : (
            <>
              {Object.keys(orders).length === 0 ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-light text-center" role="alert">
                      ありません
                    </div>
                  </div>
                </div>
              ) : (
                orders.map((tmp) => (
                  <div className="scrollable-orders">
                    <NavigatorOrder
                      key={tmp.id}
                      orderId={tmp.id}
                      order={tmp.data}
                      customerId={tmp.data.customer_id}
                      stampCardSnapshot={stampCardSnapshot}
                      elementItems={allElementItems}
                    />

                    <OrdersForKitchen orderId={tmp.id} />
                  </div>
                ))
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default SearchOrders;
