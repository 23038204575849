import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import OrderSummary from './OrderSummary';
import { StoreContainer } from './store';
import dayjs from './dayjs';

function CookingSlots(props: { slotSetId: string }) {
  const storeContainer = StoreContainer.useContainer();
  const [cookingSlots, setCookingSlots] = useState<any>();

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('cooking_slots')
      .where('slot_set_id', '==', props.slotSetId)
      .orderBy('time')
      .onSnapshot((snap) => {
        console.log('onSnapshot CookingSlots');
        const records = {};
        snap.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        });

        setCookingSlots(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [storeContainer.shopId, props.slotSetId]);

  return (
    <div className="">
      {cookingSlots ? (
        <>
          {Object.keys(cookingSlots).map((id) => (
            <div key={id}>
              <span className="pr-2">
                {cookingSlots[id].valid ? (
                  dayjs(cookingSlots[id].time.toDate()).tz('Asia/Tokyo').format('HH:mm:ss')
                ) : (
                  <s>{dayjs(cookingSlots[id].time.toDate()).tz('Asia/Tokyo').format('HH:mm:ss')}</s>
                )}
              </span>

              <span className="pr-2">
                {cookingSlots[id].order_id ? <OrderSummary orderId={cookingSlots[id].order_id} /> : '-'}
              </span>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}

export default CookingSlots;
