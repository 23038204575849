import React, { useEffect, useState } from 'react';

import dayjs from './dayjs';
import firebase from './firebase';
import Stars from './Stars';
import ReadMore from './ReadMore';

function CustomerFeedback(props: {
  customerId: string;
  limit?: number;
  lastNMonth?: number;
  currentTime?: Date | null;
  onlyAfterLastOrder?: boolean;
  before?: Date;
}) {
  const [feedbacks, setFeedbacks] = useState<Array<{ id: string; data: any; lastOrder: boolean }>>();

  useEffect(() => {
    const searchFeedbacks = async () => {
      const orders = await firebase
        .firestore()
        .collection('orders')
        .where('customer_id', '==', props.customerId)
        .where('cooked_at', '<', props.currentTime || new Date())
        .orderBy('cooked_at', 'desc')
        .limit(1)
        .get();

      const lastOrder = orders.size > 0 ? orders.docs[0] : null;
      let query = firebase.firestore().collection('feedback').where('customer_id', '==', props.customerId);

      if (props.lastNMonth) {
        query = query.where('created_at', '>', dayjs().tz('Asia/Tokyo').subtract(props.lastNMonth, 'month').toDate());
      }

      if (props.onlyAfterLastOrder && lastOrder) {
        query = query.where('created_at', '>', lastOrder.data().ordered_at);
      }

      if (props.before) {
        query = query.where('created_at', '<', props.before);
      }

      if (props.limit) {
        query = query.limit(props.limit);
      }

      const rec = await query.orderBy('created_at', 'desc').get();

      const records: Array<{ id: string; data: any; lastOrder: boolean }> = [];
      for (const feedback of rec.docs) {
        records.push({
          id: feedback.id,
          data: feedback.data(),
          lastOrder: lastOrder ? feedback.data().order_id === lastOrder.id : false,
        });
      }
      setFeedbacks(records);
    };

    searchFeedbacks();

    return () => {};
  }, [props.customerId, props.limit, props.lastNMonth, props.onlyAfterLastOrder, props.before, props.currentTime]);

  return (
    <>
      {feedbacks === undefined ? (
        <></>
      ) : (
        <>
          {feedbacks.length > 0 && (
            <div className="row pt-2">
              <div className="col-2">
                <small>
                  💬&nbsp;<strong>過去のご意見</strong>
                </small>
              </div>
              <div className="col pl-0">
                <ul className="list-group">
                  {feedbacks.map((feedback) => (
                    <li key={feedback.id} className="list-group-item py-2 small text-muted">
                      <div className="row align-items-center">
                        <div className="col-auto small">
                          {dayjs(feedback.data.created_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}
                        </div>
                        <div className="col-auto pl-0">
                          <Stars key={feedback.id} num={feedback.data.stars} />
                        </div>
                        <div className="col pl-0">
                          <ReadMore text={feedback.data.text} />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CustomerFeedback;
