import React, { useContext, useEffect, useState, useRef } from 'react';

import { NavLink } from 'react-router-dom';
import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';
import Utils from './Utils';
import NavigatorOrderDetail from './NavigatorOrderDetail';
import CustomerFeedback from './CustomerFeedback';
import useCustomerStampCard from './hooks/useCustomerStampCard';
import usePassOrder from './hooks/usePassOrder';
import TimeLeft from './TimeLeft';
import CustomerComplaint from './CustomerComplaint';
import usePassIfExists from './hooks/usePassIfExists';
import ShopsContext from './ShopsContext';
import useOnScreen from './useOnScreen';
import Common from './Common';
import NoBagBadge from './NoBagBadge';
import PayLaterUnPaidBadge from './PayLaterUnPaidBadge';

type NavigatorOrderProps = {
  orderId: string;
  order: firebase.firestore.DocumentData;
  customerId: string;
  stampCardSnapshot: firebase.firestore.DocumentSnapshot | null;
  elementItems: firebase.firestore.DocumentSnapshot[] | null;
  showLid?: boolean;
};

const NavigatorOrder = React.memo(
  (props: NavigatorOrderProps) => {
    console.log('RELOADED navigator order');

    const { shops } = useContext(ShopsContext);

    const storeContainer = StoreContainer.useContainer();

    const { order } = props;
    const handleDoneClickItem = async () => {
      if (order.cooked_at) {
        const data = {
          done_by_staff: true,
          done_by_staff_at: firebase.firestore.Timestamp.now(),
          navigator_id: storeContainer.crewId,
        };

        const batch = firebase.firestore().batch();
        batch.update(firebase.firestore().collection('orders').doc(props.orderId), data);
        await batch.commit();
      } else {
        window.alert('調理完了のものしか完了にできません。'); // eslint-disable-line no-alert
      }
    };

    const handleBatchDeliveringClickItem = async () => {
      const data = {
        delivering: true,
        delivering_at: firebase.firestore.Timestamp.now(),
        navigator_id: storeContainer.crewId,
      };

      const orders = await firebase
        .firestore()
        .collection('orders')
        .where('shop_id', '==', order.shop_id)
        .where('slot_id', '==', order.slot_id)
        .where('status', '!=', 'canceled')
        .get();

      if (orders.docs.some((o) => !o.data().cooked_at)) {
        window.alert(`${shops[order.shop_id].data()!.short_name} のすべてが調理完了にならないと出発できません`); // eslint-disable-line no-alert
        return;
      }

      const batch = firebase.firestore().batch();
      for (const o of orders.docs) {
        batch.update(o.ref, data);
      }
      await batch.commit();
    };

    const [customer, setCustomer] = useState<firebase.firestore.DocumentData>();

    useEffect(() => {
      firebase
        .firestore()
        .collection('customers')
        .doc(props.customerId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setCustomer(doc.data());
          }
        });
    }, [props.customerId]);

    const customserStampCard = useCustomerStampCard(props.stampCardSnapshot, props.customerId);
    const passOrderSnapshot = usePassOrder(props.customerId);
    const passSnapshot = usePassIfExists(passOrderSnapshot?.data()?.pass_id);

    const salesChannelIconName = (salesChanel: string) => {
      switch (salesChanel) {
        case 'takeout':
          return 'fa-store';
        case 'eatin':
          return 'fa-utensils';
        case 'stand':
          return 'fa-map-marker-alt';
        case 'office_delivery':
        case 'catering':
          return 'fa-building';
        default:
          return 'fa-question';
      }
    };

    const formatItems = () => {
      const names: Array<JSX.Element> = [];
      let number = 0;
      const multipleOrder = order.items.length > 1 || order.items[0].count > 1;
      for (const item of order.items) {
        for (let i = 0; i < item.count; i += 1) {
          number += 1;
          names.push(
            <div key={`order-item-${i}`}>
              <span className="badge badge-light">
                {Common.pickupPoint(props.order)}
                No. {Utils.formatNumber(order)}
                {multipleOrder ? `-${number}` : ''}
              </span>
              &nbsp;
              <small style={{ fontWeight: 'normal' }}>{item.curry.name}</small>
            </div>,
          );
        }
      }
      return names;
    };

    const showElementItems = (items: Array<any>) => {
      if (!navigatorElementItems) {
        return <></>;
      }

      const showCounts: { [key: string]: { count: number; prefix: string } } = {};

      for (const item of items) {
        const itemCount = item.count;
        for (const component of item.curry.components) {
          for (const element of component.elements) {
            if (!(element.quantity > 0 && element.size > 0)) {
              continue;
            }

            const foundElementItem = navigatorElementItems.find(
              (elementItem) => elementItem.data()!.id === element.item_id,
            );

            if (foundElementItem) {
              showCounts[element.item_id] ||= { prefix: foundElementItem.data()!.kitchen_navigator_prefix, count: 0 };
              showCounts[element.item_id].count += itemCount * element.quantity * element.size;
            }
          }
        }
      }

      return Object.keys(showCounts).map((elementItemId) => (
        <span key={`element-item-${elementItemId}`} className="mr-1">
          <small>
            {showCounts[elementItemId].prefix} x {showCounts[elementItemId].count}
          </small>
        </span>
      ));
    };

    const [isVisibleOnce, setIsVisibleOnce] = useState(false);
    const ref: any = useRef<HTMLDivElement>();
    const isVisible = useOnScreen(ref);

    const navigatorElementItems = props.elementItems?.filter((item) => item.data()!.kitchen_navigator_prefix);

    useEffect(() => {
      if (isVisible) {
        setIsVisibleOnce(true);
      }
    }, [isVisible]);

    return (
      <>
        <div ref={ref} className="card navigator-order mb-2">
          <div className="card-body py-3">
            <div className="row">
              <div className="col-2 text-center">
                {order.status === 'ordered' && <small>{Utils.orderStatusName(order.status)}</small>}

                {order.status === 'cooking' && (
                  <>
                    <small>{Utils.orderStatusName(order.status)}</small>
                    <br />
                    {order.cooking_slots && order.cooking_slots.length > 0 && (
                      <TimeLeft deadline={order.cooking_slots[order.cooking_slots.length - 1].time_until.toDate()} />
                    )}
                  </>
                )}

                {order.cooked_at && (
                  <>
                    {order.done_by_staff ? (
                      <small>完了済</small>
                    ) : order.batch_delivery ? (
                      order.delivering ? (
                        <>
                          <small>配達中</small>
                          <br />
                          <TimeLeft deadline={order.pickup_until_at.toDate()} />
                        </>
                      ) : (
                        <>
                          {order.batch_delivery &&
                            (order.nth_delivery_orders_in_this_slot - order.total_curries_in_this_order === 0 ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={handleBatchDeliveringClickItem}
                                >
                                  出発
                                </button>
                                <br />
                                {order.delivery_departure_due_time && (
                                  <TimeLeft deadline={order.delivery_departure_due_time.toDate()} />
                                )}
                              </>
                            ) : (
                              <></>
                            ))}
                        </>
                      )
                    ) : (
                      <>
                        {order.notified_at ? (
                          <button type="button" className="btn btn-primary" onClick={handleDoneClickItem}>
                            完了
                          </button>
                        ) : (
                          <small>準備中</small>
                        )}
                      </>
                    )}
                    {!order.paid && order.paylater && (
                      <>
                        <br />
                        <br />
                        <PayLaterUnPaidBadge />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-auto pl-1">
                        <h5>
                          <NavLink to={`/orders/${props.orderId}`} target="_blank" activeClassName="active">
                            {Common.pickupPoint(props.order)}
                            <small>No.</small>
                            {Utils.formatNumber(order)}
                          </NavLink>
                          <NavLink
                            to={`/customers/${order.customer_id}`}
                            target="_blank"
                            activeClassName="active"
                            className="ml-2 mr-1"
                          >
                            <span className="navigator-order-icon-text mr-1">
                              {order.nickname || '(ニックネーム未設定)'}
                              &nbsp;<small>様</small>
                            </span>
                          </NavLink>
                        </h5>
                      </div>
                      {order.shop_id !== order.kitchen_shop_id && (
                        <div className="col-auto pl-0">
                          <small>
                            <i className={`fas ${salesChannelIconName(order.sales_channel)} fa-xs`} />
                            &nbsp;
                            {shops[order.shop_id].data()!.short_name}
                            {order.delivery_group && ` (${order.delivery_group?.name})`}
                            {order.batch_delivery &&
                              order.nth_delivery_orders_in_this_slot &&
                              ` (${order.nth_delivery_orders_in_this_slot}/${order.number_of_delivery_orders_in_this_slot})`}
                          </small>
                        </div>
                      )}{' '}
                    </div>
                  </div>

                  <div className="col-auto text-right" style={{ lineHeight: 1.4 }}>
                    {order.batch_delivery && (
                      <span className="badge badge-warning mr-1" role="alert">
                        <small>配達</small>
                      </span>
                    )}

                    {order.sales_channel === 'eatin' && (
                      <span className="badge badge-dark mr-1" role="alert">
                        <small>イートイン</small>
                      </span>
                    )}

                    {order.estimated_at ? (
                      <>
                        <i className="far fa-clock" />
                        &nbsp;
                        {dayjs(order.estimated_at.toDate()).tz('Asia/Tokyo').format('HH:mm')}
                      </>
                    ) : (
                      <>
                        <i className="far fa-clock" />
                        &nbsp;
                        {dayjs(order.pickup_at.toDate()).tz('Asia/Tokyo').format('HH:mm')} -
                        {dayjs(order.pickup_until_at.toDate()).tz('Asia/Tokyo').format('HH:mm')}
                      </>
                    )}
                  </div>
                </div>
                <div className="row px-0 py-1">
                  <div className="col">
                    {order.bag === false && (
                      <span className="navigator-order-icon-text">
                        <NoBagBadge />
                      </span>
                    )}
                    {showElementItems(order.items)}
                    {order.total_curries_in_this_order && (
                      <span className="navigator-order-icon-text">
                        <i className="fas fa-utensil-spoon fa-xs" />
                        &nbsp;
                        {order.total_curries_in_this_order}
                      </span>
                    )}
                    {order.nth_order && (
                      <span className="navigator-order-icon-text">
                        <i className="far fa-walking fa-xs" />
                        &nbsp;
                        {order.nth_order}
                      </span>
                    )}
                    {order.invitation_code && (
                      <span className="navigator-order-icon-text">
                        <i className="fas fa-user-friends fa-xs" /> {order.invitation_code}
                      </span>
                    )}
                    {props.stampCardSnapshot && isVisibleOnce && (
                      <span
                        className={`navigator-order-icon-text ${
                          customserStampCard && customserStampCard.totalStampCount > customserStampCard.maxStampCount
                            ? 'text-muted'
                            : ''
                        }`}
                      >
                        <i className="fas fa-stamp fa-xs" />
                        &nbsp;
                        {customserStampCard
                          ? `${customserStampCard.totalStampCount}/${customserStampCard.maxStampCount}`
                          : '-/-'}
                      </span>
                    )}
                    {customer?.notification_permission &&
                      (customer?.notification_permission === 'granted' ? (
                        <span className="navigator-order-icon-text">
                          <i className="fas fa-bell fa-xs" />
                          &nbsp;通知ON
                        </span>
                      ) : (
                        <span className="navigator-order-icon-text">
                          <i className="fas fa-bell-slash fa-xs" />
                          &nbsp;通知OFF
                        </span>
                      ))}
                    <span className="navigator-order-icon-text">
                      <i className="fas fa-yen-sign fa-xs" />
                      {order.amount.toLocaleString()}
                      <small className="text-muted">({order.coupon_code ? order.coupon_code : 'クーポンなし'})</small>
                    </span>
                    {passOrderSnapshot && (
                      <span>
                        <i className="fas fa-ticket-alt fa-xs" />
                        <small>{passSnapshot?.data()?.kitchen_name}</small>
                      </span>
                    )}
                    {order.churn_status === 'WILL_CHURN' && (
                      <span style={{ color: 'red' }}>
                        <i className="fas fa-exclamation-triangle fa-xs" />
                      </span>
                    )}
                    {order.version_warning && (
                      <span className="navigator-order-icon-text">
                        <i className="fas fa-exclamation-triangle fa-xs" />
                        &nbsp;
                        <i className={`fab fa-${order.os === 'ANDROID' ? 'android' : 'apple'}`} />
                      </span>
                    )}
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col">{formatItems()}</div>
                </div>
                {order.navigator_talks?.length > 0 && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        💁&nbsp;<strong>トーク</strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        {order.navigator_talks?.map((talk) => (
                          <li key={talk.navigator_talk_id} className="list-group-item py-2 small text-muted">
                            <div style={{ whiteSpace: 'pre-wrap' }}>{talk.message}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {props.showLid && order.navigator_lid_message && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        <strong>
                          フタ
                          <br />
                          メッセージ
                        </strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        <li className="list-group-item py-2 small text-muted">
                          <div style={{ whiteSpace: 'pre-wrap' }}>{order.navigator_lid_message}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {order.request && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        🙏&nbsp;<strong>ご要望</strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        <li className="list-group-item py-2 small text-muted">{order.request}</li>
                      </ul>
                    </div>
                  </div>
                )}
                {order.cs_requests?.length > 0 && (
                  <div className="row pt-2">
                    <div className="col-2">
                      <small>
                        📢&nbsp;<strong>CS</strong>
                      </small>
                    </div>
                    <div className="col pl-0">
                      <ul className="list-group">
                        {order.cs_requests
                          .filter((request) => request.types.includes('navigator'))
                          .map((request) => (
                            <li key={request.id} className="list-group-item py-2 small text-muted">
                              {request.request}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}

                {order.nth_order > 1 && isVisibleOnce && (
                  <>
                    <CustomerFeedback
                      customerId={order.customer_id}
                      currentTime={order.ordered_at.toDate()}
                      onlyAfterLastOrder
                      before={order.ordered_at.toDate()}
                    />
                    <CustomerComplaint
                      customerId={order.customer_id}
                      currentTime={order.ordered_at.toDate()}
                      onlyAfterLastOrder
                      before={order.ordered_at.toDate()}
                    />
                  </>
                )}

                {order.nth_order > 1 && <NavigatorOrderDetail orderId={props.orderId} order={order} />}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    const ret =
      prevProps.orderId === nextProps.orderId &&
      prevProps.order.status === nextProps.order.status &&
      prevProps.order.paid === nextProps.order.paid &&
      prevProps.order.notified_at?.seconds === nextProps.order.notified_at?.seconds;
    return ret;
  },
);

export default NavigatorOrder;
