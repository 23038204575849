import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import addLabelPrinterJob from './addLabelPrinterJob';

Modal.setAppElement('#root');

function OrderReprintLabel(props: { shopId: string; ordersForKitchenId: string; inProgress: Function }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleRefundModal = async (e) => {
    e.preventDefault();
    closeModal();

    props.inProgress(true);
    await addLabelPrinterJob(props.shopId, props.ordersForKitchenId);
    props.inProgress(false);
  };

  const afterOpenModal = () => {};

  const closeModal = () => {
    props.inProgress(false);
    setModalIsOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="cooking-order-card-primary-button btn btn-dark"
        onClick={() => {
          setModalIsOpen(true);
        }}
      >
        ラベル再印刷
      </button>

      {modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="ラベル再印刷"
        >
          <form id="refund_form" onSubmit={handleRefundModal}>
            <div className="form-group">
              ラベルを再印刷します。よろしいですか？
              <br />
              <br />
              <input type="submit" className="btn btn-danger" value="再印刷" />
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                キャンセル
              </button>
            </div>
          </form>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default OrderReprintLabel;
