import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import confirmAndRunRefund from './confirmAndRunRefund';
import RoleContext from './RoleContext';

Modal.setAppElement('#root');

function OrderRefund(props: { orderId: string; order: any; modalIsOpen: boolean; inProgress: Function }) {
  const role = useContext(RoleContext);

  if (!role.isCs()) {
    throw new Error('permission error');
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    setModalIsOpen(props.modalIsOpen);
    return () => {};
  }, [props]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const progress = (start: boolean) => {
    props.inProgress(start);
  };

  const handleRefundModal = async (e) => {
    e.preventDefault();
    closeModal();

    const amount = parseInt(e.target.amount.value, 10);

    await confirmAndRunRefund(props.orderId, amount, progress);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    props.inProgress(false);
    setModalIsOpen(false);
  };

  return (
    <>
      {modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form id="refund_form" onSubmit={handleRefundModal}>
            <div className="form-group">
              <input
                className="form-control-lg"
                type="number"
                name="amount"
                defaultValue={props.order.amount}
                min={1}
                max={props.order.amount}
              />{' '}
              円
              <br />
              <br />
              <input type="submit" className="btn btn-danger" value="返金" />
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                キャンセル
              </button>
            </div>
          </form>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default OrderRefund;
