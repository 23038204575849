import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useQueryParam, StringParam } from 'use-query-params';
import dayjs from './dayjs';
import SlotSet from './SlotSet';
import { StoreContainer } from './store';

function SlotSets() {
  const storeContainer = StoreContainer.useContainer();
  const [slotSets, setSlotSets] = useState<any>();
  const [date, setDate] = useQueryParam('date', StringParam);

  useEffect(() => {
    const dayjsDate = date ? dayjs.tz(date, 'Asia/Tokyo') : dayjs().tz('Asia/Tokyo');
    const startOfDay = dayjsDate.startOf('day').toDate();
    const endOfDay = dayjsDate.endOf('day').toDate();

    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('slot_sets')
      .where('time', '>=', startOfDay)
      .where('time', '<', endOfDay)
      .orderBy('time')
      .onSnapshot((snap) => {
        console.log('onSnapshot SlotSets');
        const records = {};
        snap.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        });

        setSlotSets(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [storeContainer.shopId, date]);

  const dateChanged = (e) => {
    setDate(e.target.value);
  };

  return (
    <div className="container">
      <input type="date" onChange={dateChanged} defaultValue={date ?? ''} />

      <hr />
      {slotSets ? (
        <div>
          {Object.keys(slotSets).map((id) => (
            <SlotSet key={`${slotSets[id].shop_id}-${id}`} slotSetId={id} slotSet={slotSets[id]} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default SlotSets;
