import React, { useEffect, useState } from 'react';
import dayjs from './dayjs';
import firebase from './firebase';
import { StoreContainer } from './store';

function TodayNewCustomersOrders(props: {}) {
  const storeContainer = StoreContainer.useContainer();
  const [orders, setOrders] = useState(0);

  const getToday = () => dayjs().tz('Asia/Tokyo').format('YYYYMMDD');

  const [today, setToday] = useState(getToday());

  useEffect(() => {
    const interval = setInterval(() => {
      setToday(getToday());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const now = dayjs().tz('Asia/Tokyo');
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('orders')
      .where('kitchen_shop_id', '==', storeContainer.shopId)
      .where('sales_channel', 'in', ['takeout', 'eatin'])
      .where('nth_order', '==', 1)
      .where('ordered_at', '>=', now.startOf('day').toDate())
      .where('ordered_at', '<=', now.endOf('day').toDate())
      .onSnapshot((snapshot) => {
        console.log('onSnapshot TodayNewCustomersOrders');
        const total = snapshot.docs
          .filter((doc) => !doc.data().canceled_at)
          .reduce((sum, a) => sum + a.data().total_curries_in_this_order || 0, 0);
        setOrders(total);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId]);

  return (
    <small className="text-info">
      <strong>本日の来店新規数 {orders}</strong>
    </small>
  );
}

export default TodayNewCustomersOrders;
