import React, { useState, useEffect, useContext } from 'react';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import TimeLeft from './TimeLeft';
import firebase from './firebase';
import { StoreContainer } from './store';
import Utils from './Utils';
import RoleContext from './RoleContext';
import OrderElements from './OrderElements';
import OrderTimer from './OrderTimer';
import OrderCustomerTooltip from './OrderCustomerTooltip';
import KitchenWarnings from './KitchenWarnings';
import CsRequestsUpdate from './CsRequestsUpdate';
import ShopsContext from './ShopsContext';
import CookingButton from './CookingButton';
import dayjs from './dayjs';
import Common from './Common';
import NoBagBadge from './NoBagBadge';
import PayLaterUnPaidBadge from './PayLaterUnPaidBadge';

type OrderProps = {
  kitchenOrderId: string;
  order: firebase.firestore.DocumentData;
  line: number | null;
  kitchenLayoutSnapshot: firebase.firestore.DocumentData | null;
  hideWhenDone: boolean;
};

const Order = React.memo(
  (props: OrderProps) => {
    const { currentShop, shops } = useContext(ShopsContext);
    const [done, setDone] = useState('');
    const storeContainer = StoreContainer.useContainer();
    const [updating, setUpdating] = useState(false);

    const [csRequestOpened, setCsRequestOpened] = useState(false);
    const role = useContext(RoleContext);

    const hideUntilCookingStart = props.order.status === 'cooking' && !props.order.kitchen_cooking_at;

    const csRequests =
      (props.order.cs_requests && props.order.cs_requests.filter((request) => request.types.includes('cooking'))) || [];

    const shopName = (shopId: string) => {
      return shops[shopId].data()!.short_name;
    };

    const cooked = !!props.order.cooked_at;

    const totalPrice = () => {
      let total = 0;
      for (const component of props.order.item.components) {
        for (const element of component.elements) {
          total += element.quantity * element.price_without_tax;
        }
      }
      return total;
    };

    const initialTime =
      props.order.status === 'cooking' && props.order.kitchen_cooking_at
        ? new Date().getTime() - props.order.kitchen_cooking_at.toDate().getTime()
        : props.order.status === 'cooked' && props.order.kitchen_cooking_at && props.order.cooked_at
        ? props.order.cooked_at.toDate().getTime() - props.order.kitchen_cooking_at.toDate().getTime()
        : undefined;

    const startImmediatelyTimer = props.order.status === 'cooking' && !!props.order.kitchen_cooking_at;

    const [checked, setChecked] = useState(false);

    const handleChangeChecked = (newChecked: boolean) => {
      setChecked(newChecked);
    };

    const progressUpdateCsRequestrs = (start: boolean) => {
      setUpdating(start);
      if (!start) {
        setCsRequestOpened(false);
      }
    };

    return (
      <>
        {role.isCs() && (
          <CsRequestsUpdate
            customerId={props.order.customer_id}
            modalIsOpen={csRequestOpened}
            inProgress={progressUpdateCsRequestrs}
          />
        )}
        <div className={`container-fluid h-100 px-2 ${done ? 'fadeOut' : ''}`}>
          <div className="cooking-order-card">
            <div className="cooking-order-card-header container-fluid">
              <div className="row align-items-center">
                <div className="col">
                  <>
                    {cooked && (
                      <span>
                        {props.order.nth_order && (
                          <span>
                            <i className="far fa-walking fa-xs" /> {props.order.nth_order}
                          </span>
                        )}
                        {props.order.bag === false && (
                          <>
                            &nbsp;
                            <NoBagBadge />
                          </>
                        )}
                      </span>
                    )}
                    {!props.order.paid && props.order.paylater && (
                      <>
                        &nbsp;
                        <PayLaterUnPaidBadge />
                      </>
                    )}
                  </>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col">
                  <span className="cooking-order-card-number">
                    {Common.pickupPoint(props.order)}
                    No. {Utils.formatNumber(props.order)}
                    {props.order.status === 'cooking' &&
                      props.order.line2 > 0 &&
                      currentShop!.data()?.cooking_lines > 1 && (
                        <span className="badge badge-primary"> ライン{props.order.line2}</span>
                      )}
                    {props.order.total_curries_in_this_order > 1 && (
                      <span className="badge badge-secondary">
                        複数注文 {props.order.nth_curry_in_this_order}/{props.order.total_curries_in_this_order}
                      </span>
                    )}
                  </span>

                  <span className="cooking-order-card-estimated-at mr-1">
                    <span>
                      {dayjs((props.order.estimated_at || props.order.pickup_at).toDate())
                        .tz('Asia/Tokyo')
                        .format('HH:mm')}
                      {props.order.status === 'cooking' && props.order.estimated_at && (
                        <TimeLeft deadline={props.order.estimated_at.toDate()} />
                      )}
                    </span>
                  </span>

                  {props.order.sales_channel === 'eatin' && (
                    <span className="badge badge-dark mr-1" style={{ fontSize: '16px' }} role="alert">
                      イートイン
                    </span>
                  )}

                  {props.order.delivery_provider && (
                    <span>
                      &nbsp;
                      <img
                        alt={props.order.delivery_provider}
                        src={`/${props.order.delivery_provider}.webp`}
                        width="36px"
                        height="36px"
                      />
                    </span>
                  )}
                </div>
                <div className="col-auto">
                  <span className="badge badge-dark cooking-status">{Utils.orderStatusName(props.order.status)}</span>
                  <OrderTimer key={initialTime} initialTime={initialTime} startImmediately={startImmediatelyTimer} />

                  <OrderCustomerTooltip order={props.order} />
                </div>
              </div>
            </div>
            <div className="cooking-order-card-body">
              <div className="cooking-order-card-order-item">
                <div className="cooking-order-card-name container-fluid">
                  <div className="row">
                    <div className="col">
                      🍛 {props.order.item.name}&nbsp;
                      {cooked && (
                        <strong>
                          ¥{totalPrice()}
                          <small>(税抜)</small>
                        </strong>
                      )}
                    </div>
                    <div className="col-auto">
                      <small>
                        {shopName(props.order.shop_id)}
                        {props.order.delivery_group && <> ({props.order.delivery_group?.name})</>}
                      </small>
                    </div>
                  </div>
                </div>
                <div>
                  <OrderElements
                    item={props.order.item}
                    priceIncluded={cooked}
                    kitchenLayoutSnapshot={props.kitchenLayoutSnapshot}
                    cooking={props.order.status === 'cooking' && props.order.kitchen_cooking_at}
                    hideUntilCookingStart={hideUntilCookingStart}
                    onChangeChecked={handleChangeChecked}
                  />
                </div>
              </div>
              <div
                className={`cooking-order-card-footer d-flex ${storeContainer.cookingKitchenLayoutFlexRowClassName}`}
              >
                <CookingButton
                  role={role}
                  order={props.order}
                  kitchenOrderId={props.kitchenOrderId}
                  checked={checked}
                  hideWhenDone={props.hideWhenDone}
                  setDone={setDone}
                  setUpdating={setUpdating}
                  line={props.line}
                  csRequests={csRequests}
                  position={
                    ((props.kitchenLayoutSnapshot && props.kitchenLayoutSnapshot.data().align) || 'left') === 'left'
                      ? 'end'
                      : 'start'
                  }
                  mainButton={false}
                  lines={currentShop!.data()?.cooking_lines}
                />
                <div
                  className={`cooking-order-card-comment-box ${props.order.request ? '' : 'disabled'} ${
                    hideUntilCookingStart ? 'hidden-order' : ''
                  }`}
                >
                  <div className="cooking-order-card-comment-box-title">🙏 ご要望</div>
                  <div className="cooking-order-card-comment-box-body">{props.order.request}</div>
                </div>
                <div className="cooking-order-card-comment-box">
                  <div className="cooking-order-card-comment-box-title">📢 CS</div>
                  <div className="cooking-order-card-cs-request">
                    <ul>
                      {csRequests.length > 0 &&
                        csRequests.map((request) => <li key={request.id}>{request.request}</li>)}
                    </ul>
                  </div>
                  {role.isCs() && !props.order.kitchen_cooking_at && (
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary mt-2"
                      onClick={() => setCsRequestOpened(true)}
                    >
                      CS要望更新
                    </button>
                  )}
                </div>
                <CookingButton
                  role={role}
                  order={props.order}
                  kitchenOrderId={props.kitchenOrderId}
                  checked={checked}
                  hideWhenDone={props.hideWhenDone}
                  setDone={setDone}
                  setUpdating={setUpdating}
                  line={props.line}
                  csRequests={csRequests}
                  position={
                    ((props.kitchenLayoutSnapshot && props.kitchenLayoutSnapshot.data().align) || 'left') === 'left'
                      ? 'start'
                      : 'end'
                  }
                  mainButton
                  lines={currentShop!.data()?.cooking_lines}
                />
              </div>
            </div>

            {hideUntilCookingStart && <KitchenWarnings />}
          </div>
        </div>

        <WindmillSpinnerOverlay loading={updating} />
      </>
    );
  },
  (prevProps, nextProps) => {
    const ret =
      prevProps.kitchenOrderId === nextProps.kitchenOrderId &&
      prevProps.line === nextProps.line &&
      prevProps.hideWhenDone === nextProps.hideWhenDone &&
      prevProps.order.kitchen_cooking_at?.seconds === nextProps.order.kitchen_cooking_at?.seconds &&
      prevProps.order.status === nextProps.order.status;
    return ret;
  },
);

export default Order;
