import { useEffect, useState } from 'react';
import firebase from '../firebase';

const usePassOrder = (customerId: string): firebase.firestore.DocumentSnapshot | null => {
  const [passOrderSnapshot, setPassOrderSnapshot] = useState<firebase.firestore.DocumentSnapshot | null>(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection('pass_orders')
      .where('customer_id', '==', customerId)
      .where('status', '==', 'active')
      .orderBy('ordered_at', 'desc')
      .limit(1)
      .get()
      .then((passOrderSnapshots) => {
        console.log('get usePassOrder');
        if (passOrderSnapshots.size === 0) {
          return;
        }

        const doc = passOrderSnapshots.docs[0];

        setPassOrderSnapshot(doc);
      });
  }, [customerId]);

  return passOrderSnapshot;
};

export default usePassOrder;
