import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useSalesComponents = (): Array<firebase.firestore.DocumentData> | null => {
  const [useSalesComponentsSnapshot, setSalesComponentsSnapshot] =
    useState<Array<firebase.firestore.DocumentData> | null>(null);

  useEffect(() => {
    const query = firebase.firestore().collection('sales_components').orderBy('order');

    query.get().then((snapshot) => {
      console.log('onSnapshot useSalesComponents');
      setSalesComponentsSnapshot(snapshot.docs);
    });
  }, []);

  return useSalesComponentsSnapshot;
};

export default useSalesComponents;
