// eslint-disable-next-line max-classes-per-file
import React, { useContext, useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import firebase from './firebase';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-datasheet/lib/react-datasheet.css';
import useElementItems from './hooks/useElementItems';
import ShopsContext from './ShopsContext';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
  value: number | null;
}

class MyReactDataSheet extends ReactDataSheet<GridElement, number> {}

interface AppState {
  grid: GridElement[][];
}

class ToppingHandler {
  shopId: string;

  elementItemId: string;

  constructor(shopId: string, elementItmeId: string) {
    this.shopId = shopId;
    this.elementItemId = elementItmeId;
  }

  update(value: number, batch: firebase.firestore.WriteBatch) {
    const ref = firebase
      .firestore()
      .collection('shops')
      .doc(this.shopId)
      .collection('topping_plans')
      .doc(this.elementItemId);

    batch.set(
      ref,
      {
        shop_id: this.shopId,
        element_item_id: this.elementItemId,
        limit_per_day: value,
        available_for_kitchen: value !== null,
        kitchen_updated_at: firebase.firestore.Timestamp.now(),
      },
      { merge: true },
    );

    return true;
  }
}

class ShopRecipe {
  shopId: string;

  recipes = {};

  name: string;

  order: number;

  constructor(shopId: string, name: string, order: number) {
    this.shopId = shopId;
    this.name = name;
    this.order = order;
  }

  updateValue(elementItemId: string, value: number) {
    this.recipes[elementItemId] = value;
  }
}

function transpose(a, defaultValue) {
  return Object.keys(a[0]).map((c) => {
    return a.map((r) => {
      return r[c] || defaultValue;
    });
  });
}

function ShopStocks() {
  const { shops } = useContext(ShopsContext);
  const [shopRecipes, setShopRecipes] = useState<{ [key: string]: ShopRecipe }>();
  const elementItems = useElementItems();

  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    firebase
      .firestore()
      .collectionGroup('topping_plans')
      .get()
      .then((snapshot) => {
        const newShopRecipes: { [key: string]: ShopRecipe } = {};

        for (const shop of Object.keys(shops)
          .map((shopId) => shops[shopId])
          .filter((s) => !s.data()!.kitchen_shop_id && s.data()!.status === 'active')) {
          newShopRecipes[shop.id] = new ShopRecipe(shop.id, shop.data()!.short_name, shop.data()!.order);
        }

        for (const doc of snapshot.docs) {
          const plan = doc.data()!;
          const shopId = doc.ref.parent.parent!.id;

          if (!newShopRecipes[shopId]) {
            continue;
          }

          const shopRecipe = newShopRecipes[shopId];

          if (plan.element_item_id) {
            shopRecipe.updateValue(
              plan.element_item_id,
              (plan.limit_per_day ?? 0) - (plan.consumed ?? 0) + (plan.consumed_fromnow ?? 0),
            );
          }
        }

        setShopRecipes(newShopRecipes);
      });
  }, [shops]);

  useEffect(() => {
    if (shopRecipes && elementItems) {
      const grid: any = [];

      const elementNames = elementItems.map((element) => {
        return {
          value: element.data()!.kitchen_name,
          width: 200,
          readOnly: true,
        };
      });
      const header = [{ value: '', width: 200, readOnly: true }].concat(elementNames);

      grid.push(header);

      for (const shopRecipe of Object.values(shopRecipes).sort((a, b) => a.order - b.order)) {
        const toppingRow = elementItems.map((elementItem) => {
          return {
            value: shopRecipe.recipes[elementItem.id],
            width: 150,
            readOnly: true,
            handler: new ToppingHandler(shopRecipe.shopId, elementItem.id),
          };
        });

        const row = [{ value: shopRecipe.name, width: 150, readOnly: true }].concat(toppingRow);
        grid.push(row);
      }
      setData(transpose(grid, { value: '', width: 150 }));
    }
  }, [shopRecipes, elementItems]);

  return (
    <>
      {elementItems && shopRecipes && (
        <MyReactDataSheet className="reset-bootstrap-for-datasheet" data={data} valueRenderer={(cell) => cell.value} />
      )}
    </>
  );
}

export default ShopStocks;
