/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Order from './Order';
import firebase from './firebase';
import { StoreContainer } from './store';
import useKitchenLayout from './hooks/useKitchenLayout';

function OrdersForKitchen({ orderId }: { orderId: string }) {
  const storeContainer = StoreContainer.useContainer();
  const [orders, setOrders] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();
  const kitchenLayoutSnapshot = useKitchenLayout();

  useEffect(() => {
    const unregisterOrdersObserver = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('order_id', '==', orderId)
      .orderBy('nth_curry_in_this_order', 'asc')
      .onSnapshot((snap) => {
        console.log('onSnapshot OrdersForKitchen');

        setOrders(snap.docs);
      });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId, orderId]); // storeContainer.shopId

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">&nbsp;</div>
      </div>

      <>
        {orders === undefined ? (
          <div className="row">
            <div className="col-12 align-self-center text-center">
              <div className="alert alert-light text-center" role="alert">
                searching...
              </div>
            </div>
          </div>
        ) : (
          <>
            {Object.keys(orders).length === 0 ? (
              <div className="row">
                <div className="col">
                  <div className="alert alert-light text-center" role="alert">
                    ありません
                  </div>
                </div>
              </div>
            ) : (
              orders.map((tmp) => (
                <>
                  <Order
                    key={tmp.id}
                    kitchenOrderId={tmp.id}
                    order={tmp.data()}
                    line={storeContainer.line}
                    kitchenLayoutSnapshot={kitchenLayoutSnapshot}
                    hideWhenDone={false}
                  />
                </>
              ))
            )}
          </>
        )}
      </>
    </div>
  );
}

export default OrdersForKitchen;
