import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import firebase from './firebase';

async function submitRefund(orderId: string, amount: number) {
  const apiEndPoint = `${process.env.REACT_APP_api_server}/orders/${orderId}/refund`;
  const auth = firebase.auth();

  await auth.currentUser!.getIdToken().then(async (token) => {
    await fetch(apiEndPoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount }),
    }).then(async (response) => {
      if (response.status !== 200) {
        const responseJson = await response.json();
        if (responseJson.error?.message) {
          window.alert(responseJson.error?.message);
        }
      }
    });
  });
}

const emptyDiv = () => <div />;

async function confirmAndRunRefund(orderId: string, amount: number, cb: Function) {
  const options = {
    title: '返金',
    message: `${amount} 円の返金を行います。注意: クーポンが使用されている場合でも、クーポンは使用済のままになります。`,
    buttons: [
      {
        label: '実行する',
        onClick: async () => {
          cb(true);
          await submitRefund(orderId, amount);
          cb(false);
        },
      },
      {
        label: '実行しない',
        onClick: () => {},
      },
    ],
    childrenElement: () => emptyDiv(),
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
  };
  confirmAlert(options);
}

export default confirmAndRunRefund;
