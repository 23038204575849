import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useKitchenLayouts = (): Array<firebase.firestore.DocumentData> | null => {
  const [kitchenLayoutSnapshots, setkitchenLayoutSnapshots] = useState<Array<firebase.firestore.DocumentData> | null>(
    null,
  );

  useEffect(() => {
    firebase
      .firestore()
      .collection('kitchen_layouts')
      .get()
      .then((snapshot) => {
        setkitchenLayoutSnapshots(snapshot.docs);
      });
  }, []);

  return kitchenLayoutSnapshots;
};

export default useKitchenLayouts;
