import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import dayjs from './dayjs';
import ShopSlots from './ShopSlots';
import CookingSlots from './CookingSlots';
import { StoreContainer } from './store';
import NoCookingSlotOrders from './NoCookingSlotOrders';

function SlotSet(props: { slotSetId: string; slotSet }) {
  const storeContainer = StoreContainer.useContainer();
  const [posting, setPosting] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [status, setStatus] = useState('');

  const handleChange = (data) => {
    const numberOfCurries = Number.parseInt(data.target.value, 10);

    if (Number.isNaN(numberOfCurries)) {
      return;
    }

    if (numberOfCurries === props.slotSet.number_of_curries) {
      return;
    }

    const postData = {
      slot_set_id: props.slotSetId,
      number_of_curries: numberOfCurries,
    };

    const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${storeContainer.shopId}/slot_sets/`;
    const auth = firebase.auth();
    setPosting(true);

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 200) {
            // nothing to do
          } else {
            setMessageError(responseJson.error.message);
          }
          setStatus('更新完了');
          setPosting(false);
        })
        .catch(() => {
          setMessageError('同期に失敗しました');
          setPosting(false);
        });
    });
  };

  return (
    <>
      <div className="row border-bottom">
        {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}
        <div className="col-4" style={{ verticalAlign: 'top' }}>
          {dayjs(props.slotSet.time.toDate()).tz('Asia/Tokyo').format('HH:mm')}
          <br />

          <small>
            <i className="far fa-user-friends" /> {props.slotSet.lines}
            <br />
            <i className="far fa-soup" />
            &nbsp;
            <input
              type="number"
              max={props.slotSet.number_of_curries}
              min={0}
              defaultValue={props.slotSet.number_of_curries}
              onBlur={handleChange}
            />
            <span className="badge badge-success">{status}</span>
            <br />
          </small>

          <ShopSlots slotSetId={props.slotSetId} kitchenShopId={storeContainer.shopId} />
        </div>

        <div className="col">
          <CookingSlots slotSetId={props.slotSetId} />
          <NoCookingSlotOrders time={props.slotSet.time.toDate()} timeUntil={props.slotSet.time_until.toDate()} />
        </div>
      </div>
      <WindmillSpinnerOverlay loading={posting} message="店舗情報/スロット更新中" />
    </>
  );
}

export default SlotSet;
