import React, { useEffect, useState } from 'react';
import firebase from './firebase';
import KitchenWarnings from './KitchenWarnings';
import Order from './Order';
import { StoreContainer } from './store';

function PickupNowOrders(props: { kitchenLayoutSnapshot: firebase.firestore.DocumentData | null }) {
  const storeContainer = StoreContainer.useContainer();
  const [orders, setOrders] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>();

  useEffect(() => {
    // 盛り付け開始したものは常に最初に出す(割り込ませない)
    const sortOrders = (
      a: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      b: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    ) => {
      if (a.data().kitchen_cooking_at && !b.data().kitchen_cooking_at) {
        return -1;
      }
      if (!a.data().kitchen_cooking_at && b.data().kitchen_cooking_at) {
        return 1;
      }
      if (a.data().kitchen_cooking_at && b.data().kitchen_cooking_at) {
        return a.data().kitchen_cooking_at.toDate() - b.data().kitchen_cooking_at.toDate();
      }
      return 0;
    };

    const query = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .where('status', '==', 'cooking')
      .where('pickup_now', '==', true)
      .orderBy('planned_cooking_at', 'asc')
      .orderBy('number', 'asc')
      .orderBy('nth_curry_in_this_order', 'asc');

    const unregisterOrdersObserver = query.onSnapshot((snap) => {
      console.log('onSnapshot PickupNowOrders');

      const reordered = snap.docs.sort(sortOrders);

      setOrders(reordered);
    });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId]);

  return (
    <>
      {orders === undefined ? (
        <div className="container-fluid h-100">
          <div className="row">
            <div className="col-12 align-self-center text-center">
              <h5>loading..</h5>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Object.keys(orders).length === 0 ? (
            <div className="container-fluid h-100">
              <div className="row p-3">
                <div className="col-12 align-self-center text-center">
                  <h2>待機中</h2>
                </div>
              </div>
              <KitchenWarnings />
            </div>
          ) : (
            <>
              <div className="scrollable-orders">
                {orders.map((tmp) => (
                  <Order
                    key={tmp.id}
                    kitchenOrderId={tmp.id}
                    order={tmp.data()}
                    line={null}
                    kitchenLayoutSnapshot={props.kitchenLayoutSnapshot}
                    hideWhenDone
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PickupNowOrders;
