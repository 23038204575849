import React, { useState, useEffect, useContext } from 'react';
import 'firebase/compat/auth';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import OrderMistake from './OrderMistake';
import { StoreContainer } from './store';
import Utils from './Utils';
import OrderRefund from './OrderRefund';
import Role from './Role';
import firebase from './firebase';
import ShopsContext from './ShopsContext';
import confirmAndRunCancel from './confirmAndRunCancel';
import OrderReceipt from './OrderReceipt';
import Common from './Common';
import OrderReprintLabel from './OrderReprintLabel';

function CookingButton(props: {
  role: Role;
  order: firebase.firestore.DocumentData;
  kitchenOrderId: string;
  checked: boolean;
  hideWhenDone: boolean;
  setDone: Function;
  setUpdating: Function;
  line: number | null;
  csRequests: Array<any>;
  position: 'start' | 'end';
  mainButton: boolean;
  lines: number;
}) {
  const storeContainer = StoreContainer.useContainer();
  const [refundModalIsOpen, setRefundModalIsOpen] = useState(false);
  const { shops } = useContext(ShopsContext);
  const shopData = shops[storeContainer.shopId].data()!;

  const onCookingClick = async (early: boolean) => {
    if (props.hideWhenDone) {
      props.setDone('done');
    }

    const values: any = {
      status: 'cooking',
      line2: props.line,
      cooking_at: new Date(),
      manual_cooking_start: early,
    };

    if (early) {
      values.estimated_at = new Date(+new Date() + 5 * 60 * 1000);
    }

    firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .doc(props.kitchenOrderId)
      .update(values);
  };

  const onKitchenCookingClick = () => {
    if (props.order.request || props.csRequests.length > 0) {
      const requests = () => (
        <div>
          {props.order.request ? <div>“{props.order.request}”</div> : null}
          {props.csRequests.length > 0 && props.csRequests.map((request) => <div>“{request.request}” (CS経由）</div>)}
        </div>
      );

      const options = {
        title: 'ご要望があります',
        childrenElement: () => requests(),
        buttons: [
          {
            label: '確認しました',
            onClick: () => {
              startCooking();
            },
          },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      };
      confirmAlert(options);
    } else {
      startCooking();
    }
  };

  const startCooking = () => {
    const values: any = {
      kitchen_cooking_at: new Date(),
    };

    firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .doc(props.kitchenOrderId)
      .update(values);
  };

  const onCancelClick = async () => {
    await confirmAndRunCancel(props.order.order_id, progress);
  };

  const onCookedClick = async () => {
    if (props.hideWhenDone) {
      props.setDone('done');
    }
    const now = new Date();
    firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('orders_for_kitchen')
      .doc(props.kitchenOrderId)
      .update({ status: 'cooked', cooked_at: now, crew_id: storeContainer.crewId });
  };

  const progress = (start: boolean) => {
    props.setUpdating(start);
    if (!start) {
      setRefundModalIsOpen(false);
    }
  };

  return (
    <div className="cooking-order-card-primary-button-container">
      {props.mainButton && props.order.status === 'ordered' && (
        <>
          {(props.order.paypay || props.order.stores || props.order.square) && !props.order.paid ? (
            <span className="text-danger float-right">{props.order.paypay ? 'PayPay' : 'セルフレジ'}支払い待ち</span>
          ) : (
            <>
              {props.order.estimated_at && (
                <button
                  type="button"
                  className="cooking-order-card-primary-button btn btn-warning"
                  onClick={() => {
                    onCookingClick(true);
                  }}
                >
                  調理開始
                  <br />
                  <small>(事前到着)</small>
                </button>
              )}
              {!props.order.estimated_at && (
                <button
                  type="button"
                  className="cooking-order-card-primary-button btn btn-primary"
                  onClick={() => {
                    onCookingClick(false);
                  }}
                >
                  調理開始
                </button>
              )}
            </>
          )}
          {!(props.order.self_checkout && props.order.paid) && (
            <button
              type="button"
              className="cooking-order-card-primary-button btn btn-danger"
              onClick={() => onCancelClick()}
            >
              注文キャンセル
            </button>
          )}
        </>
      )}
      {props.position === 'start' && props.order.status === 'cooking' && !props.order.kitchen_cooking_at && (
        <>
          <button
            type="button"
            className="cooking-order-card-primary-button btn btn-primary"
            onClick={() => onKitchenCookingClick()}
          >
            <>
              {props.order.status === 'cooking' && props.order.line2 > 0 && props.lines > 1 ? ( // TODO: eatin line対応？
                <>
                  <br />
                  スタート
                  <br />
                  <br />
                  <small>ライン{props.order.line2}</small>
                </>
              ) : (
                'スタート'
              )}
            </>
          </button>
        </>
      )}
      {!props.mainButton &&
        props.order.cooked_at &&
        shopData.label_printer_sales_channels &&
        shopData.label_printer_sales_channels.includes(props.order.sales_channel) && (
          <OrderReprintLabel
            ordersForKitchenId={props.kitchenOrderId}
            shopId={props.order.kitchen_shop_id}
            inProgress={progress}
          />
        )}
      {props.position === 'end' && props.order.status === 'cooking' && props.order.kitchen_cooking_at && (
        <button
          type="button"
          disabled={!props.checked}
          className="cooking-order-card-primary-button btn btn-primary"
          onClick={() => onCookedClick()}
        >
          {Common.pickupPoint(props.order)}
          No. {Utils.formatNumber(props.order)}
          {props.order.total_curries_in_this_order > 1 && (
            <>
              <br />
              <small>
                複数注文 {props.order.nth_curry_in_this_order}/{props.order.total_curries_in_this_order}
              </small>
            </>
          )}
          <br />
          <br /> 調理完了 👍
        </button>
      )}
      {props.mainButton && Utils.isRefundableOrder(props.order) && props.role.isCs() && (
        <button
          type="button"
          className="cooking-order-card-primary-button btn btn-danger"
          onClick={() => {
            setRefundModalIsOpen(true);
          }}
        >
          返金
        </button>
      )}
      {props.mainButton && props.order.refunded_at && props.role.isCs() && (
        <div className="cooking-order-card-primary-button text-center">返金済</div>
      )}
      {props.mainButton && props.order.cooked_at && props.role.isCs() && (
        <OrderReceipt orderId={props.order.order_id} inProgress={progress} />
      )}
      {props.mainButton && props.order.cooked_at && props.role.isCs() && (
        <OrderMistake kitchenOrderId={props.kitchenOrderId} inProgress={progress} />
      )}
      {props.role.isCs() && (
        <OrderRefund
          orderId={props.order.order_id}
          order={props.order}
          modalIsOpen={refundModalIsOpen}
          inProgress={progress}
        />
      )}
    </div>
  );
}

export default CookingButton;
