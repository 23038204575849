import React, { useState, useEffect } from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import dayjs from './dayjs';
import Config from './Config';
import Utils from './Utils';
import { StoreContainer } from './store';

function OrderedDeliveryProviderToday() {
  const [orders, setOrders] = useState({});
  const storeContainer = StoreContainer.useContainer();

  useEffect(() => {
    const unregisterRecipesObserver = firebase
      .firestore()
      .collection('orders')
      .where('shop_id', '==', storeContainer.shopId)
      .where('ordered_at', '>=', dayjs().tz('Asia/Tokyo').startOf('day').toDate())
      .orderBy('ordered_at', 'desc')
      .orderBy('delivery_provider')
      .onSnapshot((snap) => {
        console.log('onSnapshot OrderedDeliveryProviderToday');
        const records = {};
        for (const docSnapshot of snap.docs) {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        }
        setOrders(records);
      });

    return () => {
      unregisterRecipesObserver();
    };
  }, [storeContainer.shopId]);

  const findProviderName = (key: string) => {
    const index = Config.deliveryProviders.findIndex((val) => key === val[0]);
    if (index < 0) {
      return <>${key}</>;
    }
    return (
      <>
        <img alt={key} src={`/${key}.webp`} width="32px" height="32px" /> {Config.deliveryProviders[index][1]}
      </>
    );
  };

  return (
    <div className="container-fluid h-100">
      本日の注文
      <table className="table w-auto">
        <thead>
          <tr>
            <th>時間</th>
            <th>配達業者</th>
            <th>注文番号</th>
            <th>内容</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(orders).map((orderId) => (
            <tr key={orderId}>
              <td>{dayjs(orders[orderId].ordered_at.toDate()).tz('Asia/Tokyo').format('HH:mm')}</td>
              <td>{findProviderName(orders[orderId].delivery_provider)}</td>
              <td>{Utils.formatNumber(orders[orderId])}</td>
              <td>
                {orders[orderId].items.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={`${orderId}-${i}`}>
                    {item.curry.name} x{item.count}
                    <br />
                  </span>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrderedDeliveryProviderToday;
