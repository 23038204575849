import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import firebase from './firebase';

async function submitCancel(orderId: string) {
  const apiEndPoint = `${process.env.REACT_APP_api_server}/orders/${orderId}/cancel`;
  const auth = firebase.auth();

  await auth.currentUser!.getIdToken().then(async (token) => {
    await fetch(apiEndPoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    });
  });
}

const emptyDiv = () => <div />;

async function confirmAndRunCancel(orderId: string, cb: Function) {
  const options = {
    title: '注文のキャンセル(返金)',
    message: '同一注文内のすべての注文がキャンセル(返金)されます。',
    buttons: [
      {
        label: '実行する',
        onClick: async () => {
          cb(true);
          await submitCancel(orderId);
          cb(false);
        },
      },
      {
        label: '実行しない',
        onClick: () => {},
      },
    ],
    childrenElement: () => emptyDiv(),
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
  };
  confirmAlert(options);
}

export default confirmAndRunCancel;
