import React, { useContext, useEffect, useState, useRef } from 'react';

import firebase from './firebase';
import { StoreContainer } from './store';
import Utils from './Utils';
import TimeLeft from './TimeLeft';
import NoBagIcon from './NoBagIcon';

function ShelfOrder(props: { label: string; orderId: string | null }) {
  const storeContainer = StoreContainer.useContainer();
  const [order, setOrder] = useState<firebase.firestore.DocumentData>();

  useEffect(() => {
    if (props.orderId) {
      const unregisterOrdersObserver = firebase
        .firestore()
        .collection('orders')
        .doc(props.orderId)
        .onSnapshot((doc) => {
          console.log('onSnapshot ShelfOrder');
          if (doc.exists && !doc.data()!.done_by_staff) {
            setOrder(doc.data());
          } else {
            setOrder(undefined);
          }
        });

      return () => {
        unregisterOrdersObserver();
      };
    }
    return () => {
      setOrder(undefined);
    };
  }, [props.orderId]);

  const handleDoneClickItem = async () => {
    if (props.orderId && order?.cooked_at) {
      const data = {
        done_by_staff: true,
        done_by_staff_at: firebase.firestore.Timestamp.now(),
        navigator_id: storeContainer.crewId,
      };

      const batch = firebase.firestore().batch();
      batch.update(firebase.firestore().collection('orders').doc(props.orderId), data);
      await batch.commit();
    } else {
      window.alert('調理完了のものしか完了にできません。'); // eslint-disable-line no-alert
    }
  };

  return (
    <div className="d-flex flex-column p-0 shelf-item position-relative h-100">
      <>
        <div className="mx-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{props.label}</span>
          {order && <strong>{Utils.formatNumber(order)}</strong>}
        </div>
        {order && (
          <>
            <div className="mx-1 mb-1">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                  {order.present_given_at && <span>🎁</span>}
                  {order.nth_order === 1 && <span>🔰</span>}
                </div>
                {order.bag === false && (
                  <span>
                    <NoBagIcon />
                  </span>
                )}
              </div>
            </div>
            <div className="text-center" style={{ lineHeight: '1.2' }}>
              <small>{order.nickname}</small>
            </div>
            <div className="position-absolute fixed-bottom mx-1 mb-2">
              {order.status === 'cooking' && (
                <div className="text-center">
                  <small>
                    <strong>{Utils.orderStatusName(order.status)}</strong>
                  </small>
                  {order.cooking_slots && order.cooking_slots.length > 0 && (
                    <TimeLeft deadline={order.cooking_slots[order.cooking_slots.length - 1].time_until.toDate()} />
                  )}
                </div>
              )}
              {order.cooked_at && order.notified_at && (
                <>
                  <button
                    type="button"
                    style={{ width: '100%' }}
                    className="btn btn-sm btn-primary"
                    onClick={handleDoneClickItem}
                  >
                    完了
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default ShelfOrder;
