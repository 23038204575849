import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NavLink } from 'react-router-dom';

function Newsletters() {
  const [newsletters, setNewsletters] = useState<any>();

  useEffect(() => {
    const unregisterNewslettersObserver = firebase
      .firestore()
      .collection('newsletters')
      .orderBy('type')
      .orderBy('nth')
      .onSnapshot((snap) => {
        console.log('onSnapshot Newsletters');
        const records = {};
        for (const docSnapshot of snap.docs) {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        }
        setNewsletters(records);
      });

    return () => {
      unregisterNewslettersObserver();
    };
  }, []);

  return (
    <div className="container-fluid h-100">
      <h3>ニュースレター</h3>

      <NavLink to="/admin/newsletter" className="nav-link" activeClassName="active">
        新規ニュースレター
      </NavLink>

      <div id="messages" className="container-fluid h-80">
        <div className="scrollable-messages h-100">
          {newsletters === undefined ? (
            <div className="row">
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {Object.keys(newsletters).length === 0 ? (
                <div className="row">
                  <div className="col">
                    <div className="alert alert-light text-center" role="alert">
                      ありません
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <table className="table w-auto">
                    <thead>
                      <tr>
                        <th>回数</th>
                        <th>タイトル</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(newsletters).map((newsletterId) => (
                        <tr key={`newsletter-${newsletterId}`}>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            <NavLink to={`/admin/newsletter/${newsletterId}`}>
                              第 {newsletters[newsletterId].nth} 回
                            </NavLink>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            <pre>{newsletters[newsletterId].subject}</pre>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Newsletters;
