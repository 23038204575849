import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { NavLink } from 'react-router-dom';

function CouponLink(props: { code }) {
  const [coupon, setCoupon] = useState<firebase.firestore.QueryDocumentSnapshot>();

  useEffect(() => {
    const unregisterCouponObserver = firebase
      .firestore()
      .collection('coupons')
      .where('code', '==', props.code)
      .onSnapshot((query) => {
        console.log('onSnapshot CouponLink');
        if (query.size > 0) {
          setCoupon(query.docs[0]);
        }
      });

    return () => {
      if (unregisterCouponObserver) {
        unregisterCouponObserver();
      }
    };
  }, [props.code]);

  return (
    <div className="container-fluid h-100">
      {coupon ? (
        <NavLink to={`/admin/coupons/${coupon.ref.id}`} className="nav-link">
          {props.code}
        </NavLink>
      ) : (
        props.code
      )}
    </div>
  );
}

export default CouponLink;
