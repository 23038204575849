import React, { useEffect, useState } from 'react';

import dayjs from './dayjs';
import firebase from './firebase';
import Picture from './Picture';
import ReadMore from './ReadMore';

function CustomerComplaint(props: {
  customerId: string;
  limit?: number;
  showImage?: boolean;
  currentTime?: Date | null;
  onlyAfterLastOrder?: boolean;
  before?: Date;
}) {
  const [complaints, setComplaints] = useState<Array<{ id: string; data: any; lastOrder: boolean }>>();

  useEffect(() => {
    const searchComplaints = async () => {
      const orders = await firebase
        .firestore()
        .collection('orders')
        .where('customer_id', '==', props.customerId)
        .where('cooked_at', '<', props.currentTime || new Date())
        .orderBy('cooked_at', 'desc')
        .limit(1)
        .get();

      const lastOrder = orders.size > 0 ? orders.docs[0] : null;

      let query = firebase
        .firestore()
        .collection('complaints')
        .where('customer_id', '==', props.customerId)
        .orderBy('created_at', 'desc');

      if (props.onlyAfterLastOrder && lastOrder) {
        query = query.where('created_at', '>', lastOrder.data().ordered_at);
      }

      if (props.before) {
        query = query.where('created_at', '<', props.before);
      }

      if (props.limit) {
        query = query.limit(props.limit);
      }

      const snap = await query.get();

      const records: Array<{ id: string; data: any; lastOrder: boolean }> = [];
      snap.docs.forEach((complaint) => {
        records.push({
          id: complaint.id,
          data: complaint.data(),
          lastOrder: lastOrder ? complaint.data().order_id === lastOrder.id : false,
        });
      });

      setComplaints(records);
      return () => {};
    };

    searchComplaints();
  }, [props.customerId, props.onlyAfterLastOrder, props.before, props.currentTime, props.limit]);

  return (
    <>
      {complaints === undefined ? (
        <></>
      ) : (
        <>
          {Object.keys(complaints).length > 0 && (
            <div className="row pt-2">
              <div className="col-2">
                <small>
                  🛑&nbsp;<strong>過去の不備</strong>
                </small>
              </div>
              <div className="col pl-0">
                <ul className="list-group">
                  {complaints.map((complaint) => (
                    <li key={complaint.id} className="list-group-item py-2 small text-muted">
                      <div className="row align-items-center">
                        <div className="col-auto small">
                          {dayjs(complaint.data.created_at.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}
                        </div>
                        <div className="col pl-0">
                          <ReadMore text={complaint.data.content} />
                        </div>
                        {props.showImage && (
                          <div className="col pl-0">
                            {complaint.data.photo ? (
                              <Picture file={complaint.data.photo} width={80} alt="complaint" />
                            ) : (
                              <small className="text-muted">画像なし</small>
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CustomerComplaint;
