import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import firebase from './firebase';
import ShopsContext from './ShopsContext';
import { StoreContainer } from './store';
import withTMCAuth from './withTMCAuth';

function SelectShop(props: {}) {
  const storeContainer = StoreContainer.useContainer();
  const { shops } = useContext(ShopsContext);

  const change = (selected) => {
    if (window.confirm('店舗を切り替えます。よろしいですか？')) {
      storeContainer.setLine(1);
      storeContainer.setShopId(selected.target.value);
    }
  };

  return (
    <select
      id="shops"
      className="form-control form-control-sm text-light bg-dark"
      onChange={change}
      value={storeContainer.shopId}
      style={{ maxWidth: '10rem', fontSize: '12px' }}
    >
      <option value="">店舗</option>
      {shops === undefined
        ? ''
        : Object.keys(shops)
            .filter(
              (shopId: string) => !shops[shopId].data()!.kitchen_shop_id && shops[shopId].data()!.status === 'active',
            )
            .map((shopId: string) => (
              <option key={shopId} value={shopId}>
                {shops[shopId].data()!.short_name}
              </option>
            ))}
    </select>
  );
}

export default withRouter(withTMCAuth(SelectShop));
