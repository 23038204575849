import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import RoleContext from './RoleContext';
import firebase from './firebase';

Modal.setAppElement('#root');

function OrderReceipt(props: { orderId: string; inProgress: Function }) {
  const auth = firebase.auth();
  const role = useContext(RoleContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const ref = useRef<HTMLInputElement | null>(null);

  if (!role.isCs()) {
    throw new Error('permission error');
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    props.inProgress(false);
    setModalIsOpen(false);
  };

  const issueReceipt = async (e) => {
    e.preventDefault();
    closeModal();

    props.inProgress(true);

    const payload: { [key: string]: string } = {};

    const recipient = ref.current?.value || null;

    if (recipient) {
      payload.recipient = recipient;
    }

    const apiEndPoint = `${process.env.REACT_APP_api_server}/orders/${props.orderId}/issue_receipt`;

    await auth.currentUser!.getIdToken().then(async (token) => {
      await fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.blob())
        .then((blob) => {
          const file = window.URL.createObjectURL(blob);
          const tab = window.open();

          tab!.location.assign(file);
        });
    });
    props.inProgress(false);
  };

  return (
    <>
      <button
        type="button"
        className="cooking-order-card-primary-button btn btn-danger"
        onClick={() => {
          setModalIsOpen(true);
        }}
      >
        領収書発行
      </button>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form id="refund_form" onSubmit={issueReceipt}>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
                宛先
              </label>
              <div className="col-sm-10">
                <input className="form-control-lg" type="text" name="receipt_recipient" size={20} ref={ref} />
              </div>
            </div>

            <div className="form-group">
              <input type="submit" className="btn btn-danger" value="発行" />
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                キャンセル
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}

export default OrderReceipt;
