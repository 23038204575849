import React from 'react';
import { useStopwatch } from 'react-timer-hook';
import { sprintf } from 'sprintf-js';

function OrderTimer(props: { initialTime: number | undefined; startImmediately: boolean }) {
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } = useStopwatch({
    autoStart: props.startImmediately,
    offsetTimestamp: new Date(+new Date() + (props.initialTime ?? 0)),
  });

  const formatDate = () => {
    if (days > 0) {
      return sprintf('%d日 %02d:%02d:%02d', days, hours, minutes, seconds);
    }
    if (hours > 0) {
      return sprintf('%02d:%02d:%02d', hours, minutes, seconds);
    }

    return sprintf('%02d:%02d', minutes, seconds);
  };

  return (
    <span className="cooking-order-card-timer">
      ⏲&nbsp;
      {formatDate()}
    </span>
  );
}

export default OrderTimer;
