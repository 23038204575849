/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NavLink } from 'react-router-dom';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';
import IssueCoupon from './IssueCoupon';
import RoleContext from './RoleContext';
import useCustomers from './useCustomers';

function CustomerMessage(props: { match }) {
  const role = useContext(RoleContext);
  const [feedbackId] = useQueryParam('feedback_id', StringParam);

  if (!role.isCs()) {
    throw new Error('permission error');
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...{
        notification_channel: 'marketing',
        force_notification: false,
        subject: '',
        body: '',
        cover_image_url: '',
        coupon_code: '',
        send_at: '',
      },
      ...(feedbackId ? { feedback_id: feedbackId } : {}),
    },
  });

  const [posting, setPosting] = useState(false);

  const [waitingMessage, setWaitingMessage] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');

  const [customerIds] = useState<Array<string>>(props.match.params.id.split(','));

  const customers = useCustomers(customerIds);

  const [templates, setTemplates] =
    useState<Array<{ id: string; name: string; subject: string; body: string; coverImageUrl: string }>>();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const unregisterObserver = firebase
      .firestore()
      .collection('message_templates')
      .orderBy('order')
      .onSnapshot((snapshot) => {
        console.log('onSnapshot message_templates');
        const records: Array<{ id: string; name: string; subject: string; body: string; coverImageUrl: string }> = [];
        for (const doc of snapshot.docs) {
          records.push({
            id: doc.id,
            name: doc.data().name,
            subject: doc.data().subject,
            body: doc.data().body,
            coverImageUrl: doc.data().cover_image_url,
          });
        }
        setTemplates(records);
      });

    return () => {
      unregisterObserver();
    };
  }, []);

  useEffect(() => {
    if (feedbackId) {
      const unregisterObserver = firebase
        .firestore()
        .collection('feedback')
        .doc(feedbackId)
        .onSnapshot((doc) => {
          console.log('onSnapshot feedback');
          if (doc.exists) {
            setValue('subject', doc.data()?.ai_feedback_reply?.subject ?? '');
            setValue('body', doc.data()?.ai_feedback_reply?.reply_texts[0] ?? '');
          }
        });

      return () => {
        unregisterObserver();
      };
    }
    return () => {};
  }, [feedbackId, setValue]);

  useEffect(() => {}, [feedbackId]);

  const emptyDiv = () => <div />;
  const onSubmit = (data) => {
    const options = {
      title: 'メッセージの送信を行います。',
      message: '取り消せないので注意してください！',
      buttons: [
        {
          label: '送信する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
  };

  const sendMessage = () => {
    formRef.current!.dispatchEvent(new Event('submit', { bubbles: true }));
  };

  const eraseWhenEmpty = (event) => {
    // when incompleate date/time, erase it
    // e.g. when a user input just `2019-02-01` and no time, looks like inputted but it's actually empty and it's misunderstading. so erase all.
    if (!event.target.value) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const submit = (argData) => {
    const data = { ...argData };
    data.customer_ids = customerIds;

    const apiEndPoint = `${process.env.REACT_APP_api_server}/customer_messages/`;
    const auth = firebase.auth();

    setWaitingMessage('メッセージ配信登録中');
    setPosting(true);

    if (data.send_at) {
      data.send_at = dayjs.tz(data.send_at, 'Asia/Tokyo').unix();
    } else {
      data.send_at = null;
    }

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 201) {
            setMessageSuccess('送信完了しました。');
            formRef.current!.reset();
          } else {
            setMessageError(responseJson.error.message);
          }
          setPosting(false);
        })
        .catch((error) => {
          setMessageError('送信に失敗しました');
          setPosting(false);
        });
    });
  };

  const selectTemplateRef = React.createRef<HTMLSelectElement>();
  const selectedTemplate = (event) => {
    const templateId = selectTemplateRef.current!.value;
    if (templateId && templates && customers) {
      const template = templates.find((doc) => doc.id === templateId);
      if (template) {
        let { body } = template;
        if (body.includes('%%%nickname%%%')) {
          if (customerIds.length > 1) {
            // エラー
            selectTemplateRef.current!.selectedIndex = 0;
            window.alert('%%%nickname%%%は二人以上送信の場合使用できません');

            return;
          }

          body = body.replaceAll('%%%nickname%%%', customers[0].data()?.nickname);
        }

        if (body.includes('%%%coupon%%%')) {
          const couponCode = getValues('coupon_code');
          if (!couponCode) {
            selectTemplateRef.current!.selectedIndex = 0;
            window.alert('クーポンが作成されていません');
            return;
          }
          body = body.replaceAll('%%%coupon%%%', couponCode);
        }

        setValue('subject', template.subject);
        setValue('body', body);
        setValue('cover_image_url', template.coverImageUrl);
      }
    }
  };

  const createdCoupon = (createdCouponCode: string) => {
    setValue('coupon_code', createdCouponCode);
  };

  return (
    <div className="container-fluid">
      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

      {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

      {customers && (
        <>
          <div className="row">
            <div className="col-6">
              <form id="message-form" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                <h3>送信内容</h3>

                <div className="form-group row">
                  <label htmlFor="inputReceiver" className="col-sm-2 ">
                    ニックネーム
                  </label>
                  <div className="col-sm-8">
                    {customers
                      .map<React.ReactNode>((customer) => {
                        return (
                          <NavLink target="_blank" to={`/customers/${customer.id}`}>
                            {customer.data()?.nickname}
                          </NavLink>
                        );
                      })
                      .reduce((prev, curr) => [prev, ', ', curr])}
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="inputNotificationChannel" className="col-sm-2">
                    通知チャンネル
                  </label>
                  <div className="col-sm-9">
                    <select id="inputNotificationChannel" {...register('notification_channel', {})}>
                      <option value="marketing">TOKYO MIX CURRYからのお知らせ</option>
                      <option value="order">注文に関するお知らせ</option>
                    </select>
                    &nbsp;
                    <br />
                    <p className="text-danger">
                      <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                      ユーザーの設定に関係なく通知する&nbsp;
                      <input type="checkbox" {...register('force_notification', {})} />
                    </p>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="inputFcmNotification" className="col-sm-2 ">
                    テンプレート
                  </label>
                  <div className="col-sm-8">
                    <select onChange={selectedTemplate} ref={selectTemplateRef}>
                      <option>選択</option>
                      {templates &&
                        templates.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="inputSubject" className="col-sm-2 ">
                    タイトル
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      id="inputSubject"
                      size={60}
                      {...register('subject', { required: true })}
                      placeholder="開店1周年記念200円クーポンプレゼント！"
                    />
                  </div>
                  <div className="col-sm-2">
                    <small id="error_subject" className="text-danger">
                      {errors.subject && 'タイトルは必須です'}
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="inputDeeplink" className="col-sm-2 ">
                    画像のURL
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      id="inputCoverImageUrl"
                      size={60}
                      {...register('cover_image_url', {})}
                      placeholder="https://example.com/image.jpg"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="inputCouponCode" className="col-sm-2 ">
                    クーポン
                  </label>
                  <div className="col-sm-8">
                    <input type="text" id="inputCouponCode" size={20} {...register('coupon_code', {})} />
                  </div>
                  <div className="col-sm-2" />
                </div>

                <div className="form-group row">
                  <label htmlFor="inputBody" className="col-sm-2 ">
                    本文
                  </label>
                  <div className="col-sm-8">
                    <textarea id="inputBody" rows={20} cols={80} {...register('body', { required: true })} />
                  </div>
                  <div className="col-sm-2">
                    <small id="error_body" className="text-danger">
                      {errors.body && '本文は必須です'}
                    </small>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <label htmlFor="inputScheduledTime" className="col-sm-3 ">
                    メッセージ送信する時間
                  </label>
                  <div className="col-sm-2">
                    <input type="datetime-local" {...register('send_at', { onBlur: eraseWhenEmpty })} />
                  </div>
                </div>

                <hr />

                <input type="button" className="btn btn-primary" value="送信" onClick={sendMessage} />
              </form>
            </div>

            <div className="col-6">
              <IssueCoupon callbackCreatedCoupon={createdCoupon} />
            </div>
          </div>
          <WindmillSpinnerOverlay loading={posting} message={waitingMessage} />
        </>
      )}
    </div>
  );
}

export default CustomerMessage;
