import React, { useEffect, useState, useContext } from 'react';
import { StoreContainer } from './store';
import firebase from './firebase';
import ShopsContext from './ShopsContext';

function InCooking(props: {
  shopId: string;
  excludePickupNowOrders: boolean;
  line2: number | null;
  onlyPickupNowOrders: boolean;
}) {
  const [inCooking, setInCooking] = useState(0);
  useEffect(() => {
    let query = firebase
      .firestore()
      .collection('shops')
      .doc(props.shopId)
      .collection('orders_for_kitchen')
      .where('status', '==', 'cooking');

    if (props.excludePickupNowOrders) {
      query = query.where('pickup_now', '==', false);
    }
    if (props.onlyPickupNowOrders) {
      query = query.where('pickup_now', '==', true);
    }

    if (props.line2) {
      query = query.where('line2', '==', props.line2);
    }

    const unregisterOrdersObserver = query.onSnapshot((snap) => {
      console.log('onSnapshot InCooking');
      setInCooking(snap.size);
    });

    return () => {
      unregisterOrdersObserver();
    };
  }, [props.shopId, props.excludePickupNowOrders, props.line2, props.onlyPickupNowOrders]);

  return <> {inCooking > 0 && <span className="badge badge-secondary">{inCooking}</span>}</>;
}

function LineSwitcher(props: {}) {
  const [lines, setLines] = useState<{ cookingLines: number; enabledPickupNowLine: boolean }>({
    cookingLines: 1,
    enabledPickupNowLine: false,
  });
  const [currentTime, setCurrentTime] = useState<Date>();
  const [scheduledLines, setScheduledLines] = useState<number | null>(null);
  const storeContainer = StoreContainer.useContainer();
  const { currentShop } = useContext(ShopsContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (storeContainer.shopId) {
      const now = new Date();
      const unregisterScheduledLineObserver = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('slot_sets')
        .where('time', '<', now)
        .orderBy('time', 'desc')
        .limit(1)
        .onSnapshot((snap) => {
          console.log('onSnapshot LineSwitcher 1');
          if (snap.size > 0 && snap.docs[0].data().time_until.toDate() > now) {
            setScheduledLines(snap.docs[0].data().lines);
          } else {
            setScheduledLines(null);
          }
        });

      return () => {
        unregisterScheduledLineObserver();
      };
    }

    return () => {};
  }, [storeContainer.shopId, currentTime]);

  useEffect(() => {
    const shopData = currentShop?.data();

    if (shopData) {
      const cookingLines = shopData.cooking_lines ?? 1;
      const enabledPickupNowLine = shopData.enabled_pickup_now_line ?? false;

      setLines({ cookingLines, enabledPickupNowLine });
      if (cookingLines === 1) {
        storeContainer.setLine(-1);
      } else if (storeContainer.line > cookingLines) {
        storeContainer.setLine(1);
      }
    }
  }, [currentShop, storeContainer]);

  const addLine = async (num: number) => {
    const newLines = lines.cookingLines + num;
    if (newLines < 1) {
      return;
    }
    if (storeContainer.line > newLines) {
      clicked(newLines, false);
    } else if (newLines === 1) {
      clicked(-1, false);
    }
    await firebase.firestore().collection('shops').doc(storeContainer.shopId).update({ cooking_lines: newLines });
  };

  const clicked = (line: number, eatinLine: boolean) => {
    storeContainer.setLine(line);
    storeContainer.setPickupNowLine(eatinLine);
  };

  const createButtons = () => {
    const buttons: Array<JSX.Element> = [];

    buttons.push(
      <button
        type="button"
        key={-1}
        className={`btn btn-${
          storeContainer.line === -1 && !storeContainer.pickupNowLine ? 'primary active' : 'light'
        }`}
        onClick={() => clicked(-1, false)}
      >
        {lines.enabledPickupNowLine ? '予約注文' : '全て'}
        <InCooking
          shopId={storeContainer.shopId}
          line2={null}
          excludePickupNowOrders={lines.enabledPickupNowLine}
          onlyPickupNowOrders={false}
        />
      </button>,
    );

    if (lines.cookingLines > 1) {
      for (let i = 1; i <= lines.cookingLines; i += 1) {
        buttons.push(
          <button
            type="button"
            key={i}
            className={`btn btn-${storeContainer.line === i ? 'primary active' : 'light'}`}
            onClick={() => clicked(i, false)}
          >
            {lines.cookingLines === 1 ? (lines.enabledPickupNowLine ? '予約注文' : '全て') : `ライン ${i}`}
            <InCooking
              shopId={storeContainer.shopId}
              line2={lines.cookingLines === 1 ? null : i}
              excludePickupNowOrders={lines.enabledPickupNowLine}
              onlyPickupNowOrders={false}
            />
          </button>,
        );
      }
    }

    if (lines.enabledPickupNowLine) {
      buttons.push(
        <button
          type="button"
          className={`btn btn-${storeContainer.pickupNowLine ? 'primary active' : 'light'}`}
          onClick={() => clicked(-1, true)}
        >
          即時注文
          <InCooking shopId={storeContainer.shopId} line2={null} excludePickupNowOrders={false} onlyPickupNowOrders />
        </button>,
      );
    }

    return (
      <>
        <div className="btn-group" role="group">
          {buttons}
          <div>
            <button type="button" className="btn btn-light btn-sm" onClick={() => addLine(1)}>
              ＋
            </button>
            <button type="button" className="btn btn-light btn-sm" onClick={() => addLine(-1)}>
              ー
            </button>
          </div>
        </div>

        {scheduledLines && lines.cookingLines !== scheduledLines ? (
          <>
            <br />
            <span className="badge badge-danger">
              この時間のライン数は
              {scheduledLines} です。変更してください。
            </span>
          </>
        ) : null}
      </>
    );
  };
  return <>{lines ? createButtons() : ''}</>;
}

export default LineSwitcher;
