import { useEffect, useState } from 'react';
import firebase from '../firebase';

const usePassIfExists = (passId: string | undefined) => {
  const [passSnapshot, setPassSnapshot] = useState<firebase.firestore.DocumentSnapshot | null>(null);

  useEffect(() => {
    (async () => {
      try {
        if (!passId) return;

        const query = firebase.firestore().collection('passes').doc(passId);
        setPassSnapshot(await query.get());
      } catch (e) {
        alert(e);
      }
    })();
  }, [passId]);

  return passSnapshot;
};

export default usePassIfExists;
