import React, { useEffect, useState } from 'react';
import firebase from './firebase';

import Login from './Login';
import Navigator from './Navigator';
import withTMCAuth from './withTMCAuth';
import RoleContext from './RoleContext';
import ShopsContext from './ShopsContext';
import Role from './Role';
import { StoreContainer } from './store';

function App(props: { user; signOut }) {
  const { user } = props;
  const { signOut } = props;
  const [role, setRole] = useState<Role>();
  const [shops, setShops] = useState<{ [key: string]: firebase.firestore.DocumentSnapshot }>({});
  const [currentShop, setCurrentShop] = useState<firebase.firestore.DocumentSnapshot | null>(null);
  const storeContainer = StoreContainer.useContainer();

  // 初回読み込み
  useEffect(() => {
    firebase
      .firestore()
      .collection('shops')
      .orderBy('order')
      .get()
      .then((snapshot) => {
        const records = {};

        for (const shop of snapshot.docs) {
          records[shop.id] = shop;
        }
        setShops(records);
      });

    const currentShopLister = storeContainer.shopId
      ? firebase
          .firestore()
          .collection('shops')
          .doc(storeContainer.shopId)
          .onSnapshot((snapshot) => {
            console.log('onSnapshot Shop');
            setCurrentShop(snapshot);
          })
      : null;

    return () => {
      if (currentShopLister) {
        currentShopLister();
      }
    };
  }, [storeContainer.shopId]);

  useEffect(() => {
    if (user) {
      if (user && user.providerData[0].providerId === 'google.com' && user.email.match(/@tokyomixcurry.com$/)) {
        setRole(new Role(['super-admin']));
      } else {
        try {
          const unregisterSlotsObserver = firebase
            .firestore()
            .collection('admins')
            .doc(user.uid)
            .onSnapshot(
              (admin) => {
                console.log('onSnapshot admin');
                if (admin.exists) {
                  const { roles } = admin.data()!;
                  if (roles && roles.length > 0) {
                    setRole(new Role(admin.data()!.roles));
                  } else {
                    signOut();
                  }
                }
              },
              (err) => {
                console.log(err);
                signOut();
              },
            );

          return () => {
            unregisterSlotsObserver();
          };
        } catch (e) {
          signOut();
        }
      }
    }
    return () => {};
  }, [user, signOut]);

  return (
    <>
      {user && role ? (
        <ShopsContext.Provider value={{ currentShop, shops }}>
          <RoleContext.Provider value={role}>
            <Navigator />
          </RoleContext.Provider>
        </ShopsContext.Provider>
      ) : (
        <Login />
      )}
    </>
  );
}

export default withTMCAuth(App);
