import { useEffect, useState } from 'react';
import firebase from '../firebase';

const useAnycarryAddresses = (): Array<firebase.firestore.DocumentData> | null => {
  const [useAnycarryAddressesSnapshot, setAnycarryAddressesSnapshot] =
    useState<Array<firebase.firestore.DocumentSnapshot> | null>(null);

  useEffect(() => {
    const query = firebase.firestore().collection('anycarry_addresses');

    const unregister = query.onSnapshot((snapshot) => {
      console.log('onSnapshot useAnycarryAddresses');
      setAnycarryAddressesSnapshot(snapshot.docs);
    });

    return () => {
      unregister();
    };
  }, []);

  return useAnycarryAddressesSnapshot;
};

export default useAnycarryAddresses;
